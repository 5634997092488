import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { ModifyRoleAssignment, RoleAssignment } from 'Models/RoleModels';

export const useRoleAssignmentsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('roleassignments');

	const Add = (dto: ModifyRoleAssignment) =>
		ep
			.Post<RoleAssignment>(dto)
			.then(r => {
				useSuccess('Role assignment added');
				return r;
			})
			.catch(() => {
				useError('Error adding role assignment');
				return undefined;
			});

	const Delete = (dto: ModifyRoleAssignment) =>
		ep
			.Delete<number>(`?proposalId=${dto.proposalId}&personId=${dto.personId}&roleId=${dto.roleId}`)
			.then(r => {
				useSuccess('Role assignment deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting role assignment');
				return undefined;
			});

	return { Add, Delete, IsLoading: ep.IsLoading };
};
