export type AwardStatusNames =
	| 'Awarded'
	| 'Completed'
	| 'Facilitated'
	| 'Contract Pending'
	| 'Pending'
	| 'Rejected'
	| 'Submitted'
	| 'Withdrawn';

export enum AwardStatuses {
	Awarded = 4,
	Completed = 5,
	Facilitated = 6,
	ContractPending = 7,
	Pending = 8,
	Rejected = 9,
	Submitted = 10,
	Withdrawn = 11,
}

export class AwardStatus {
	id: AwardStatuses = 10;
	name: AwardStatusNames = 'Submitted';
}
