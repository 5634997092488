import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { MenuItem } from '@material-ui/core';
import { AgencyModels } from 'Models';

export interface IAgencySelectProps {
	/** The array of agencies that will be shown as dropdown items */
	agencies: AgencyModels.Agency[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
}

export const AgencySelect = (props: IAgencySelectProps) => {
	return (
		<Field
			component={TextField}
			name={props.name}
			select={true}
			label={props.label || 'Lead Agency'}
			fullWidth={true}
			className={props.className}
		>
			<MenuItem key={'agency-select-0'} value="0">
				- Select -
			</MenuItem>
			{props.agencies
				.sort((a, b) => ('' + a.description).localeCompare(b.description))
				.map(a => {
					return (
						<MenuItem key={`agency-select-${a.id}`} value={a.id}>
							{a.description}
						</MenuItem>
					);
				})}
		</Field>
	);
};
