import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { Person, AddPerson, EditPerson } from 'Models/PersonModels';

export const usePeopleEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('people');

	const Get = (includeInactive: boolean = false) =>
		ep.Get<Person[]>(includeInactive ? 'all' : '').catch(() => {
			useError('Error getting people');
			return [] as Person[];
		});

	const Add = (dto: AddPerson) =>
		ep
			.Post<Person>(dto)
			.then(r => {
				useSuccess('Person added');
				return r;
			})
			.catch(() => {
				useError('Error adding person');
				return undefined;
			});

	const Edit = (dto: EditPerson) =>
		ep
			.Put<Person>(dto.id, dto)
			.then(r => {
				useSuccess('Person saved');
				return r;
			})
			.catch(() => {
				useError('Error saving person');
				return undefined;
			});

	const EditActive = (id: number, isActive: boolean) =>
		ep
			.Put<number>(`${id}/active`, { id, isActive })
			.then(r => {
				useSuccess('Person saved');
				return r;
			})
			.catch(() => {
				useError('Error saving person');
				return undefined;
			});

	return { Get, Add, Edit, EditActive, IsLoading: ep.IsLoading };
};
