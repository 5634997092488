import React from 'react';
import { FieldProps, getIn } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { TextField } from '@material-ui/core';

export interface IGlobalAutocompleteProps extends FieldProps {
	label?: string;
	options: unknown[];
	namefield?: string;
	value: unknown[];
	/** For autosave selects or uncontrolled selects that just accept a value it should be set to true */
	setField?: boolean;
	valueChanged?: (v: unknown) => void;
	inputChanged?: (v: unknown) => void;
}

/** Wraps the formik material ui autocomplete so we don't have to repeat all the error and helperText props */

export const GlobalAutocomplete = (props: IGlobalAutocompleteProps) => {
	const { field, form } = props;
	const { name } = field;
	const { touched, errors } = form;
	const currentError = getIn(errors, name);
	const currentTouched = getIn(touched, name);
	const showError = currentTouched && !!currentError;
	return (
		<React.Fragment>
			<Autocomplete
				{...props}
				renderInput={(params: AutocompleteRenderInputParams) => (
					<TextField
						{...params}
						value={props.value}
						label={props.label}
						error={showError}
						helperText={currentTouched && currentError}
					/>
				)}
				disableClearable={true}
				onChange={(e, value: any | null) => {
					if (props.setField && props.setField) {
						form.setFieldValue(name, value.id);
						props.namefield && form.setFieldValue(props.namefield, value.name);
					}
					props.valueChanged && props.valueChanged(value);
					props.inputChanged && props.inputChanged(value);
					return true;
				}}
			/>
		</React.Fragment>
	);
};
