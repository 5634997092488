import { Role } from 'Models/RoleModels';
import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';

export const useRolesEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('roles');

	const Get = () =>
		ep.Get<Role[]>().catch(() => {
			useError('Error getting roles');
			return [] as Role[];
		});
	return { Get, IsLoading: ep.IsLoading };
};
