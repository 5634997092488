import React from 'react';
import { TextHelper } from 'Common/Helpers';
import { SupportAccountModels } from 'Models';

import MaterialTable from '@material-table/core';
import { TableIcons } from '.';
import AddIcon from '@material-ui/icons/Add';
import TrashIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { TableStyles, Colors } from 'Common/Styles';

export interface ISupportAccountTableProps {
	isLoading?: boolean;
	supportAccounts: SupportAccountModels.SupportAccount[];
	onAddClicked: () => void;
	onEditClicked: (supportAccount: SupportAccountModels.EditSupportAccount) => void;
	onDeleteClicked: (organizationId: number, accountNumber: string) => void;
	editable: boolean;
}

export const SupportAccountTable = (props: ISupportAccountTableProps) => {
	const colors = Colors.useColors();

	return (
		<MaterialTable
			columns={[
				{ title: 'Name', field: 'organizationName' },
				{ title: 'Account Number', field: 'accountNumber' },
				{ title: 'Dept PI', field: 'deptPI' },
				{ title: 'Bookkeeper', field: 'bookkeeper' },
				{ title: 'Amount', field: 'amount', render: rowData => TextHelper.money(rowData.amount) },
				{ title: 'Match', field: 'matchAmount', render: rowData => TextHelper.money(rowData.matchAmount) },
			]}
			data={props.supportAccounts}
			title="Support Accounts"
			icons={TableIcons}
			options={{
				pageSize: 5,
				headerStyle: TableStyles.headerStyle,
				actionsColumnIndex: -1,
			}}
			actions={[
				{
					icon: () => <AddIcon className={colors.primary} />,
					tooltip: 'Add Support Account',
					position: 'toolbar',
					onClick: event => props.onAddClicked(),
					hidden: !props.editable,
				},
				{
					icon: () => <EditIcon className={colors.warning} />,
					tooltip: 'Edit',
					onClick: (event, rowData: SupportAccountModels.SupportAccount) =>
						props.onEditClicked(
							new SupportAccountModels.EditSupportAccount(
								rowData.proposalId,
								rowData.organizationId,
								rowData.accountNumber,
								rowData.deptPI,
								rowData.bookkeeper,
								rowData.amount,
								rowData.matchAmount
							)
						),
					hidden: !props.editable,
				},
				{
					icon: () => <TrashIcon className={colors.error} />,
					tooltip: 'Delete',
					onClick: (event, rowData: SupportAccountModels.SupportAccount) =>
						props.onDeleteClicked(rowData.organizationId, rowData.accountNumber),
					hidden: !props.editable,
				},
			]}
			isLoading={props.isLoading}
		/>
	);
};
