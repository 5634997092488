import { AddPerson, EditPerson } from 'Models/PersonModels';
import { SchemaOf, object } from 'yup';
import { emptyStringSchema, numberSchema, stringSchema } from './BaseSchemas';

export const AddPersonSchema: SchemaOf<AddPerson> = object({
	firstName: stringSchema('First Name'),
	lastName: stringSchema('Last Name'),
	username: emptyStringSchema('Username'),
	description: emptyStringSchema('Description'),
});

export const EditPersonSchema: SchemaOf<EditPerson> = object({
	id: numberSchema('Id'),
	firstName: stringSchema('First Name'),
	lastName: stringSchema('Last Name'),
	username: emptyStringSchema('Username'),
	description: emptyStringSchema('Description'),
});
