import React, { useEffect, useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { Formik, Form, Field } from 'formik';
import { TextField, RadioGroup } from 'formik-material-ui';
import { Grid, FormControl, Button, FormLabel, FormControlLabel, Radio, LinearProgress } from '@material-ui/core';
import { OrganizationSelect, PersonSelect, GlobalDatePicker, FormDebugging } from '../Fields';
import { formStyles } from '../styles';
import { useOrganizationsEndpoint, usePeopleEndpoint, useSubcontractLinesEndpoint } from 'Endpoints';
import { Organization } from 'Models/OrganizationModels';
import { SubcontractLine, AddSubcontractLine, ContractToValues } from 'Models/SubcontractLineModels';
import { Person } from 'Models/PersonModels';
import { AddSubcontractLineSchema } from '../ValidationSchemas/SubcontractLineSchema';

export interface IAddSubcontractLineFormProps {
	onSuccess: (sub: SubcontractLine) => void;
	proposalId: number;
}

export const AddSubcontractLineForm = (props: IAddSubcontractLineFormProps) => {
	const ep = useSubcontractLinesEndpoint();
	const peopleEp = usePeopleEndpoint();
	const orgEp = useOrganizationsEndpoint();
	const [people, setPeople] = useState<Person[]>([]);
	const [organizations, setOrganizations] = useState<Organization[]>([]);

	useEffect(() => {
		peopleEp.Get().then(r => setPeople(r));
		orgEp.Get().then(r => setOrganizations(r));
	}, []);

	const classes = formStyles();
	const nameof = useNameof<AddSubcontractLine>();

	const [subcontractLine, setSubcontractLine] = useState(new AddSubcontractLine(props.proposalId));

	const handleSuccess = (sub: SubcontractLine) => {
		setSubcontractLine(new AddSubcontractLine(props.proposalId));
		props.onSuccess(sub);
	};

	const { Person: contractToPerson, Organization: contractToOrganization } = ContractToValues;

	return (
		<Formik
			initialValues={subcontractLine}
			validationSchema={AddSubcontractLineSchema}
			onSubmit={(values, { setSubmitting }) => {
				// Clear the subcontract line of any default values
				const subcontractLineToAdd = values;
				subcontractLineToAdd.personId =
					subcontractLineToAdd.personId === 0 ? undefined : subcontractLineToAdd.personId;
				subcontractLineToAdd.organizationId =
					subcontractLineToAdd.organizationId === 0 ? undefined : subcontractLineToAdd.organizationId;
				// add the subcontract line and clear the form if that succeeds
				ep.Add(subcontractLineToAdd).then(response => response && handleSuccess(response));
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form className={classes.flexForm}>
					<Grid container={true}>
						<Grid item={true} sm={12}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Contract To</FormLabel>
								<Field component={RadioGroup} aria-label="contract to" name={nameof('contractTo')}>
									<FormControlLabel
										value={contractToPerson}
										control={<Radio disabled={formprops.isSubmitting} />}
										label="Person"
										disabled={formprops.isSubmitting}
									/>
									<FormControlLabel
										value={contractToOrganization}
										control={<Radio disabled={formprops.isSubmitting} />}
										label="Organization"
										disabled={formprops.isSubmitting}
									/>
								</Field>
							</FormControl>
						</Grid>
						<Grid item={true} sm={12}>
							{formprops.values.contractTo === contractToOrganization ? (
								<FormControl className={classes.flexGrow}>
									<OrganizationSelect
										organizations={organizations}
										name={nameof('organizationId')}
										multiple={false}
										setField={true}
										label="Organization"
									/>
								</FormControl>
							) : (
								<FormControl className={classes.flexGrow}>
									<PersonSelect people={people} name={nameof('personId')} multiple={false} />
								</FormControl>
							)}
						</Grid>
						<FormControl className={classes.flexGrow}>
							<Field
								label="Account Number"
								fullWidth={true}
								name={nameof('accountNumber')}
								type="text"
								component={TextField}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								label="M Number"
								fullWidth={true}
								name={nameof('mNumber')}
								type="text"
								component={TextField}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								label="Dept. PI"
								fullWidth={true}
								name={nameof('deptPI')}
								type="text"
								component={TextField}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								label="Bookkeeper"
								fullWidth={true}
								name={nameof('bookkeeper')}
								type="text"
								component={TextField}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								label="Amount"
								fullWidth={true}
								name={nameof('amount')}
								type="text"
								component={TextField}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								label="Match Amount"
								fullWidth={true}
								name={nameof('matchAmount')}
								type="text"
								component={TextField}
							/>
						</FormControl>
						<Grid item={true} xs={12} sm={6}>
							<FormControl className={classes.flexGrow}>
								<Field component={GlobalDatePicker} label="Starts On" name={nameof('startsOn')} />
							</FormControl>
						</Grid>
						<Grid item={true} xs={12} sm={6}>
							<FormControl className={classes.flexGrow}>
								<Field component={GlobalDatePicker} label="Ends On" name={nameof('endsOn')} />
							</FormControl>
						</Grid>
						<Grid item={true} xs={12}>
							<FormControl>
								{formprops.isSubmitting && <LinearProgress />}
								<Button
									type="submit"
									color="primary"
									variant="contained"
									disabled={formprops.isSubmitting}
									onSubmit={formprops.submitForm}
								>
									{formprops.isSubmitting ? 'Saving...' : 'Add'}
								</Button>
							</FormControl>
						</Grid>
					</Grid>
					<FormDebugging />
				</Form>
			)}
		</Formik>
	);
};
