export type Team = {
	id: number;
	name: string;
};

export class AddTeamToProposal {
	proposalId: number;
	teamId: number = 0;

	constructor(proposalId: number) {
		this.proposalId = proposalId;
	}
}
