import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { AddNote, Note } from 'Models/NoteModels';

export const useNotesEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('notes');

	const Add = (dto: AddNote) =>
		ep
			.Post<Note>(dto)
			.then(r => {
				useSuccess('Note added');
				return r;
			})
			.catch(() => {
				useError('Error adding note');
				return undefined;
			});

	return { Add, IsLoading: ep.IsLoading };
};
