import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { LinkedProposals } from 'Models/ProposalModels';

export const useLinkedProposalsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('linkedproposals');

	const Add = (link: LinkedProposals) =>
		ep
			.Post<LinkedProposals>(link)
			.then(r => {
				useSuccess('Proposals linked');
				return r;
			})
			.catch(() => {
				useError('Error linking proposals');
				return undefined;
			});

	const Delete = (id: number, linkedId: number) =>
		ep
			.Delete<LinkedProposals>(`?proposalId=${id}&linkedProposalId=${linkedId}`)
			.then(r => {
				useSuccess('Proposals unlinked');
				return r;
			})
			.catch(() => {
				useError('Error unlinking proposals');
				return undefined;
			});

	return { Add, Delete, IsLoading: ep.IsLoading };
};
