import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { WorkItem } from 'Models/WorkItemModels';
import { GlobalAutocomplete } from './GlobalAutocomplete';

export interface IWorkItemParentSelectProps {
	/** The array of people that will be shown as dropdown items */
	workItems: WorkItem[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
	/** For controlled autocomplete */
	value?: WorkItem;
	/** For making this field optional */
	required?: boolean;
}

export const WorkItemParentSelect = (props: IWorkItemParentSelectProps) => {
	// because it is stupid and won't load properly for a controlled select correctly!!
	const [val, setVal] = useState(props.value);

	useEffect(() => {
		setVal(props.value);
	}, [props.value]);

	return (
		<Field
			name={props.name}
			key="parent-auto"
			component={(p: any) => (
				<GlobalAutocomplete
					{...p}
					label={props.label || 'Work Items'}
					options={props.workItems.sort((a, b) => ('' + a.title).localeCompare(b.title))}
					value={val}
					setField={props.required ? props.required : true}
					inputChanged={(v: WorkItem) => setVal(v)}
				/>
			)}
			multiple={false}
			getOptionLabel={(option: WorkItem) => option.title}
			fullWidth={true}
		/>
	);
};
