export enum ExcludeProposals {
	Completed = 'completed',
	Rejected = 'rejected',
	Withdrawn = 'withdrawn',
}

export class ExcludesValues {
	[ExcludeProposals.Completed]: boolean = false;
	[ExcludeProposals.Rejected]: boolean = false;
	[ExcludeProposals.Withdrawn]: boolean = false;
}

export enum ProposalTableColumns {
	ShortName = 'shortName',
	InstituteName = 'instituteName',
	LeadAgencyName = 'leadAgencyName',
	PrimaryContact = 'primaryContact',
	PrincipalInvestigator = 'principalInvestigator',
	BusinessContact = 'businessContact',
	CoPIs = 'coPIs',
	AwardStatusName = 'awardStatusName',
	ProposalNumber = 'proposalNumber',
	AccountNumber = 'accountNumber',
	AgencyNumber = 'agencyNumber',
	MNumber = 'mNumber',
	SubmittedOn = 'submittedOn',
	StartsOn = 'startsOn',
	EndsOn = 'endsOn',
	ContractSignedOn = 'contractSignedOn',
	FundingSourceName = 'fundingSourceName',
	GrantProgramName = 'grantProgramName',
	DirectCosts = 'directCosts',
	IndirectCosts = 'indirectCosts',
	TotalAward = 'totalAward',
	DirectMatch = 'directMatch',
	IndirectMatch = 'indirectMatch',
	UnrecoveredIDC = 'unrecoveredIDC',
	TotalMatch = 'totalMatch',
	Teams = 'teams',
}

export class ProposalTableColumnsValues {
	[ProposalTableColumns.ShortName]: boolean = false;
	[ProposalTableColumns.InstituteName]: boolean = false;
	[ProposalTableColumns.LeadAgencyName]: boolean = false;
	[ProposalTableColumns.PrimaryContact]: boolean = false;
	[ProposalTableColumns.PrincipalInvestigator]: boolean = false;
	[ProposalTableColumns.BusinessContact]: boolean = false;
	[ProposalTableColumns.CoPIs]: boolean = false;
	[ProposalTableColumns.AwardStatusName]: boolean = false;
	[ProposalTableColumns.AccountNumber]: boolean = false;
	[ProposalTableColumns.AgencyNumber]: boolean = false;
	[ProposalTableColumns.ProposalNumber]: boolean = false;
	[ProposalTableColumns.MNumber]: boolean = false;
	[ProposalTableColumns.SubmittedOn]: boolean = false;
	[ProposalTableColumns.StartsOn]: boolean = false;
	[ProposalTableColumns.EndsOn]: boolean = false;
	[ProposalTableColumns.ContractSignedOn]: boolean = false;
	[ProposalTableColumns.FundingSourceName]: boolean = false;
	[ProposalTableColumns.GrantProgramName]: boolean = false;
	[ProposalTableColumns.DirectCosts]: boolean = false;
	[ProposalTableColumns.IndirectCosts]: boolean = false;
	[ProposalTableColumns.TotalAward]: boolean = false;
	[ProposalTableColumns.DirectMatch]: boolean = false;
	[ProposalTableColumns.IndirectMatch]: boolean = false;
	[ProposalTableColumns.UnrecoveredIDC]: boolean = false;
	[ProposalTableColumns.TotalMatch]: boolean = false;
	[ProposalTableColumns.Teams]: boolean = false;
}

export class Preferences {
	proposalsInstitute: string = 'all';
	excludeProposals: ExcludeProposals[] = [];
	highlightEnding: boolean = true;
	openInNewTab: boolean = false;
	proposalsTableColumns: ProposalTableColumns[] = [
		ProposalTableColumns.ShortName,
		ProposalTableColumns.InstituteName,
		ProposalTableColumns.PrimaryContact,
		ProposalTableColumns.PrincipalInvestigator,
		ProposalTableColumns.AwardStatusName,
		ProposalTableColumns.AccountNumber,
		ProposalTableColumns.ProposalNumber,
		ProposalTableColumns.StartsOn,
		ProposalTableColumns.EndsOn,
		ProposalTableColumns.FundingSourceName,
		ProposalTableColumns.DirectCosts,
		ProposalTableColumns.IndirectCosts,
		ProposalTableColumns.TotalAward,
		ProposalTableColumns.TotalMatch,
	];
}
