import React, { useState, useEffect } from 'react';
import { Grid, FormControl, makeStyles, Theme, TextField } from '@material-ui/core';
import { DisplayField } from 'Common/Elements';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ProposalModels } from 'Models';
import { DateHelper } from 'Common/Helpers';
import deepEqual from 'deep-equal';
import { FormikErrors } from 'formik';

// props to be provided by parent component
// this is the components external interface
export interface IFacilitatedProposalCardProps {
	facilitatedProposal?: ProposalModels.FacilitatedProposal;
	editable: boolean;
	onChange?: (facilitatedProposal: ProposalModels.FacilitatedProposal) => void;
	errors?: FormikErrors<ProposalModels.FacilitatedProposal>;
}

export type Props = IFacilitatedProposalCardProps;

const useStyles = makeStyles((theme: Theme) => ({
	formControl: {
		marginLeft: '0',
		marginRight: '0',
		maxWidth: '100%',
		padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
		width: '100%',
	},
	formField: {
		marginLeft: '0',
		marginRight: '0',
	},
}));

export const FacilitatedProposalCard = (props: Props) => {
	const classes = useStyles();
	const origFacilitatedProposal = props.facilitatedProposal || new ProposalModels.FacilitatedProposal();
	const [facilitatedProposal, setFacilitatedProposal] = useState(origFacilitatedProposal);

	const somethingChanged = () => {
		const areEqual = deepEqual(origFacilitatedProposal, facilitatedProposal);
		return !areEqual;
	};

	useEffect(() => {
		if (somethingChanged()) {
			props.onChange && props.onChange(facilitatedProposal);
		}
	}, [facilitatedProposal]);

	useEffect(() => {
		if (somethingChanged()) {
			setFacilitatedProposal(origFacilitatedProposal);
		}
	}, [props.facilitatedProposal]);

	return (
		<Grid container={true}>
			<Grid item={true} xs={6}>
				{props.editable ? (
					<React.Fragment>
						<FormControl className={classes.formControl}>
							<KeyboardDatePicker
								showTodayButton={true}
								id="date-picker-facilitated-on"
								label="Facilitated On"
								format="MM/dd/yyyy"
								value={facilitatedProposal.facilitatedOn}
								onChange={(date: Date) =>
									setFacilitatedProposal({ ...facilitatedProposal, facilitatedOn: date })
								}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								error={props.errors && !!props.errors.facilitatedOn}
								helperText={props.errors && props.errors.facilitatedOn}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Account Number"
								fullWidth={true}
								value={facilitatedProposal.accountNumber}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									setFacilitatedProposal({
										...facilitatedProposal,
										accountNumber: event.target.value,
									})
								}
								margin="normal"
								className={classes.formField}
								error={props.errors && !!props.errors.accountNumber}
								helperText={props.errors && props.errors.accountNumber}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Agency Number"
								fullWidth={true}
								value={facilitatedProposal.agencyNumber}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									setFacilitatedProposal({
										...facilitatedProposal,
										agencyNumber: event.target.value,
									})
								}
								margin="normal"
								className={classes.formField}
								error={props.errors && !!props.errors.agencyNumber}
								helperText={props.errors && props.errors.agencyNumber}
							/>
						</FormControl>
					</React.Fragment>
				) : (
					<React.Fragment>
						<DisplayField
							label="Facilitated On"
							value={DateHelper.getShortDateString(facilitatedProposal.facilitatedOn)}
						/>
						<DisplayField label="Account Number" value={facilitatedProposal.accountNumber || ''} />
						<DisplayField label="Agency Number" value={facilitatedProposal.agencyNumber || ''} />
					</React.Fragment>
				)}
			</Grid>
		</Grid>
	);
};
