import React, { useEffect, useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { FormControl, Button, LinearProgress, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { FormDebugging, GlobalDatePicker, PersonSelect, TeamSelect } from '../Fields';
import { usePeopleEndpoint, useWorkItemsEndpoint } from 'Endpoints';
import { AddWorkItemSchema } from '../ValidationSchemas/WorkItemSchema';
import { AddWorkItem, WorkItem } from 'Models/WorkItemModels';
import { Team } from 'Models/TeamModels';
import { Person } from 'Models/PersonModels';
import { WorkItemParentSelect } from '../Fields/WorkItemParentSelect';

export interface IAddWorkItemFormProps {
	onSuccess: (workItem: WorkItem) => void;
	proposalId: number;
	teams: Team[];
	workItems: WorkItem[];
}

export const AddWorkItemForm = (props: IAddWorkItemFormProps) => {
	const ep = useWorkItemsEndpoint();
	const classes = formStyles();
	const nameof = useNameof<AddWorkItem>();
	const peopleEp = usePeopleEndpoint();
	const [people, setPeople] = useState<Person[]>([]);

	useEffect(() => {
		peopleEp.Get().then(r => setPeople(r));
	}, []);

	return (
		<Formik
			initialValues={new AddWorkItem(props.proposalId)}
			enableReinitialize={true}
			validationSchema={AddWorkItemSchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				ep.Add(values).then(r => r && props.onSuccess(r));
				resetForm();
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form className={classes.flexForm} autoComplete="off">
					<Grid container={true}>
						<FormControl className={classes.flexGrow}>
							<Field
								component={TextField}
								label="Title/Description"
								fullWidth={true}
								name={nameof('title')}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								component={GlobalDatePicker}
								fullWidth={true}
								label="Starts On"
								defaultValue={new Date()}
								name={nameof('startsOn')}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								component={GlobalDatePicker}
								fullWidth={true}
								label="Ends On"
								name={nameof('endsOn')}
							/>
						</FormControl>
						<Grid item={true} xs={12}>
							<FormControl fullWidth={true}>
								<PersonSelect
									people={people}
									name={nameof('personId')}
									multiple={false}
									required={false}
									label="Assigned Person"
								/>
							</FormControl>
							<FormControl fullWidth={true}>
								<TeamSelect teams={props.teams} name={nameof('teamId')} label="Assigned Team" />
							</FormControl>
							<FormControl fullWidth={true}>
								<WorkItemParentSelect
									workItems={props.workItems}
									name={nameof('parentId')}
									label="Parent Work Item"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} xs={12}>
							<FormControl>
								{formprops.isSubmitting && <LinearProgress />}
								<Button
									type="submit"
									color="primary"
									variant="contained"
									disabled={formprops.isSubmitting}
									onSubmit={formprops.submitForm}
								>
									{formprops.isSubmitting ? 'Saving...' : 'Add'}
								</Button>
							</FormControl>
							<FormDebugging />
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};
