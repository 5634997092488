import { AddGrantProgram, EditGrantProgram } from 'Models/GrantProgramModels';
import { SchemaOf, object } from 'yup';
import { numberSchema, stringSchema } from './BaseSchemas';

export const AddGrantProgramSchema: SchemaOf<AddGrantProgram> = object({
	shortName: stringSchema('Acronym'),
	name: stringSchema('Name'),
});

export const EditGrantProgramSchema: SchemaOf<EditGrantProgram> = object({
	id: numberSchema('Id'),
	shortName: stringSchema('Acronym'),
	name: stringSchema('Name'),
});
