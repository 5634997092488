import { CalendarEvent } from 'Models/CalendarModels';
import { DateHelper } from 'Common/Helpers';
import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';

export const useCalendarEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('calendar');

	const Get = (date?: Date) =>
		ep
			.Get<CalendarEvent[]>(date !== undefined ? DateHelper.getShortDateString(date, 'yyyy-MM-dd') : '')
			.catch(() => {
				useError('Error getting calendar events');
				return [] as CalendarEvent[];
			});
	return { Get, IsLoading: ep.IsLoading };
};
