import { EditFile } from 'Models/FileModels';
import { SchemaOf, object } from 'yup';
import { numberSchema, selectSchema, stringSchema } from './BaseSchemas';

export const EditFileSchema: SchemaOf<EditFile> = object({
	id: numberSchema('Id'),
	title: stringSchema('Title'),
	description: stringSchema('Description'),
	fileCategoryId: selectSchema('File Category'),
});
