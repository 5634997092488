import React, { useEffect, useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { Formik, Form } from 'formik';
import { FormControl, Button, LinearProgress } from '@material-ui/core';
import { formStyles } from '../styles';
import { useTeamsEndpoint } from 'Endpoints';
import { AddTeamToProposal, Team } from 'Models/TeamModels';
import { FormDebugging, TeamSelect } from '../Fields';
import { AddTeamToProposalSchema } from '../ValidationSchemas/TeamSchema';

export interface IAddTeamAssignmentFormProps {
	onSuccess: (team: Team) => void;
	proposalId: number;
}

export const AddTeamAssignmentForm = (props: IAddTeamAssignmentFormProps) => {
	const ep = useTeamsEndpoint();
	const [teams, setTeams] = useState<Team[]>([]);

	useEffect(() => {
		ep.Get().then(r => setTeams(r));
	}, []);

	const classes = formStyles();
	const nameof = useNameof<AddTeamToProposal>();

	const handleSuccess = (team: Team) => {
		props.onSuccess(team);
	};

	return (
		<Formik
			initialValues={new AddTeamToProposal(props.proposalId)}
			validationSchema={AddTeamToProposalSchema}
			onSubmit={(values, { setSubmitting }) => {
				// add team and clear the form if that succeeds
				ep.AddToProposal(values)
					.then(response => response && handleSuccess(response))
					.finally(() => setSubmitting(false));
			}}
		>
			{formprops => (
				<Form className={classes.flexForm}>
					<FormControl fullWidth={true}>
						<TeamSelect teams={teams} name={nameof('teamId')} />
					</FormControl>

					<FormControl fullWidth={true}>
						{formprops.isSubmitting && <LinearProgress />}
						<Button
							type="submit"
							color="primary"
							variant="contained"
							fullWidth={true}
							disabled={formprops.isSubmitting}
						>
							{formprops.isSubmitting ? 'Saving...' : 'Add'}
						</Button>
					</FormControl>
					<FormDebugging />
				</Form>
			)}
		</Formik>
	);
};
