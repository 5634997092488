import React, { createContext, useReducer, Dispatch, useContext, useEffect } from 'react';
import { ActionType, createAction } from 'typesafe-actions';
import { LoggedInUser } from 'Models/AppModels';
import { useMediaQuery } from '@material-ui/core';
import { AccountInfo } from '@azure/msal-browser';

// actions
const Actions = {
	UserLoggedIn: createAction('APP/USER_LOGGED_IN')<AccountInfo>(),
	UserLoggedOut: createAction('APP/USER_LOGGED_OUT')(),
	SetTheme: createAction('APP/SET_THEME')<'light' | 'dark'>(),
};

// state
class State {
	CurrentUser?: LoggedInUser;
	/** A way to track a user's login status separate from CurrentUser since that can't
	 * differentiate awaiting login and logged out
	 */
	LoginStatus: 'Awaiting Login' | 'Logged In' | 'Logged Out' = 'Awaiting Login';
	Theme: 'light' | 'dark' = 'light';
}

// reducer
const loginReducer = (state: State, action: ActionType<typeof Actions>): State => {
	switch (action.type) {
		case 'APP/USER_LOGGED_IN':
			return { ...state, CurrentUser: new LoggedInUser(action.payload), LoginStatus: 'Logged In' };
		case 'APP/USER_LOGGED_OUT':
			return { ...state, CurrentUser: undefined, LoginStatus: 'Logged Out' };
		case 'APP/SET_THEME':
			return { ...state, Theme: action.payload };
		default:
			return state;
	}
};

// init contexts
const initialState = new State();
const AppStateContext = createContext(initialState);
// tslint:disable-next-line: no-empty
const AppDispatchContext = createContext<Dispatch<ActionType<typeof Actions>>>(() => {});

// create context provider
const AppProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(loginReducer, initialState);
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	useEffect(() => {
		if (prefersDarkMode) {
			dispatch({ type: 'APP/SET_THEME', payload: 'dark' });
		}
	}, [prefersDarkMode]);
	return (
		<AppStateContext.Provider value={state}>
			<AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
		</AppStateContext.Provider>
	);
};

const useAppState = () => {
	return useContext(AppStateContext);
};

const useAppDispatch = () => {
	return useContext(AppDispatchContext);
};

export { useAppState, useAppDispatch, AppProvider };
