import React from 'react';
import * as Sentry from '@sentry/browser';

export interface IErrorBoundaryProps {
	errorComponent: JSX.Element;
}

interface State {
	hasError: boolean;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, State> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		Sentry.withScope(scope => {
			scope.setExtras(info);
			Sentry.captureException(error);
		});
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return this.props.errorComponent;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
