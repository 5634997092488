import React from 'react';
import { RouteComponentProps, Route, Redirect } from 'react-router-dom';
import { useAuthorization } from 'Common/Helpers/AuthHelper';

type RoleName = 'superUser' | 'admin';

export interface IProtectedRouteProps {
	allowedRoles: RoleName[];
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
	exact?: boolean;
	path: string;
}

export const ProtectedRoute = ({
	component: ProtectedComponent,
	allowedRoles,
	path,
	...rest
}: IProtectedRouteProps) => {
	const { isAdmin, isSuperUser } = useAuthorization();

	const userIsAuthorized = (): boolean => {
		if (allowedRoles.includes('admin') && isAdmin()) {
			return true;
		}

		if (allowedRoles.includes('superUser') && isSuperUser()) {
			return true;
		}

		return false;
	};

	return (
		<Route
			{...rest}
			path={path}
			render={(renderProps: RouteComponentProps) =>
				userIsAuthorized() ? (
					<ProtectedComponent {...renderProps} />
				) : (
					<Redirect from={path} to="/not-authorized" />
				)
			}
		/>
	);
};
