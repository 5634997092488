import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { GrantProgram, AddGrantProgram, EditGrantProgram } from 'Models/GrantProgramModels';

export const useGrantProgramsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('grantprograms');

	const Get = (includeInactive: boolean = false) =>
		ep.Get<GrantProgram[]>(includeInactive ? 'all' : '').catch(() => {
			useError('Error getting grant programs');
			return [] as GrantProgram[];
		});

	const Add = (dto: AddGrantProgram) =>
		ep
			.Post<GrantProgram>(dto)
			.then(r => {
				useSuccess('Grant program added');
				return r;
			})
			.catch(() => {
				useError('Error adding grant program');
				return undefined;
			});

	const Edit = (dto: EditGrantProgram) =>
		ep
			.Put<GrantProgram>(dto.id, dto)
			.then(r => {
				useSuccess('Grant program saved');
				return r;
			})
			.catch(() => {
				useError('Error saving grant program');
				return undefined;
			});

	const EditActive = (id: number, isActive: boolean) =>
		ep
			.Put<number>(`${id}/active`, { id, isActive })
			.then(r => {
				useSuccess('Grant program saved');
				return r;
			})
			.catch(() => {
				useError('Error saving grant program');
				return undefined;
			});

	return { Get, Add, Edit, EditActive, IsLoading: ep.IsLoading };
};
