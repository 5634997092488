import { AwardStatus } from 'Models/AwardStatusModels';
import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';

export const useAwardStatusesEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('awardstatuses');

	const Get = () =>
		ep.Get<AwardStatus[]>().catch(() => {
			useError('Error getting award statuses');
			return [] as AwardStatus[];
		});
	return { Get, IsLoading: ep.IsLoading };
};
