import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { Preferences } from 'Models/UserModels';

export const useUsersEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('users');

	const Get = (id: string) =>
		ep.Get<Preferences>(id).catch(() => {
			useError('Error getting user');
			return undefined;
		});

	const Edit = (id: string, dto: Preferences) =>
		ep
			.Put<Preferences>(id, dto)
			.then(r => {
				useSuccess('User saved');
				return r;
			})
			.catch(() => {
				useError('Error saving user');
				return undefined;
			});

	const Add = () =>
		ep.Post<string>({}).catch(() => {
			useError('Error adding user');
			return undefined;
		});

	return { Get, Edit, Add, IsLoading: ep.IsLoading };
};
