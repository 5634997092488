import React, { useEffect, useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { FormControl, Button, LinearProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { FormDebugging, OrganizationTypeSelect } from '../Fields';
import { useOrganizationsEndpoint } from 'Endpoints';
import { OrganizationType, EditOrganization, AddOrganization } from 'Models/OrganizationModels';
import { EditOrganizationSchema } from '../ValidationSchemas/OrganizationSchema';

// props to be provided by parent component
// this is the components external interface
export interface IEditOrganizationFormProps {
	onSuccess: () => void;
	organization: EditOrganization;
}

export const EditOrganizationForm = (props: IEditOrganizationFormProps) => {
	const ep = useOrganizationsEndpoint();
	const [organizationTypes, setOrganizationTypes] = useState<OrganizationType[]>([]);

	useEffect(() => {
		ep.Types().then(r => setOrganizationTypes(r));
	}, []);

	const classes = formStyles();
	const nameof = useNameof<AddOrganization>();

	const [organization, setOrganization] = useState(props.organization);

	const handleSuccess = () => {
		setOrganization(new EditOrganization(0));
		props.onSuccess();
	};

	return (
		<React.Fragment>
			<Formik
				initialValues={organization}
				validationSchema={EditOrganizationSchema}
				onSubmit={(values, { setSubmitting }) => {
					ep.Edit(values).then(response => response && handleSuccess());
					setSubmitting(false);
				}}
			>
				{formprops => (
					<Form className={classes.flexForm}>
						<FormControl>
							<Field label="Acronym" fullWidth={true} name={nameof('shortName')} component={TextField} />
						</FormControl>
						<FormControl>
							<Field label="Name" fullWidth={true} name={nameof('name')} component={TextField} />
						</FormControl>
						<FormControl>
							<OrganizationTypeSelect
								organizationTypes={organizationTypes}
								name={nameof('organizationTypeId')}
							/>
						</FormControl>
						<FormControl>
							{formprops.isSubmitting && <LinearProgress />}
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={formprops.isSubmitting}
								onSubmit={formprops.submitForm}
							>
								{formprops.isSubmitting ? 'Saving...' : 'Save'}
							</Button>
						</FormControl>
						<FormDebugging />
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};
