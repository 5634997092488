import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { AddSubcontractLine, SubcontractLine, EditSubcontractLine } from 'Models/SubcontractLineModels';

export const useSubcontractLinesEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('subcontractlines');

	const Add = (dto: AddSubcontractLine) =>
		ep
			.Post<SubcontractLine>(dto)
			.then(r => {
				useSuccess('Subcontract line added');
				return r;
			})
			.catch(() => {
				useError('Error adding subcontract line');
				return undefined;
			});

	const Edit = (dto: EditSubcontractLine) =>
		ep
			.Put<SubcontractLine>(dto.id, dto)
			.then(r => {
				useSuccess('Subcontract line saved');
				return r;
			})
			.catch(() => {
				useError('Error saving subcontract line');
				return undefined;
			});

	const Delete = (id: number) =>
		ep
			.Delete<number>(id)
			.then(r => {
				useSuccess('Subcontract line deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting subcontract line');
				return undefined;
			});

	return { Add, Edit, Delete, IsLoading: ep.IsLoading };
};
