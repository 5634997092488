import React from 'react';
import { Typography, Box } from '@material-ui/core';

export interface ITabPanelProps {
	/** The index of the selected tab panel for this tab group */
	selectedTab: number;
	/** This tab panel's 0-based index in the list of panels */
	index: number;
}

export const TabPanel = (props: React.PropsWithChildren<ITabPanelProps>) => {
	return (
		<Typography component="div" role="tabpanel" hidden={props.index !== props.selectedTab}>
			<Box p={3}>{props.children}</Box>
		</Typography>
	);
};
