import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';

import { Fade, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { TableIcons } from 'Common/Tables';
import AddIcon from '@material-ui/icons/Add';
// import TrashIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import { AddOrganizationForm } from 'Common/Forms/Add';
import { TableStyles, Colors } from 'Common/Styles';
import { EditOrganizationForm } from 'Common/Forms/Edit';
import { useOrganizationsEndpoint } from 'Endpoints';
import { Organization, EditOrganization } from 'Models/OrganizationModels';

enum ModalOptions {
	None = 0,
	Add = 1,
	Edit = 2,
}

export const Organizations = () => {
	const ep = useOrganizationsEndpoint();
	const [organizations, setOrganizations] = useState<Organization[]>([]);

	const [openModal, setOpenModal] = useState<ModalOptions>(ModalOptions.None);
	const colors = Colors.useColors();
	const [editOrganization, setEditOrganization] = useState<EditOrganization>(new EditOrganization(0));

	useEffect(() => {
		DOMHelper.setPageTitle('Organizations Admin');
		ep.Get(true).then(r => setOrganizations(r));
	}, []);

	const handleLock = (id: number, currentStatus?: boolean) => {
		currentStatus = currentStatus === undefined ? false : currentStatus;
		ep.EditActive(id, !currentStatus).then(() => ep.Get(true).then(r => setOrganizations(r)));
	};

	const handleEditSucess = () => {
		setOpenModal(ModalOptions.None);
		ep.Get(true).then(r => setOrganizations(r));
	};

	useEffect(() => {
		if (editOrganization.id !== 0) {
			setOpenModal(ModalOptions.Edit);
		}
	}, [editOrganization]);

	return (
		<Fade in={true}>
			<React.Fragment>
				<MaterialTable
					columns={[
						{ title: 'Acronym', field: 'shortName' },
						{ title: 'Name', field: 'name' },
						{ title: 'Type', field: 'organizationTypeName' },
					]}
					data={organizations}
					title="Organizations"
					icons={TableIcons}
					options={{
						pageSize: 10,
						headerStyle: TableStyles.headerStyle,
						exportAllData: true,
						actionsColumnIndex: -1,
						rowStyle: (rowData: Organization) => ({
							opacity: !rowData.isActive ? 0.4 : 1,
						}),
					}}
					isLoading={ep.IsLoading}
					actions={[
						{
							icon: () => <AddIcon className={colors.primary} />,
							tooltip: 'Add Organization',
							position: 'toolbar',
							onClick: event => setOpenModal(ModalOptions.Add),
						},
						{
							icon: () => <EditIcon className={colors.warning} />,
							tooltip: 'Edit',
							onClick: (event, rowData: Organization) =>
								setEditOrganization(
									new EditOrganization(
										rowData.id,
										rowData.shortName,
										rowData.name,
										rowData.organizationTypeId
									)
								),
						},
						{
							icon: () => <LockIcon />,
							tooltip: 'Lock/Set Inactive',
							onClick: (event, rowData: Organization) => handleLock(rowData.id, rowData.isActive),
						},
						// {
						// 	icon: () => <TrashIcon className={colors.error} />,
						// 	tooltip: 'Delete',
						// 	onClick: event => alert('delete clicked'),
						// },
					]}
				/>

				<Dialog
					maxWidth="md"
					open={openModal === ModalOptions.Add}
					onClose={() => setOpenModal(ModalOptions.None)}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Add Organization</DialogTitle>
					<DialogContent>
						<AddOrganizationForm onSuccess={() => setOpenModal(ModalOptions.None)} />
					</DialogContent>
				</Dialog>

				<Dialog
					maxWidth="md"
					open={openModal === ModalOptions.Edit}
					onClose={() => setOpenModal(ModalOptions.None)}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Edit Organization</DialogTitle>
					<DialogContent>
						<EditOrganizationForm organization={editOrganization} onSuccess={handleEditSucess} />
					</DialogContent>
				</Dialog>
			</React.Fragment>
		</Fade>
	);
};
