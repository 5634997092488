import { connect, FormikState } from 'formik';
import { ReactElement, useEffect, useRef } from 'react';

interface OuterProps<Values> {
	onChange: (currentState: FormikState<Values>, prevState: FormikState<Values> | null) => void;
}

interface Props<Values> extends OuterProps<Values> {
	formik: FormikState<Values>;
}

const Effect = <Values,>({ formik, onChange }: Props<Values>) => {
	const ref = useRef<FormikState<Values> | null>(null);
	useEffect(() => {
		onChange(formik, ref.current);
		ref.current = formik;
	}, [formik]);

	return null;
};

export default connect(Effect) as <Values>(props: OuterProps<Values>) => ReactElement;
