/** used when getting organizations, and the model sent from the API when editing an organization */
export class Organization {
	id: number = 0;
	organizationTypeName: string = '';
	organizationTypeId: number = 0;
	name: string = '';
	shortName: string = '';
	isActive?: boolean;
}

export class AddOrganization {
	shortName: string = '';
	name: string = '';
	organizationTypeId: number = 0;
}

export class EditOrganization {
	id: number;
	shortName: string;
	name: string;
	organizationTypeId: number;

	constructor(id: number, shortName: string = '', name: string = '', organizationTypeId: number = 0) {
		this.id = id;
		this.shortName = shortName;
		this.name = name;
		this.organizationTypeId = organizationTypeId;
	}
}

export class OrganizationType {
	id: number = 0;
	name: string = '';
}
