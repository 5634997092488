import { createAction, ActionType } from 'typesafe-actions';
import { AgencyModels } from 'Models';
import { GrantProgram } from 'Models/GrantProgramModels';
import { Institute } from 'Models/InstituteModels';
import { FundingType } from 'Models/FundingTypeModels';
import { Organization } from 'Models/OrganizationModels';
import { AwardStatus } from 'Models/AwardStatusModels';

interface Dropdowns {
	grantPrograms: GrantProgram[];
	institutes: Institute[];
	agencies: AgencyModels.Agency[];
	fundingTypes: FundingType[];
	organizations: Organization[];
	awardStatuses: AwardStatus[];
}

// actions
const Actions = {
	UserLoggedIn: createAction('DROPDOWNS_LOADED')<Dropdowns>(),
};

export class ProposalDetailsState implements Dropdowns {
	isLoading: boolean = true;
	grantPrograms: GrantProgram[] = [];
	institutes: Institute[] = [];
	agencies: AgencyModels.Agency[] = [];
	fundingTypes: FundingType[] = [];
	organizations: Organization[] = [];
	awardStatuses: AwardStatus[] = [];
}
export const ProposalDetailsReducer = (
	state: ProposalDetailsState,
	action: ActionType<typeof Actions>
): ProposalDetailsState => {
	switch (action.type) {
		case 'DROPDOWNS_LOADED':
			return { ...state, ...action.payload, isLoading: false };

		default:
			return state;
	}
};
