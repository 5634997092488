import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import {
	AddSupportAccount,
	SupportAccount,
	EditSupportAccount,
	DeleteSupportAccount,
} from 'Models/SupportAccountModels';

export const useSupportAccountsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('supportaccounts');

	const Add = (dto: AddSupportAccount) =>
		ep
			.Post<SupportAccount>(dto)
			.then(r => {
				useSuccess('Support account added');
				return r;
			})
			.catch(() => {
				useError('Error adding support account');
				return undefined;
			});

	const Edit = (dto: EditSupportAccount) =>
		ep
			.Put<SupportAccount>(dto.organizationId, dto)
			.then(r => {
				useSuccess('Support account saved');
				return r;
			})
			.catch(() => {
				useError('Error saving support account');
				return undefined;
			});

	const Delete = (dto: DeleteSupportAccount) =>
		ep
			.Delete<number>(
				`?proposalId=${dto.proposalId}&organizationId=${dto.organizationId}&accountNumber=${dto.accountNumber}`
			)
			.then(r => {
				useSuccess('Support account deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting support account');
				return undefined;
			});

	return { Add, Edit, Delete, IsLoading: ep.IsLoading };
};
