export const truncate = (str: string, length = 100, ending = '...'): string => {
	if (str.length > length) {
		return str.substring(0, length - ending.length) + ending;
	}
	return str;
};

export const isEmptyOrWhitespace = (str: string): boolean => {
	return !str || str.length === 0 || /^\s*$/.test(str);
};

/** Formats the supplied number to currency with exactly two decimal places */
export const money = (x: number | undefined): string => {
	if (x === undefined) {
		x = 0;
	}
	const curr = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(x);

	return curr;
};

/** Formats the supplied number as a percentage. The number should be a decimal between 0 and 1 */
export const percentage = (x: number | undefined): string => {
	if (!x) {
		x = 0;
	}

	const percent = (x * 100).toFixed(2) + '%';

	return percent;
};

export const decimal = (x: number | undefined): string => {
	let deci = '0.00';
	if (x !== undefined) {
		deci = x.toFixed(2);
	}

	return deci;
};
