export enum ContractToValues {
	Person = 'person',
	Organization = 'organization',
}

/** for showing subcontract lines in the subcontract table, and the return dto from adding or editing a subcontract */
export class SubcontractLine {
	id: number = 0;
	name: string = '';
	accountNumber: string = '';
	mNumber: string = '';
	deptPI: string = '';
	bookkeeper: string = '';
	amount: number = 0;
	matchAmount: number = 0;
	startsOn?: Date;
	endsOn?: Date;
}

/** for adding a new subcontract line */
export class AddSubcontractLine {
	personId?: number = 0;
	contractTo: ContractToValues = ContractToValues.Organization;
	organizationId?: number = 0;
	accountNumber: string = '';
	mNumber: string = '';
	deptPI: string = '';
	bookkeeper: string = '';
	amount: number = 0;
	matchAmount: number = 0;
	startsOn?: Date = new Date();
	endsOn?: Date = new Date();
	proposalId: number;

	constructor(proposalId: number) {
		this.proposalId = proposalId;
	}
}

/** for editing an existing subcontract line */
export class EditSubcontractLine {
	id: number;
	mNumber: string;
	deptPI: string;
	bookkeeper: string;
	amount: number;
	matchAmount: number;
	startsOn?: Date;
	endsOn?: Date;

	constructor(
		id: number,
		mNumber: string = '',
		deptPI: string = '',
		bookkeeper: string = '',
		amount: number = 0,
		matchAmount: number = 0,
		startsOn?: Date,
		endsOn?: Date
	) {
		this.id = id;
		this.mNumber = mNumber;
		this.deptPI = deptPI;
		this.bookkeeper = bookkeeper;
		this.amount = amount;
		this.matchAmount = matchAmount;
		this.startsOn = startsOn;
		this.endsOn = endsOn;
	}
}
