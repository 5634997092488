import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { PersonModels } from 'Models';
import { GlobalAutocomplete } from '.';
import { Person } from 'Models/PersonModels';

export interface IPersonSelectProps {
	/** The array of people that will be shown as dropdown items */
	people: PersonModels.Person[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
	/** IMPORTANT!!! Mulitple must be set to false for single select autocompletes or it bombs the application */
	multiple?: boolean;
	/** For controlled autocomplete */
	value?: Person;
	/** For making this field optional */
	required?: boolean;
	onChange?: (v: number) => void;
}

export const PersonSelect = (props: IPersonSelectProps) => {
	// because it is stupid and won't load properly for a controlled select correctly!!
	const [val, setVal] = useState(props.value);

	useEffect(() => {
		setVal(props.value);
	}, [props.value]);
	return (
		<Field
			name={props.name}
			key="person-auto"
			component={(p: any) => (
				<GlobalAutocomplete
					{...p}
					label={props.label || 'Person'}
					options={props.people.sort((a, b) => ('' + a.lastName).localeCompare(b.lastName))}
					value={val}
					setField={props.required === undefined ? true : props.required}
					inputChanged={(v: Person) => setVal(v)}
				/>
			)}
			multiple={props.multiple === undefined ? false : props.multiple}
			getOptionLabel={(option: Person) => option.fullName}
			fullWidth={true}
		/>
	);
};
