export class WorkItem {
	id = 0;
	title = '';
	startsOn: Nullable<Date> = new Date();
	endsOn = new Date();
	proposalId = 0;
	proposalName = '';
	teamId: Nullable<number> = null;
	teamName = '';
	personId: Nullable<number> = null;
	personName = '';
	parentId: Nullable<number> = null;
	parentTitle = '';
}

export class AddWorkItem {
	title = '';
	startsOn: Nullable<Date> = new Date();
	endsOn = new Date();
	proposalId;
	teamId: Nullable<number> = null;
	personId: Nullable<number> = null;
	parentId: Nullable<number> = null;

	constructor(
		proposalId: number,
		startsOn: Nullable<Date> = new Date(),
		teamId: Nullable<number> = null,
		personId: Nullable<number> = null,
		parentId: Nullable<number> = null,
	) {
		this.proposalId = proposalId;
		this.startsOn = startsOn;
		this.teamId = teamId;
		this.personId = personId;
		this.parentId = parentId;
	}
}

export class EditWorkItem {
	id: number;
	title: string;
	startsOn: Nullable<Date> = new Date();
	endsOn: Date;
	proposalId: number;
	teamId: Nullable<number>;
	personId: Nullable<number>;
	parentId: Nullable<number>;

	constructor(details: WorkItem) {
		this.id = details.id;
		this.title = details.title;
		this.startsOn = details.startsOn;
		this.endsOn = details.endsOn;
		this.proposalId = details.proposalId;
		this.teamId = details.teamId;
		this.personId = details.personId;
		this.parentId = details.parentId;
	}
}
