import React, { useState } from 'react';

import {
	Theme,
	Grid,
	Typography,
	Avatar,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
} from '@material-ui/core';
import { teal, indigo, red } from '@material-ui/core/colors/';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { Colors } from 'Common/Styles';
import { RoleModels } from 'Models';
import cuid from 'cuid';
import { AddRoleAssignmentForm } from 'Common/Forms/Add';

export interface IPeopleCardProps {
	proposalId: number;
	roleAssignments: RoleModels.RoleAssignment[];
	editable: boolean;
	onAddSuccess?: (roleAssignment: RoleModels.RoleAssignment) => void;
	onDeleteClick?: (personId: number, roleId: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		marginBottom: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(0.5),
		color: theme.palette.text.secondary,
		textTransform: 'uppercase',
		fontSize: '14px',
	},
	header: {
		marginBottom: theme.spacing(0.5),
		color: theme.palette.text.secondary,
		textTransform: 'uppercase',
		fontSize: '16px',
	},
	details: {
		display: 'flex',
		alignItems: 'center',
	},
	detailsButtons: {
		marginLeft: 'auto',
	},
	avatar: {
		marginRight: theme.spacing(1.5),
	},
	avatarPrimary: {
		backgroundColor: teal[500],
	},
	avatarPrincipal: {
		backgroundColor: indigo[500],
	},
	avatarBusiness: {
		backgroundColor: red[700],
	},
	name: {
		color: theme.palette.text.primary,
		fontSize: '16px',
		lineHeight: '1.25',
	},
	textRight: {
		textAlign: 'right',
	},
}));

enum ModalOptions {
	None = 0,
	Add = 1,
}

const PersonBlock = (props: {
	roleAssignment: RoleModels.RoleAssignment;
	editable: boolean;
	onDeleteClick: (personId: number, roleId: number) => void;
}) => {
	const colors = Colors.useColors();
	const classes = useStyles();

	const initials = props.roleAssignment.personName
		? props.roleAssignment.personName
			.split(' ')
			.map(name => name.charAt(0))
			.join('')
		: '';

	let avatarClass = '';
	const lowercaseHeading = props.roleAssignment.roleName.toLowerCase();
	if (lowercaseHeading.includes('primary')) {
		avatarClass = classes.avatarPrimary;
	} else if (lowercaseHeading.includes('principal')) {
		avatarClass = classes.avatarPrincipal;
	} else if (lowercaseHeading.includes('business')) {
		avatarClass = classes.avatarBusiness;
	}

	return (
		<Grid item={true} xs={12}>
			<Grid item={true} className={classes.container}>
				<Typography component="h2" className={classes.heading}>
					{props.roleAssignment.roleName}
				</Typography>
				<Grid className={classes.details} item={true}>
					<Grid>
						<Avatar className={`${classes.avatar} ${avatarClass}`}>{initials}</Avatar>
					</Grid>
					<Grid item={true}>
						<Typography component="p" className={classes.name}>
							{props.roleAssignment.personName}
						</Typography>
					</Grid>
					<Grid className={classes.detailsButtons} item={true}>
						{props.editable && (
							<IconButton
								edge="end"
								aria-label="delete"
								onClick={() =>
									props.onDeleteClick(props.roleAssignment.personId, props.roleAssignment.roleId)
								}
							>
								<DeleteIcon className={colors.error} />
							</IconButton>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const PeopleCard = (props: IPeopleCardProps) => {
	const sortOrder = ['Primary Contact', 'Principal Investigator', 'Co-PI', 'Business Contact'];
	const getSortOrder = (role: string) => {
		const i = sortOrder.indexOf(role);
		return i >= 0 ? i : 99; // do this so any missing role names just go to the end
	};

	const classes = useStyles();
	const [openModal, setOpenModal] = useState<ModalOptions>(ModalOptions.None);

	const closeModal = () => setOpenModal(ModalOptions.None);

	const handleAddSuccess = (roleAssignment: RoleModels.RoleAssignment) => {
		closeModal();
		props.onAddSuccess && props.onAddSuccess(roleAssignment);
	};

	return (
		<React.Fragment>
			<Grid container={true}>
				{(props.editable || props.roleAssignments.length) ? (
					<Typography component="h2" className={classes.header}>
						Role Assignments
					</Typography>
				) : ''}
				{props.editable && (
					<Grid item={true} sm={12} className={classes.textRight}>
						<Button
							startIcon={<AddIcon />}
							color="primary"
							variant="contained"
							onClick={() => setOpenModal(ModalOptions.Add)}
						>
							Add Roles
						</Button>
					</Grid>
				)}
				{props.roleAssignments
					.sort((a, b) => getSortOrder(a.roleName) - getSortOrder(b.roleName))
					.map(a => (
						<PersonBlock
							key={cuid()}
							roleAssignment={a}
							editable={props.editable}
							onDeleteClick={(personId, roleId) =>
								props.onDeleteClick && props.onDeleteClick(personId, roleId)
							}
						/>
					))}
			</Grid>
			<Dialog
				maxWidth="sm"
				open={openModal === ModalOptions.Add}
				onClose={closeModal}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Add Role Assignment</DialogTitle>
				<DialogContent>
					<AddRoleAssignmentForm proposalId={props.proposalId} onSuccess={handleAddSuccess} />
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};
