import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { MenuItem } from '@material-ui/core';
import { InstituteModels } from 'Models';

export interface IInstituteSelectProps {
	/** The array of institutes that will be shown as dropdown items */
	institutes: InstituteModels.Institute[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
}

export const InstituteSelect = (props: IInstituteSelectProps) => {
	return (
		<Field
			component={TextField}
			name={props.name}
			select={true}
			label={props.label || 'Institute/Dept'}
			fullWidth={true}
			className={props.className}
		>
			<MenuItem key={'institute-select-0'} value="0">
				- Select -
			</MenuItem>
			{props.institutes
				.sort((a, b) => ('' + a.name).localeCompare(b.name))
				.map(i => {
					return (
						<MenuItem key={`institute-select-${i.id}`} value={i.id}>
							{i.name}
						</MenuItem>
					);
				})}
		</Field>
	);
};
