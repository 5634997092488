/*hehe...role models...get it?  ( ͡° ͜ʖ ͡°) */

/* Used for showing roles in a dropdown */
export class Role {
	id: number = 0;
	name: string = '';
}

/* used for showing a proposal's role assignments */
export class RoleAssignment {
	personName: string = '';
	roleName: string = '';
	personId: number = 0;
	roleId: number = 0;
	username: string = '';
}

/* used for adding or deleting a role assignment */
export class ModifyRoleAssignment {
	proposalId: number;
	personId: number = 0;
	roleId: number = 0;

	constructor(proposalId: number) {
		this.proposalId = proposalId;
	}
}
