import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { Organization, OrganizationType, AddOrganization, EditOrganization } from 'Models/OrganizationModels';

export const useOrganizationsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('organizations');

	const Get = (includeInactive: boolean = false) =>
		ep.Get<Organization[]>(includeInactive ? 'all' : '').catch(() => {
			useError('Error getting organizations');
			return [] as Organization[];
		});

	const Add = (dto: AddOrganization) =>
		ep
			.Post<Organization>(dto)
			.then(r => {
				useSuccess('Organization added');
				return r;
			})
			.catch(() => {
				useError('Error adding organization');
				return undefined;
			});

	const Edit = (dto: EditOrganization) =>
		ep
			.Put<Organization>(dto.id, dto)
			.then(r => {
				useSuccess('Organization saved');
				return r;
			})
			.catch(() => {
				useError('Error saving organization');
				return undefined;
			});

	const EditActive = (id: number, isActive: boolean) =>
		ep
			.Put<number>(`${id}/active`, { id, isActive })
			.then(r => {
				useSuccess('Organization saved');
				return r;
			})
			.catch(() => {
				useError('Error saving organization');
				return undefined;
			});

	const Types = () =>
		ep.Get<OrganizationType[]>('types').catch(() => {
			useError('Error getting organization types');
			return [];
		});

	return { Get, Add, Edit, EditActive, Types, IsLoading: ep.IsLoading };
};
