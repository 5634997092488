import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { OrganizationModels } from 'Models';
import { Organization } from 'Models/OrganizationModels';
import { GlobalAutocomplete } from '.';

export interface IOrganizationSelectProps {
	/** The array of organizations that will be shown as dropdown items */
	organizations: OrganizationModels.Organization[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
	/** IMPORTANT!!! Mulitple must be set to false for single select autocompletes or it bombs the application */
	multiple?: boolean;
	/** For controlled autocomplete */
	value?: Organization;
	/** For autosave, set to false for all others set to true */
	setField: boolean;
	/** For save on change in the involved parties card */
	onChange?: (v: number) => void;
}

export const OrganizationSelect = (props: IOrganizationSelectProps) => {
	const [val, setVal] = useState<Organization>();

	useEffect(() => {
		setVal(props.value);
	}, [props.value]);

	return (
		<React.Fragment>
			<Field
				name={props.name}
				key="funding-source-auto"
				component={(p: any) => (
					<GlobalAutocomplete
						{...p}
						label={props.label || 'Funding Source'}
						options={props.organizations.sort((a, b) => ('' + a.name).localeCompare(b.name))}
						value={val}
						namefield="fundingSourceName"
						setField={props.setField}
						inputChanged={(v: Organization) => setVal(v)}
						valueChanged={(v: Organization) => props.onChange && props.onChange(v.id)}
					/>
				)}
				multiple={props.multiple === undefined ? false : props.multiple}
				getOptionLabel={(option: Organization) => option.name}
				fullWidth={true}
			/>
		</React.Fragment>
	);
};
