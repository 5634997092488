import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { InvolvedParty } from 'Models/ProposalModels';

export const useInvolvedPartiesEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('involvedparties');

	const Add = (proposalId: number, organizationId: number) =>
		ep
			.Post<InvolvedParty>({ proposalId, organizationId })
			.then(r => {
				useSuccess('Involved party added');
				return r;
			})
			.catch(() => {
				useError('Error adding involved party');
				return undefined;
			});

	const Delete = (proposalId: number, organizationId: number) =>
		ep
			.Delete<InvolvedParty>(`?proposalId=${proposalId}&organizationId=${organizationId}`)
			.then(r => {
				useSuccess('Involved party deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting involved party');
				return undefined;
			});

	return { Add, Delete, IsLoading: ep.IsLoading };
};
