import { RoleAssignment } from 'Models/RoleModels';
// put these methods in a helper instead of class methods on proposal since methods didn't work with material table

export const primaryContact = (roleAssignments: RoleAssignment[]) => {
	const ra = roleAssignments.find(a => a.roleName === 'Primary Contact');
	return ra !== undefined ? ra.personName : '';
};

export const principalInvestigator = (roleAssignments: RoleAssignment[]) => {
	const ra = roleAssignments.find(a => a.roleName === 'Principal Investigator');
	return ra !== undefined ? ra.personName : '';
};

export const businessContact = (roleAssignments: RoleAssignment[]) => {
	const ra = roleAssignments.find(a => a.roleName === 'Business Contact');
	return ra !== undefined ? ra.personName : '';
};

export const coPIs = (roleAssignments: RoleAssignment[]) => {
	const ra = roleAssignments.filter(a => a.roleName === 'Co-PI');
	return ra.length ? ra.map(a => a.personName).join(', ') : '';
};
