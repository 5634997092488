import React, { useEffect } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { StyledCard } from 'Common/Cards';
import { Grid } from '@material-ui/core';

export interface INotAuthorizedProps {}

export const NotAuthorized = (props: INotAuthorizedProps) => {
	useEffect(() => {
		DOMHelper.setPageTitle('Not Authorized');
	}, []);

	return (
		<Grid container={true}>
			<Grid item={true} md={4}>
				<StyledCard title="Not Authorized">
					<p>
						You are not authorized to view this page. If you believe this is incorrect, please{' '}
						<a href="mailto:nri-web@ag.tamu.edu">contact us</a>.
					</p>
				</StyledCard>
			</Grid>
		</Grid>
	);
};

export default NotAuthorized;
