import React, { useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { FormControl, Button, LinearProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { useGrantProgramsEndpoint } from 'Endpoints';
import { AddGrantProgram } from 'Models/GrantProgramModels';
import { AddGrantProgramSchema } from '../ValidationSchemas/GrantProgramSchema';
import { FormDebugging } from '../Fields';

// props to be provided by parent component
// this is the components external interface
export interface IAddGrantProgramFormProps {
	onSuccess: () => void;
}

export const AddGrantProgramForm = (props: IAddGrantProgramFormProps) => {
	const ep = useGrantProgramsEndpoint();
	const classes = formStyles();
	const [grantProgram, setGrantProgram] = useState(new AddGrantProgram());
	const nameof = useNameof<AddGrantProgram>();

	const handleSuccess = () => {
		setGrantProgram(new AddGrantProgram());
		props.onSuccess();
	};

	return (
		<Formik
			initialValues={grantProgram}
			validationSchema={AddGrantProgramSchema}
			onSubmit={(values, { setSubmitting }) => {
				ep.Add(values).then(r => r && handleSuccess());
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form className={classes.flexForm}>
					<FormControl>
						<Field
							label="Acronym"
							fullWidth={true}
							type="text"
							name={nameof('shortName')}
							component={TextField}
						/>
					</FormControl>
					<FormControl>
						<Field label="Name" fullWidth={true} name={nameof('name')} component={TextField} />
					</FormControl>
					<FormControl>
						{formprops.isSubmitting && <LinearProgress />}
						<Button
							type="submit"
							color="primary"
							variant="contained"
							disabled={formprops.isSubmitting}
							onSubmit={formprops.submitForm}
						>
							{formprops.isSubmitting ? 'Saving...' : 'Add'}
						</Button>
					</FormControl>
					<FormDebugging />
				</Form>
			)}
		</Formik>
	);
};
