import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { AddCost, Cost, EditCost } from 'Models/CostModels';

export const useCostsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('costs');

	const Add = (dto: AddCost) =>
		ep
			.Post<Cost>(dto)
			.then(r => {
				useSuccess('Cost added');
				return r;
			})
			.catch(() => {
				useError('Error adding cost');
				return undefined;
			});

	const Edit = (dto: EditCost) =>
		ep
			.Put<Cost>(dto.id, dto)
			.then(r => {
				useSuccess('Cost saved');
				return r;
			})
			.catch(() => {
				useError('Error saving cost');
				return undefined;
			});

	const Delete = (id: number) =>
		ep
			.Delete<number>(id)
			.then(r => {
				useSuccess('Cost deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting cost');
				return undefined;
			});

	return { Add, Edit, Delete, IsLoading: ep.IsLoading };
};
