import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

export interface IStyledCardProps {
	title: string;
}

export const StyledCard = (props: React.PropsWithChildren<IStyledCardProps>) => {
	return (
		<Card>
			<CardContent>
				<Typography gutterBottom={true} variant="h5" component="h2">
					{props.title}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="div">
					{props.children}
				</Typography>
			</CardContent>
		</Card>
	);
};
