import { Institute } from 'Models/InstituteModels';
import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';

export const useInstitutesEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('institutes');

	const Get = () =>
		ep.Get<Institute[]>().catch(() => {
			useError('Error getting institutes');
			return [] as Institute[];
		});
	return { Get, IsLoading: ep.IsLoading };
};
