import React, { useState, useEffect } from 'react';
import { FileModels } from 'Models';
import { FormControl, Button, LinearProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { FileCategorySelect, FormDebugging } from '../Fields';
import { nameof } from 'Common/Helpers/ReactHelper';
import { useFilesEndpoint } from 'Endpoints';
import { FileCategory } from 'Models/FileModels';
import { EditFileSchema } from '../ValidationSchemas/FileSchema';

// props to be provided by parent component
// this is the components external interface
export interface IEditFileFormProps {
	onSuccess: (file: FileModels.File) => void;
	file: FileModels.EditFile;
}

export const EditFileForm = (props: IEditFileFormProps) => {
	const ep = useFilesEndpoint();
	const [fileCategories, setFileCategories] = useState<FileCategory[]>([]);
	const classes = formStyles();
	const [file, setFile] = useState(props.file);

	useEffect(() => {
		ep.Categories().then(r => setFileCategories(r));
	}, []);

	const handleSuccess = (editedFile: FileModels.File) => {
		setFile(new FileModels.EditFile());
		props.onSuccess(editedFile);
	};

	return (
		<React.Fragment>
			<Formik
				initialValues={file}
				validationSchema={EditFileSchema}
				onSubmit={(values, { setSubmitting }) => {
					ep.Edit(values).then(response => response && handleSuccess(response));
					setSubmitting(false);
				}}
			>
				{formprops => (
					<Form className={classes.flexForm}>
						{' '}
						<FormControl>
							<Field component={TextField} label="Title" fullWidth={true} name={nameof('title')} />
						</FormControl>
						<FormControl>
							<Field
								component={TextField}
								label="Description"
								fullWidth={true}
								name={nameof('description')}
							/>
						</FormControl>
						<FormControl>
							<FileCategorySelect fileCategories={fileCategories} name={nameof('fileCategoryId')} />
						</FormControl>
						<FormControl>
							{formprops.isSubmitting && <LinearProgress />}
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={formprops.isSubmitting}
								onSubmit={formprops.submitForm}
							>
								{formprops.isSubmitting ? 'Saving...' : 'Save'}
							</Button>
						</FormControl>
						<FormDebugging />
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};
