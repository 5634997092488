import React, { useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { FormControl, Button, LinearProgress, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { FormDebugging, GlobalDatePicker } from '../Fields';
import { useSubcontractLinesEndpoint } from 'Endpoints';
import { SubcontractLine, EditSubcontractLine } from 'Models/SubcontractLineModels';
import { EditSubcontractLineSchema } from '../ValidationSchemas/SubcontractLineSchema';

// props to be provided by parent component
// this is the components external interface
export interface IEditSubcontractLineFormProps {
	onSuccess: (sub: SubcontractLine) => void;
	subcontractLine?: EditSubcontractLine;
}

export const EditSubcontractLineForm = (props: IEditSubcontractLineFormProps) => {
	const ep = useSubcontractLinesEndpoint();
	const nameof = useNameof<EditSubcontractLine>();
	const classes = formStyles();

	const [subcontractLine, setSubcontractLine] = useState(
		props.subcontractLine ? props.subcontractLine : new EditSubcontractLine(0)
	);

	const handleSuccess = (sub: SubcontractLine) => {
		props.onSuccess(sub);
		setSubcontractLine(new EditSubcontractLine(0));
	};

	return (
		<React.Fragment>
			<Formik
				initialValues={subcontractLine}
				validationSchema={EditSubcontractLineSchema}
				onSubmit={(values, { setSubmitting }) => {
					ep.Edit(values).then(response => response && handleSuccess(response));
					setSubmitting(false);
				}}
			>
				{formprops => (
					<Form className={classes.flexForm}>
						<Grid container={true} direction="row">
							<FormControl>
								<Field
									component={TextField}
									label="M Number"
									fullWidth={true}
									name={nameof('mNumber')}
								/>
							</FormControl>
							<FormControl>
								<Field
									component={TextField}
									label="Dept. PI"
									fullWidth={true}
									name={nameof('deptPI')}
								/>
							</FormControl>
							<FormControl>
								<Field
									component={TextField}
									label="Bookkeeper"
									fullWidth={true}
									name={nameof('bookkeeper')}
								/>
							</FormControl>
							<FormControl>
								<Field component={TextField} label="Amount" fullWidth={true} name={nameof('amount')} />
							</FormControl>
							<FormControl>
								<Field
									component={TextField}
									label="Match Amount"
									fullWidth={true}
									name={nameof('matchAmount')}
								/>
							</FormControl>
							<FormControl>
								<Field component={GlobalDatePicker} label="Starts On" name={nameof('startsOn')} />
							</FormControl>
							<FormControl>
								<Field component={GlobalDatePicker} label="Ends On" name={nameof('endsOn')} />
							</FormControl>
							<FormControl>
								{formprops.isSubmitting && <LinearProgress />}
								<Button
									type="submit"
									color="primary"
									variant="contained"
									disabled={formprops.isSubmitting}
									onSubmit={formprops.submitForm}
								>
									{formprops.isSubmitting ? 'Saving...' : 'Save'}
								</Button>
							</FormControl>
							<FormDebugging />
						</Grid>
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};
