import { AddWorkItem, EditWorkItem } from 'Models/WorkItemModels';
import { SchemaOf, object } from 'yup';
import {
	numberSchema,
	dateSchema,
	stringSchema,
	nullableNumberSchema,
	nullableDateSchema,
} from './BaseSchemas';

export const EditWorkItemSchema: SchemaOf<EditWorkItem> = object({
	id: numberSchema('Id'),
	startsOn: nullableDateSchema('Starts On'),
	endsOn: dateSchema('Ends On'),
	title: stringSchema('Title'),
	proposalId: numberSchema('ProposalId'),
	teamId: nullableNumberSchema(),
	personId: nullableNumberSchema(),
	parentId: nullableNumberSchema(),
});

export const AddWorkItemSchema: SchemaOf<AddWorkItem> = object({
	startsOn: nullableDateSchema('Starts On'),
	endsOn: dateSchema('Ends On'),
	title: stringSchema('Title'),
	proposalId: numberSchema('ProposalId'),
	teamId: nullableNumberSchema(),
	personId: nullableNumberSchema(),
	parentId: nullableNumberSchema(),
});
