/** used for showing notes on the proposal details page */
export class Note {
	id: number = 0;
	proposalId: number = 0;
	text: string = '';
	submittedAt: Date = new Date();
	username: string = '';
	displayName: string = '';
}

/** for adding new notes */
export class AddNote {
	proposalId: number;
	text: string = '';

	constructor(proposalId: number) {
		this.proposalId = proposalId;
	}
}
