import React, { useState } from 'react';
import classnames from 'classnames';
import { ProposalModels } from 'Models';
import { DateHelper } from 'Common/Helpers';

import { Theme, Paper, Grid, Tab, Tabs, AppBar, Divider } from '@material-ui/core';
import { TabPanel, DisplayField } from 'Common/Elements';
import { makeStyles } from '@material-ui/styles';
import InfoIcon from '@material-ui/icons/Info';
import OrganizationIcon from '@material-ui/icons/AccountTree';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import RelatedIcon from '@material-ui/icons/Autorenew';

import { Colors } from 'Common/Styles';
import {
	InvolvedPartiesCard,
	AwardedCompletedProposalCard,
	RejectedProposalCard,
	WithdrawnProposalCard,
	FacilitatedProposalCard,
	LinkedProposalsCard,
} from '.';
import { AwardStatuses } from 'Models/AwardStatusModels';

export interface IProposalDetailsCardProps {
	proposal: ProposalModels.ProposalDetails;
}

export type Props = IProposalDetailsCardProps;

const useStyles = makeStyles((theme: Theme) => ({
	gridContainer: {
		marginBottom: theme.spacing(4),
		paddingRight: `${theme.spacing(2)}px`,
	},
	detailsContainer: {
		margin: 0,
		padding: `${theme.spacing(2)}px 0 0`,
		borderTopWidth: 4,
		borderTopStyle: 'solid',
	},
	showMoreContainer: {
		textAlign: 'center',
	},
	showMoreText: {
		cursor: 'pointer',
		display: 'inline-block',
		fontSize: '12px',
		opacity: 0.4,
		textAlign: 'center',
		textTransform: 'uppercase',
		'&:hover': {
			opacity: 1,
		},
	},
	visibilityIcon: {
		display: 'block',
		margin: 'auto',
	},
}));

enum TabsOptions {
	General = 0,
	Organizations = 1,
	Status = 2,
	Related = 3,
}

export const ProposalDetailsCardDisplay = (props: Props) => {
	const colors = Colors.useColors();
	const classes = useStyles();
	const [selectedTab, setSelectedTab] = useState<TabsOptions>(TabsOptions.General);
	const { proposal } = props;

	return (
		<Grid container={true} className={classes.gridContainer}>
			<Grid item={true} md={12}>
				<Paper
					className={classnames(
						classes.detailsContainer,
						{
							[colors.defaultTopBorder]: proposal.awardStatusName === 'Submitted',
						},
						{
							[colors.warningTopBorder]:
								proposal.awardStatusName === 'Pending' ||
								proposal.awardStatusName === 'Contract Pending',
						},
						{
							[colors.primaryTopBorder]:
								proposal.awardStatusName === 'Awarded' || proposal.awardStatusName === 'Completed',
						},
						{
							[colors.secondaryTopBorder]: proposal.awardStatusName === 'Facilitated',
						},
						{
							[colors.errorTopBorder]:
								proposal.awardStatusName === 'Rejected' || proposal.awardStatusName === 'Withdrawn',
						}
					)}
				>
					<Grid container={true}>
						<Grid item={true} xs={10} md={11}>
							<DisplayField label="Long Name" value={proposal.longName} />
						</Grid>
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="Proposal Number" value={proposal.proposalNumber} />
						</Grid>
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="M Number" value={proposal.mNumber} />
						</Grid>
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="Client Contract Number" value={proposal.clientContractNumber} />
						</Grid>
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="Starts On" value={DateHelper.getShortDateString(proposal.startsOn)} />
						</Grid>
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="Ends On" value={DateHelper.getShortDateString(proposal.endsOn)} />
						</Grid>
						<Grid item={true} xs={6} md={4} />
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="Institute/Dept" value={proposal.instituteName} />
						</Grid>
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="Lead Agency" value={proposal.leadAgencyName} />
						</Grid>
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="Funding Source" value={proposal.fundingSourceName} />
						</Grid>
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="Grant Program" value={proposal.grantProgramName} />
						</Grid>
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="Funding Type" value={proposal.fundingTypeName} />
						</Grid>
						<Grid item={true} xs={6} md={4}>
							<DisplayField label="Award Status" value={proposal.awardStatusName} />
						</Grid>
					</Grid>

					<Divider style={{ marginTop: 10 }} />
					<Grid container={true}>
						<Grid item={true} xs={3} lg={3}>
							<AppBar position="static" color="default" elevation={0} style={{ height: '100%' }}>
								<Tabs
									value={selectedTab}
									orientation="vertical"
									onChange={(event: React.ChangeEvent<Record<string, unknown>>, newValue: number) =>
										setSelectedTab(newValue)
									}
									aria-label="scrollable auto tabs example"
								>
									<Tab icon={<InfoIcon />} label="General Info" wrapped={false} />
									<Tab icon={<OrganizationIcon />} label="Organizations" />
									<Tab icon={<TrophyIcon />} label="Status" />
									<Tab icon={<RelatedIcon />} label="Related Proposals" />
								</Tabs>
							</AppBar>
						</Grid>
						<Grid item={true} xs={9} lg={9}>
							<TabPanel selectedTab={selectedTab} index={TabsOptions.General}>
								<Grid container={true}>
									<Grid item={true} xs={12}>
										<DisplayField label="Abstract" value={proposal.abstract} />
									</Grid>
									<Grid item={true} xs={6} md={4}>
										<DisplayField
											label="Submitted On"
											value={DateHelper.getShortDateString(proposal.submittedOn)}
										/>
									</Grid>
									<Grid item={true} xs={6} md={4}>
										<DisplayField
											label="Created On"
											value={DateHelper.getShortDateString(proposal.createdOn)}
										/>
									</Grid>
									<Grid item={true} xs={6} md={4}>
										<DisplayField label="Submitted By" value={proposal.createdByName} />
									</Grid>
								</Grid>
							</TabPanel>
							<TabPanel selectedTab={selectedTab} index={TabsOptions.Organizations}>
								<InvolvedPartiesCard
									editable={false}
									involvedParties={proposal.involvedParties}
									proposalId={proposal.id}
								/>
							</TabPanel>
							<TabPanel selectedTab={selectedTab} index={TabsOptions.Status}>
								{proposal.awardStatusId === AwardStatuses.Awarded && (
									<AwardedCompletedProposalCard
										awardedProposal={proposal.awardedProposal}
										editable={false}
									/>
								)}
								{proposal.awardStatusId === AwardStatuses.Completed && (
									<AwardedCompletedProposalCard
										awardedProposal={proposal.awardedProposal}
										editable={false}
									/>
								)}
								{proposal.awardStatusId === AwardStatuses.Facilitated && (
									<FacilitatedProposalCard
										facilitatedProposal={proposal.facilitatedProposal}
										editable={false}
									/>
								)}
								{proposal.awardStatusId === AwardStatuses.Rejected && (
									<RejectedProposalCard
										rejectedProposal={proposal.rejectedProposal}
										editable={false}
									/>
								)}
								{proposal.awardStatusId === AwardStatuses.Withdrawn && (
									<WithdrawnProposalCard
										withdrawnProposal={proposal.withdrawnProposal}
										editable={false}
									/>
								)}
							</TabPanel>
							<TabPanel selectedTab={selectedTab} index={TabsOptions.Related}>
								<LinkedProposalsCard
									linkedProposals={proposal.linkedProposals}
									proposalId={proposal.id}
									editable={false}
								/>
							</TabPanel>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	);
};
