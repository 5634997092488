import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { loginRequest } from 'Common/Auth/config';
import { useAppDispatch, useAppState } from 'Context/AppProvider';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { useUsersEndpoint } from 'Endpoints';

export const SignInManager: React.FC = props => {
	const { instance, accounts } = useMsal();
	const state = useAppState();
	const dispatch = useAppDispatch();
	const userEp = useUsersEndpoint();

	const setBearerToken = (token: string) => {
		axios.defaults.headers.common.Authorization = `Bearer ${token}`;
	};

	// get a new token if axios gets a 401 response
	// then try the request again
	// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
	axios.interceptors.response.use(
		response => {
			return response;
		},
		async function (error) {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				await getToken();
				return axios(originalRequest);
			}
			return Promise.reject(error);
		}
	);

	const getToken = () => {
		return instance
			.acquireTokenSilent({
				...loginRequest,
				account: accounts[0],
			})
			.then(response => setBearerToken(response.accessToken));
	};

	// initially got an error about 'Interaction is currently in process' for loginRedirect
	// found a good solution at https://stackoverflow.com/a/66405987
	useEffect(() => {
		if (accounts.length > 0) {
			instance.setActiveAccount(accounts[0]);
		}

		const callbackId = instance.addEventCallback((event: EventMessage) => {
			// set active account after redirect
			if ([EventType.ACQUIRE_TOKEN_SUCCESS, EventType.LOGIN_SUCCESS].includes(event.eventType) && event.payload) {
				const account = (event.payload as AuthenticationResult).account;
				instance.setActiveAccount(account);
				getToken().then(() => account && dispatch({ type: 'APP/USER_LOGGED_IN', payload: account }));
			}
		});

		// handle auth redired/do all initial setup for msal
		instance
			.handleRedirectPromise()
			.then(authResult => {
				// Check if user signed in
				const account = instance.getActiveAccount();
				if (!account) {
					// redirect anonymous user to login page
					instance.loginRedirect();
				} else {
					getToken().then(() => dispatch({ type: 'APP/USER_LOGGED_IN', payload: account }));
				}
			})
			.catch(err => {
				// TODO: Handle errors
				console.error(err);
			});

		return () => {
			// This will be run on component unmount
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, []);

	useEffect(() => {
		if (state.LoginStatus === 'Logged In') {
			// put this here since it should only be hit once the user has signed in, has a token, and app dispatch has happened
			userEp.Add();
		}
	}, [state.LoginStatus]);

	if (state.LoginStatus === 'Logged Out') {
		instance.logoutRedirect({
			postLogoutRedirectUri: '/',
		});
	}

	if (state.LoginStatus === 'Awaiting Login') {
		return <div>Waiting to sign in...</div>;
	}

	return <React.Fragment>{props.children}</React.Fragment>;
};
