import { Agency } from 'Models/AgencyModels';
import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';

export const useAgenciesEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('agencies');

	const Get = () =>
		ep.Get<Agency[]>().catch(() => {
			useError('Error getting agencies');
			return [] as Agency[];
		});
	return { Get, IsLoading: ep.IsLoading };
};
