import React from 'react';
import { FormControl, Typography, makeStyles, Theme } from '@material-ui/core';

export interface IDisplayFieldProps {}

const useStyles = makeStyles((theme: Theme) => ({
	formControl: {
		marginLeft: '0',
		marginRight: '0',
		maxWidth: '100%',
		padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
		width: '100%',
	},
	propertyHeader: {
		color: theme.palette.text.secondary,
		display: 'block',
		fontSize: '14px',
		marginBottom: theme.spacing(1),
	},
	propertyValue: {
		fontSize: '16px',
		color: theme.palette.text.primary,
		display: 'block',
		marginBottom: theme.spacing(3),
	},
}));

export const DisplayField = (props: { label: string; value: string }) => {
	const classes = useStyles();

	return (
		<FormControl className={classes.formControl}>
			<Typography component="span" className={classes.propertyHeader}>
				{props.label}
			</Typography>
			<Typography component="span" className={classes.propertyValue}>
				{props.value}
			</Typography>
		</FormControl>
	);
};
