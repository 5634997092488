import { AddSupportAccount } from 'Models/SupportAccountModels';
import { SchemaOf, object } from 'yup';
import { stringSchema, numberSchema, selectSchema, emptyStringSchema } from './BaseSchemas';

export const AddSupportAccountSchema: SchemaOf<AddSupportAccount> = object({
	organizationId: selectSchema('Organization'),
	accountNumber: stringSchema('Account Number'),
	deptPI: stringSchema('Dept PI'),
	bookkeeper: emptyStringSchema('Bookkeeper'),
	amount: numberSchema('Amount'),
	matchAmount: numberSchema('Match Amount'),
	proposalId: numberSchema('Proposal Id'),
});

export const EditSupportAccountSchema = AddSupportAccountSchema;
