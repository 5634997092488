import { makeStyles } from '@material-ui/core';

export const formStyles = makeStyles(theme => ({
	flexForm: {
		display: 'flex',
		flexDirection: 'column',
		width: 'fit-content',
	},
	flexGrow: {
		flexGrow: 1,
	},
	wideControl: {
		minWidth: 250,
	},
}));
