import { SchemaOf, object, number, mixed } from 'yup';
import { stringSchema, numberSchema, dateSchema, selectSchema } from './BaseSchemas';
import { AddSubcontractLine, ContractToValues, EditSubcontractLine } from 'Models/SubcontractLineModels';

export const AddSubcontractLineSchema: SchemaOf<AddSubcontractLine> = object({
	contractTo: mixed().oneOf([ContractToValues.Person, ContractToValues.Organization]),
	personId: number()
		.notRequired()
		.when('contractTo', {
			is: ContractToValues.Person,
			then: selectSchema('Person', 1),
		}),
	organizationId: number()
		.notRequired()
		.when('contractTo', {
			is: ContractToValues.Organization,
			then: selectSchema('Organization'),
		}),
	accountNumber: stringSchema('Account Number'),
	mNumber: stringSchema('M Number'),
	deptPI: stringSchema('Dept PI'),
	bookkeeper: stringSchema('Bookkeeper'),
	amount: numberSchema('Amount', 0),
	matchAmount: numberSchema('Match Amount', 0),
	startsOn: dateSchema('Starts On'),
	endsOn: dateSchema('Ends On'),
	proposalId: numberSchema('Proposal Id'),
});

export const EditSubcontractLineSchema: SchemaOf<EditSubcontractLine> = object({
	id: numberSchema('Id'),
	contractTo: mixed().oneOf([ContractToValues.Person, ContractToValues.Organization]),
	mNumber: stringSchema('M Number'),
	deptPI: stringSchema('Dept. PI'),
	bookkeeper: stringSchema('Bookkeeper'),
	amount: numberSchema('Amount', 1),
	matchAmount: numberSchema('Match Amount'),
	startsOn: dateSchema('Starts On'),
	endsOn: dateSchema('Ends On'),
	proposalId: numberSchema('Proposal Id'),
});
