/** Makes the values of the provided array's indexKey both the key and value of the resulting object
 * (e.g. [{institute: 'NRI'}, {institute: 'TWRI'}] becomes {NRI: 'NRI', TWRI: 'TWRI'})
 */
export function toObject<T>(array: T[] | Readonly<T[]>, indexKey: keyof T) {
	const normalizedObject: any = {};
	for (const each of array) {
		const p = each[indexKey];

		if (Array.isArray(p)) {
			for (const i of p) {
				normalizedObject[i] = i;
			}
		} else {
			normalizedObject[p] = p;
		}
	}
	return normalizedObject as { [key: string]: T };
}
