import React, { useState, useEffect } from 'react';
import { ProposalModels } from 'Models';

import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import AddPartyIcon from '@material-ui/icons/AddToPhotos';
import { Organization } from 'Models/OrganizationModels';
import { useInvolvedPartiesEndpoint, useOrganizationsEndpoint } from 'Endpoints';
import { OrganizationSelect } from 'Common/Forms/Fields';

// props to be provided by parent component
// this is the components external interface
export interface IInvolvedPartiesCardProps {
	editable: boolean;
	involvedParties: ProposalModels.InvolvedParty[];
	onDelete?: (involvedParty: ProposalModels.InvolvedParty) => void;
	onAdd?: (involvedParty: ProposalModels.InvolvedParty) => void;
	proposalId: number;
}

export const InvolvedPartiesCard = (props: IInvolvedPartiesCardProps) => {
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const orgsEp = useOrganizationsEndpoint();
	const partiesEp = useInvolvedPartiesEndpoint();

	useEffect(() => {
		orgsEp.Get().then(r => setOrganizations(r));
	}, []);

	const handleDelete = (proposalId: number, organizationId: number) => {
		partiesEp
			.Delete(proposalId, organizationId)
			.then(response => response && props.onDelete && props.onDelete(response));
	};

	const [show, setShow] = useState(false);

	const handleItemClick = (organizationId: number) => {
		partiesEp
			.Add(props.proposalId, organizationId)
			.then(response => response && props.onAdd && props.onAdd(response));
		setShow(false);
	};

	const showSelect = () => {
		setShow(true);
	};

	const existingPartyIds = props.involvedParties.map(x => x.organizationId);
	const filteredOrginizations = organizations
		.sort((a, b) => ('' + a.name).localeCompare(b.name))
		.filter(o => !existingPartyIds.includes(o.id));
	return (
		<React.Fragment>
			<List dense={true}>
				{props.involvedParties.map(i => {
					return (
						<ListItem key={i.organizationId}>
							<ListItemText primary={i.organizationName} />
							{props.editable && (
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="delete"
										onClick={() => handleDelete(i.proposalId, i.organizationId)}
									>
										<TrashIcon color="error" />
									</IconButton>
								</ListItemSecondaryAction>
							)}
						</ListItem>
					);
				})}
			</List>
			{props.editable && (
				<React.Fragment>
					<IconButton onClick={showSelect}>
						<AddPartyIcon color="primary" />
					</IconButton>
					{show ? (
						<OrganizationSelect
							organizations={filteredOrginizations}
							name="involvedParties"
							label="Organization"
							setField={false}
							multiple={false}
							onChange={v => handleItemClick(v)}
						/>
					) : (
						''
					)}
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
