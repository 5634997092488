import React, { useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { FormControl, Button, LinearProgress, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { useSupportAccountsEndpoint } from 'Endpoints';
import { SupportAccount, EditSupportAccount } from 'Models/SupportAccountModels';
import { EditSupportAccountSchema } from '../ValidationSchemas/SupportAccountSchema';
import { FormDebugging } from '../Fields';

// props to be provided by parent component
// this is the components external interface
export interface IEditSupportAccountFormProps {
	onSuccess: (acct: SupportAccount) => void;
	supportAccount?: EditSupportAccount;
}

export const EditSupportAccountForm = (props: IEditSupportAccountFormProps) => {
	const ep = useSupportAccountsEndpoint();
	const nameof = useNameof<EditSupportAccount>();
	const classes = formStyles();

	const [supportAccount, setSupportAccount] = useState(
		props.supportAccount ? props.supportAccount : new EditSupportAccount(0)
	);

	const handleSuccess = (acct: SupportAccount) => {
		props.onSuccess(acct);
		setSupportAccount(new EditSupportAccount(0));
	};

	return (
		<Formik
			initialValues={supportAccount}
			validationSchema={EditSupportAccountSchema}
			onSubmit={(values, { setSubmitting }) => {
				ep.Edit(values).then(response => response && handleSuccess(response));
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form className={classes.flexForm}>
					<Grid container={true} direction="row">
						<FormControl>
							<Field
								component={TextField}
								label="Dept. PI"
								fullWidth={true}
								value={supportAccount.deptPI}
								name={nameof('deptPI')}
							/>
						</FormControl>
						<FormControl>
							<Field
								component={TextField}
								label="Bookkeeper"
								fullWidth={true}
								value={supportAccount.bookkeeper}
								name={nameof('bookkeeper')}
							/>
						</FormControl>
						<FormControl>
							<Field
								component={TextField}
								label="Amount"
								fullWidth={true}
								value={supportAccount.amount}
								name={nameof('amount')}
							/>
						</FormControl>
						<FormControl>
							<Field
								component={TextField}
								label="Match Amount"
								fullWidth={true}
								value={supportAccount.matchAmount}
								name={nameof('matchAmount')}
							/>
						</FormControl>
						<FormControl>
							{formprops.isSubmitting && <LinearProgress />}
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={formprops.isSubmitting}
								onSubmit={formprops.submitForm}
							>
								{formprops.isSubmitting ? 'Saving...' : 'Save'}
							</Button>
						</FormControl>
						<FormDebugging />
					</Grid>
				</Form>
			)}
		</Formik>
	);
};
