import React from 'react';
import { ProposalModels } from 'Models';
import { AwardStatuses } from 'Models/AwardStatusModels';

import { Chip, Tooltip, Avatar } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AssignmentIcon from '@material-ui/icons/Assignment';

export const FundedFields = (props: { proposal: ProposalModels.ProposalDetails }) => {
	if (
		(props.proposal.awardStatusId === AwardStatuses.Awarded ||
			props.proposal.awardStatusId === AwardStatuses.Completed) &&
		props.proposal.awardedProposal
	) {
		return (
			<React.Fragment>
				{props.proposal.awardedProposal.accountNumber && (
					<Chip
						size="medium"
						color="primary"
						label={props.proposal.awardedProposal.accountNumber}
						avatar={
							<Tooltip title="Account Number">
								<Avatar>$</Avatar>
							</Tooltip>
						}
						style={{ marginRight: 10 }}
					/>
				)}
				{props.proposal.awardedProposal.agencyNumber && (
					<Chip
						size="medium"
						color="default"
						label={props.proposal.awardedProposal.agencyNumber}
						avatar={
							<Tooltip title="Agency Number">
								<Avatar>
									<WorkIcon fontSize="small" />
								</Avatar>
							</Tooltip>
						}
						style={{ marginRight: 10 }}
					/>
				)}
				{props.proposal.mNumber && props.proposal.mNumber !== '' && (
					<Chip
						size="medium"
						color="secondary"
						label={props.proposal.mNumber}
						avatar={
							<Tooltip title="M Number">
								<Avatar>M</Avatar>
							</Tooltip>
						}
						style={{ marginRight: 10 }}
					/>
				)}
				{props.proposal.confluenceKey && props.proposal.confluenceKey !== '' && (
					<Chip
						size="medium"
						color="primary"
						label={
							<a
								href={`https://tamunri.atlassian.net/wiki/spaces/${props.proposal.confluenceKey}`}
								target="_blank"
							>
								Confluence
							</a>
						}
						avatar={
							<Tooltip title="Confluence Space">
								<Avatar>
									<AccountTreeIcon fontSize="small" />
								</Avatar>
							</Tooltip>
						}
						style={{ marginRight: 10 }}
					/>
				)}
				{props.proposal.jiraKey && props.proposal.jiraKey !== '' && (
					<Chip
						size="medium"
						color="secondary"
						label={
							<a href={`https://tamunri.atlassian.net/browse/${props.proposal.jiraKey}`} target="_blank">
								Jira
							</a>
						}
						avatar={
							<Tooltip title="Jira Project">
								<Avatar>
									<AssignmentIcon fontSize="small" />
								</Avatar>
							</Tooltip>
						}
						style={{ marginRight: 10 }}
					/>
				)}
			</React.Fragment>
		);
	} else {
		return null;
	}
};
