import React from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { FormControl, Button, LinearProgress, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { FormDebugging, GlobalDatePicker } from '../Fields';
import { useCostsEndpoint } from 'Endpoints';
import { Cost, AddCost } from 'Models/CostModels';
import { AddCostSchema } from '../ValidationSchemas/CostSchema';

export interface IAddCostFormProps {
	onSuccess: (cost: Cost) => void;
	proposalId: number;
}

export const AddCostForm = (props: IAddCostFormProps) => {
	const ep = useCostsEndpoint();
	const classes = formStyles();
	const nameof = useNameof<AddCost>();

	return (
		<Formik
			initialValues={new AddCost(props.proposalId)}
			enableReinitialize={true}
			validationSchema={AddCostSchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				ep.Add(values).then(r => r && props.onSuccess(r) && resetForm());
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form className={classes.flexForm} autoComplete="off">
					<Grid container={true}>
						<FormControl className={classes.flexGrow}>
							<Field
								component={TextField}
								label="Direct Costs"
								fullWidth={true}
								name={nameof('directCosts')}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								component={TextField}
								label="IDC Rate (e.g., 0-.99)"
								fullWidth={true}
								name={nameof('indirectCostRate')}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								component={TextField}
								label="Indirect Costs"
								fullWidth={true}
								name={nameof('indirectCostTotal')}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								component={TextField}
								label="Direct Match"
								fullWidth={true}
								name={nameof('directCostMatch')}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								component={TextField}
								label="IDC Match"
								fullWidth={true}
								name={nameof('indirectCostMatch')}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field
								component={TextField}
								label="Unrecovered IDC"
								fullWidth={true}
								name={nameof('unrecoveredIndirectCost')}
							/>
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field component={GlobalDatePicker} label="Starts On" name={nameof('startsOn')} />
						</FormControl>
						<FormControl className={classes.flexGrow}>
							<Field component={GlobalDatePicker} label="Ends On" name={nameof('endsOn')} />
						</FormControl>
						<Grid item={true} xs={12}>
							<FormControl>
								{formprops.isSubmitting && <LinearProgress />}
								<Button
									type="submit"
									color="primary"
									variant="contained"
									disabled={formprops.isSubmitting}
									onSubmit={formprops.submitForm}
								>
									{formprops.isSubmitting ? 'Saving...' : 'Add'}
								</Button>
							</FormControl>
						</Grid>
					</Grid>
					<FormDebugging />
				</Form>
			)}
		</Formik>
	);
};
