import settings from 'settings';
import { useAppState } from 'Context/AppProvider';

export const useAuthorization = () => {
	const { CurrentUser: user } = useAppState();

	// pass in user and admin object with groups and users arrays
	const isAdmin = () => {
		if (!user) {
			return false;
		}

		if (settings.authorization.adminUsers.includes(user.preferredUsername.toLowerCase())) return true;

		let isInAdminGroup = false;
		user.roles.forEach((group: string) => {
			if (settings.authorization.adminGroups.includes(group)) isInAdminGroup = true;
		});

		return isInAdminGroup;
	};

	// pass in user and supers object with groups and users arrays
	const isSuperUser = () => {
		if (!user) {
			return false;
		}

		if (settings.authorization.superUsers.includes(user.preferredUsername.toLowerCase())) return true;

		let isInSuperUserGroup = false;
		user.roles.forEach((group: string) => {
			if (settings.authorization.superUserGroups.includes(group)) isInSuperUserGroup = true;
		});

		return isInSuperUserGroup;
	};
	return { isAdmin, isSuperUser };
};
