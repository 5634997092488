import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { WorkItem } from 'Models/WorkItemModels';
import { calculateDateDifferencePercentage, getShortDate } from 'Common/Helpers/DateHelper';
import { subHours } from 'date-fns';

export interface IGanttCardProps {
	workItems: WorkItem[];
	proposalId: number;
}

export const options = {
	height: 400,
	gantt: {
		trackHeight: 50,
		criticalPathEnabled: false,
	},
};

// all of these fields are required for the gantt chart, but can be null
export const columns = [
	{ type: 'string', label: 'Task ID' },
	{ type: 'string', label: 'Task Name' },
	{ type: 'string', label: 'Resource' },
	{ type: 'date', label: 'Start Date' },
	{ type: 'date', label: 'End Date' },
	{ type: 'number', label: 'Duration' },
	{ type: 'number', label: 'Completion' },
	{ type: 'string', label: 'Dependencies' },
];

export const GanttCard = (props: IGanttCardProps) => {
	const [workItems, setWorkItems] = useState(props.workItems);
	useEffect(() => {
		setWorkItems(props.workItems);
	}, [props.workItems]);

	// convert work items to gantt chart array
	const rows: [string, string, Nullable<string>, Nullable<Date>, Date, Nullable<number>, number, Nullable<string>][] =
		[];

	workItems.forEach(w => {
		rows.push([
			w.id.toString(),
			w.title,
			null,
			w.startsOn === null ? subHours(w.endsOn, 23) : getShortDate(w.startsOn),
			getShortDate(w.endsOn),
			null,
			calculateDateDifferencePercentage(w.endsOn, w.startsOn === null ? subHours(w.endsOn, 23) : w.startsOn),
			props.workItems.find(wi => wi.id === w.parentId)?.id.toString() || null,
		]);
	});

	const data = [columns, ...rows];

	if (workItems.length == 0) {
		return <>No chart available. Add work items to view</>;
	} else {
		return <Chart chartType="Gantt" data={data} options={options} width="100%" height="50%" />;
	}
};

export default GanttCard;
