import React, { useEffect, useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { FormControl, Button, LinearProgress, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { FormDebugging, GlobalDatePicker, PersonSelect, TeamSelect } from '../Fields';
import { usePeopleEndpoint, useWorkItemsEndpoint } from 'Endpoints';
import { EditWorkItemSchema } from '../ValidationSchemas/WorkItemSchema';
import { WorkItem, EditWorkItem } from 'Models/WorkItemModels';
import { WorkItemParentSelect } from '../Fields/WorkItemParentSelect';
import { Person } from 'Models/PersonModels';
import { Team } from 'Models/TeamModels';

// props to be provided by parent component
// this is the components external interface
export interface IEditWorkItemFormProps {
	onSuccess: (workItem: WorkItem) => void;
	workItem?: EditWorkItem;
	teams: Team[];
	workItems: WorkItem[];
}

export const EditWorkItemForm = (props: IEditWorkItemFormProps) => {
	const ep = useWorkItemsEndpoint();
	const peopleEp = usePeopleEndpoint();
	const classes = formStyles();
	const nameof = useNameof<EditWorkItem>();

	const [people, setPeople] = useState<Person[]>([]);

	useEffect(() => {
		peopleEp.Get().then(r => setPeople(r));
	}, []);

	if (props.workItem === undefined) {
		return null;
	}

	return (
		<Formik
			initialValues={props.workItem}
			validationSchema={EditWorkItemSchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				ep.Edit(values).then(response => response && props.onSuccess(response));
				resetForm();
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form className={classes.flexForm} autoComplete="off">
					<Grid container={true}>
						<Grid item={true} md={6}>
							<FormControl className={classes.flexGrow}>
								<Field
									component={TextField}
									label="Title/Description"
									fullWidth={true}
									name={nameof('title')}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl className={classes.flexGrow}>
								<Field
									component={GlobalDatePicker}
									name={nameof('startsOn')}
									fullWidth={true}
									label="Starts On"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl className={classes.flexGrow}>
								<Field
									component={GlobalDatePicker}
									name={nameof('endsOn')}
									fullWidth={true}
									label="Ends On"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl className={classes.flexGrow}>
								<PersonSelect
									people={people}
									name={nameof('personId')}
									multiple={false}
									label="Assign Person"
									value={
										props.workItem ? people.find(p => p.id === props.workItem?.personId) : undefined
									}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl className={classes.flexGrow}>
								<TeamSelect
									teams={props.teams != null ? props.teams : []}
									name={nameof('teamId')}
									value={
										props.workItem?.teamId != null
											? props.teams.find(t => t.id == props.workItem?.teamId)
											: undefined
									}
									label="Assigned Team"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl className={classes.flexGrow}>
								<WorkItemParentSelect
									workItems={props.workItems != null ? props.workItems : []}
									name={nameof('parentId')}
									value={
										props.workItem?.parentId === null
											? undefined
											: (props.workItems.find(w => w.id === props.workItem?.parentId) as WorkItem)
									}
									label="Parent Work Item"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={12}>
							<FormControl className={classes.flexGrow}>
								{formprops.isSubmitting && <LinearProgress />}
								<Button
									type="submit"
									color="primary"
									variant="contained"
									disabled={formprops.isSubmitting}
									onSubmit={formprops.submitForm}
								>
									{formprops.isSubmitting ? 'Saving...' : 'Save'}
								</Button>
							</FormControl>
						</Grid>
					</Grid>
					<FormDebugging />
				</Form>
			)}
		</Formik>
	);
};
