import React, { useState, useEffect } from 'react';
import { Grid, FormControl, makeStyles, Theme, TextField } from '@material-ui/core';
import { ProposalModels } from 'Models';
import { DisplayField } from 'Common/Elements';
import { DateHelper } from 'Common/Helpers';
import deepEqual from 'deep-equal';
import { FormikErrors } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';

// props to be provided by parent component
// this is the components external interface
export interface IRejectedProposalCardProps {
	rejectedProposal?: ProposalModels.RejectedProposal;
	editable: boolean;
	onChange?: (rejectedProposal: ProposalModels.RejectedProposal) => void;
	errors?: FormikErrors<ProposalModels.RejectedProposal>;
}

const useStyles = makeStyles((theme: Theme) => ({
	formControl: {
		marginLeft: '0',
		marginRight: '0',
		maxWidth: '100%',
		padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
		width: '100%',
	},
	formField: {
		marginLeft: '0',
		marginRight: '0',
	},
}));

export const RejectedProposalCard = (props: IRejectedProposalCardProps) => {
	const classes = useStyles();

	const origRejectedProposal = props.rejectedProposal || new ProposalModels.RejectedProposal();
	const [rejectedProposal, setRejectedProposal] = useState(origRejectedProposal);

	const somethingChanged = () => {
		const areEqual = deepEqual(origRejectedProposal, rejectedProposal);
		return !areEqual;
	};

	useEffect(() => {
		if (somethingChanged()) {
			props.onChange && props.onChange(rejectedProposal);
		}
	}, [rejectedProposal]);

	useEffect(() => {
		if (somethingChanged()) {
			setRejectedProposal(origRejectedProposal);
		}
	}, [props.rejectedProposal]);

	return (
		<Grid container={true}>
			<Grid item={true} xs={6}>
				{props.editable ? (
					<React.Fragment>
						<FormControl className={classes.formControl}>
							<KeyboardDatePicker
								showTodayButton={true}
								id="date-picker-awarded-on"
								label="Rejected On"
								format="MM/dd/yyyy"
								value={rejectedProposal.rejectedOn}
								onChange={(date: Date) =>
									setRejectedProposal({ ...rejectedProposal, rejectedOn: date })
								}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								error={props.errors && !!props.errors.rejectedOn}
								helperText={props.errors && props.errors.rejectedOn}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Note"
								fullWidth={true}
								value={rejectedProposal.note || ''}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									setRejectedProposal({ ...rejectedProposal, note: event.target.value })
								}
								margin="normal"
								className={classes.formField}
								error={props.errors && !!props.errors.note}
								helperText={props.errors && props.errors.note}
							/>
						</FormControl>
					</React.Fragment>
				) : (
					<React.Fragment>
						<DisplayField
							label="Rejected On"
							value={DateHelper.getShortDateString(rejectedProposal.rejectedOn)}
						/>
						<DisplayField label="Note" value={rejectedProposal.note || ''} />
					</React.Fragment>
				)}
			</Grid>
		</Grid>
	);
};
