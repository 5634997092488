import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
	ProposalModels,
	CostModels,
	SupportAccountModels,
	SubcontractLineModels,
	NoteModels,
	FileModels,
	RoleModels,
} from 'Models';
import { DOMHelper } from 'Common/Helpers';

import { SupportAccountTable, SubcontractLineTable } from 'Common/Tables';
import {
	Grid,
	Paper,
	Tabs,
	Tab,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	LinearProgress,
	DialogActions,
	Button,
} from '@material-ui/core';
import { TabPanel, FundedFields } from 'Common/Elements';
import {
	ProposalDetailsCardDisplay,
	ProposalDetailsCardEditable,
	NotesCard,
	CostsCard,
	FilesCard,
	PeopleCard,
	TeamsCard,
} from 'Common/Cards';
import { AddSupportAccountForm, AddSubcontractLineForm, AddWorkItemForm } from 'Common/Forms/Add';
import { EditSubcontractLineForm, EditSupportAccountForm, EditCostForm, EditWorkItemForm } from 'Common/Forms/Edit';
import { ProtectedComponent } from 'Common/Utilities';
import { ModifyRoleAssignment } from 'Models/RoleModels';
import {
	useProposalsEndpoint,
	useSupportAccountsEndpoint,
	useSubcontractLinesEndpoint,
	useRoleAssignmentsEndpoint,
	useCostsEndpoint,
	useTeamsEndpoint,
	useWorkItemsEndpoint,
} from 'Endpoints';
import { Team } from 'Models/TeamModels';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import InfoIcon from '@material-ui/icons/Info';
import PlanningIcon from '@material-ui/icons/BarChart';
import GanttCard from 'Common/Cards/GanttCard';
import { EditWorkItem, WorkItem } from 'Models/WorkItemModels';
import { WorkItemTable } from 'Common/Tables/WorkItemTable';

enum MainTabsOptions {
	Overview = 0,
	Funding = 1,
	Planning = 2,
}
enum TabsOptions {
	People = 0,
	Notes = 1,
	Files = 2,
}

enum ModalOptions {
	None = 0,
	AddSupport = 1,
	AddSub = 2,
	EditSupport = 3,
	EditSub = 4,
	DeleteSupport = 5,
	DeleteSub = 6,
	DeleteCost = 8,
	DeleteProposal = 9,
	EditCost = 10,
	DeleteRole = 11,
	DeleteTeam = 12,
	AddWorkItem = 13,
	EditWorkItem = 14,
	DeleteWorkItem = 15,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Details = (props: RouteComponentProps<any>) => {
	const [proposal, setProposal] = useState<ProposalModels.ProposalDetails>();
	const [selectedTab, setSelectedTab] = useState<TabsOptions>(TabsOptions.People);
	const [selectedMainTab, setSelectedMainTab] = useState<MainTabsOptions>(MainTabsOptions.Overview);
	const [openModal, setOpenModal] = useState<ModalOptions>(ModalOptions.None);

	const ep = useProposalsEndpoint();
	const supportAccountEp = useSupportAccountsEndpoint();
	const subcontractEp = useSubcontractLinesEndpoint();
	const costEp = useCostsEndpoint();
	const roleEp = useRoleAssignmentsEndpoint();
	const teamEp = useTeamsEndpoint();
	const workItemEp = useWorkItemsEndpoint();

	useEffect(() => {
		DOMHelper.setPageTitle('Proposal Details');
		ep.GetProposal(props.match.params.id).then(r => r && setProposal(r));
	}, [props.match.params.id]);

	useEffect(() => {
		if (proposal) {
			DOMHelper.setPageTitle(proposal.shortName);
			setProposal(proposal);
		}
	}, [proposal]);

	const handleEditProposalSuccess = (newProposal: ProposalModels.ProposalDetails) => {
		setProposal(newProposal);
	};

	//#region add child entities
	const handleAddCostSuccess = (cost: CostModels.Cost) => {
		// add the new item to tne UI
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				costs: [...proposal.costs, cost],
			});
		}
	};

	const handleAddSupportAccountSuccess = (newAcct: SupportAccountModels.SupportAccount) => {
		// close the delete modal and add the new item to tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				supportAccounts: [...proposal.supportAccounts, newAcct],
			});
		}
	};

	const handleAddSubcontractSuccess = (sub: SubcontractLineModels.SubcontractLine) => {
		// close the delete modal and add the new item to tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				subcontractLines: [...proposal.subcontractLines, sub],
			});
		}
	};

	const handleAddNoteSuccess = (n: NoteModels.Note) => {
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				notes: [...proposal.notes, n],
			});
		}
	};

	const handleAddWorkItemSuccess = (workItem: WorkItem) => {
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				workItems: [...proposal.workItems, workItem],
			});
		}
	};
	//#endregion

	//#region cost
	const [costToEdit, setCostToEdit] = useState<CostModels.EditCost>();
	useEffect(() => {
		if (costToEdit !== undefined) {
			setOpenModal(ModalOptions.EditCost);
		}
	}, [costToEdit]);

	const handleEditCostInit = (cost: CostModels.EditCost) => {
		setCostToEdit(cost);
	};
	const handleEditCostSuccess = (cost: CostModels.Cost) => {
		// close the delete modal and update the affected item in tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined && costToEdit !== undefined) {
			setProposal({
				...proposal,
				costs: [...proposal.costs.filter(c => c.id !== costToEdit.id), cost],
			});
			setCostToEdit(undefined);
		}
	};
	//#endregion

	//#region edit support account
	const [supportAccountToEdit, setSupportAccountToEdit] = useState<SupportAccountModels.EditSupportAccount>();
	useEffect(() => {
		if (supportAccountToEdit !== undefined) {
			setOpenModal(ModalOptions.EditSupport);
		}
	}, [supportAccountToEdit]);

	const handleEditSupportAccountInit = (account: SupportAccountModels.EditSupportAccount) => {
		setSupportAccountToEdit(account);
	};
	const handleEditSupportAccountSuccess = (acct: SupportAccountModels.SupportAccount) => {
		// close the delete modal and update the affected item in tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined && supportAccountToEdit !== undefined) {
			setProposal({
				...proposal,
				supportAccounts: [
					...proposal.supportAccounts.filter(
						s =>
							!(
								s.organizationId === supportAccountToEdit.organizationId &&
								s.accountNumber === supportAccountToEdit.accountNumber
							)
					),
					acct,
				],
			});
			setSupportAccountToEdit(undefined);
		}
	};
	//#endregion

	//#region edit subcontract line
	const [subcontractToEdit, setSubcontractToEdit] = useState<SubcontractLineModels.EditSubcontractLine>();
	useEffect(() => {
		if (subcontractToEdit !== undefined) {
			setOpenModal(ModalOptions.EditSub);
		}
	}, [subcontractToEdit]);

	const handleEditSubcontractInit = (subcontractLine: SubcontractLineModels.EditSubcontractLine) => {
		setSubcontractToEdit(subcontractLine);
	};
	const handleEditSubcontractSuccess = (sub: SubcontractLineModels.SubcontractLine) => {
		// close the delete modal and update the affected item in tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined && subcontractToEdit !== undefined) {
			setProposal({
				...proposal,
				subcontractLines: [...proposal.subcontractLines.filter(s => s.id !== subcontractToEdit.id), sub],
			});
			setSubcontractToEdit(undefined);
		}
	};
	//#endregion

	//#region delete support account
	const [supportAccountToDelete, setSupportAccountToDelete] = useState<SupportAccountModels.DeleteSupportAccount>();
	useEffect(() => {
		if (supportAccountToDelete !== undefined) {
			setOpenModal(ModalOptions.DeleteSupport);
		}
	}, [supportAccountToDelete]);

	const handleDeleteSupportInit = (organizationId: number, accountNumber: string) => {
		setSupportAccountToDelete(
			new SupportAccountModels.DeleteSupportAccount(props.match.params.id, organizationId, accountNumber)
		);
	};
	const handleDeleteSupportConfirm = () => {
		// delete support account and hide the modal when done
		if (supportAccountToDelete !== undefined) {
			supportAccountEp.Delete(supportAccountToDelete).then(success => success && handleDeleteSupportSuccess());
		}
	};
	const handleDeleteSupportSuccess = () => {
		// close the delete modal and remove the deleted item from tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined && supportAccountToDelete !== undefined) {
			setProposal({
				...proposal,
				supportAccounts: [
					...proposal.supportAccounts.filter(
						s =>
							!(
								s.organizationId === supportAccountToDelete.organizationId &&
								s.accountNumber === supportAccountToDelete.accountNumber
							)
					),
				],
			});
			setSupportAccountToDelete(undefined);
		}
	};
	const handleDeleteSupportCancel = () => {
		// need to reset to undefined or the useEffect will not fire if a row is clicked, cancelled, and clicked again
		setSupportAccountToDelete(undefined);
		setOpenModal(ModalOptions.None);
	};
	//#endregion

	//#region delete subcontract line
	const [subcontractToDelete, setSubcontractToDelete] = useState<number>();
	useEffect(() => {
		if (subcontractToDelete !== undefined) {
			setOpenModal(ModalOptions.DeleteSub);
		}
	}, [subcontractToDelete]);

	const handleDeleteSubcontractInit = (id: number) => {
		setSubcontractToDelete(id);
	};
	const handleDeleteSubcontractConfirm = () => {
		// delete subcontract line and hide the modal when done
		if (subcontractToDelete !== undefined) {
			subcontractEp.Delete(subcontractToDelete).then(success => success && handleDeleteSubcontractSuccess());
		}
	};
	const handleDeleteSubcontractSuccess = () => {
		// close the delete modal and remove the deleted item from tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined && subcontractToDelete !== undefined) {
			setProposal({
				...proposal,
				subcontractLines: [...proposal.subcontractLines.filter(s => s.id !== subcontractToDelete)],
			});
			setSubcontractToDelete(undefined);
		}
	};
	const handleDeleteSubcontractCancel = () => {
		setSubcontractToDelete(undefined);
		setOpenModal(ModalOptions.None);
	};
	//#endregion

	//#region File Edit/Delete/Upload

	const handleFileEditSuccess = (editedFile: FileModels.File) => {
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				files: [...proposal.files.filter(f => f.id !== editedFile.id), editedFile],
			});
		}
	};

	const handleFileDeleteSuccess = (fileId: number) => {
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				files: proposal.files.filter(f => f.id !== fileId),
			});
		}
	};

	const handleFileUploadSuccess = (newFiles: FileModels.File[]) => {
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				files: [...proposal.files, ...newFiles],
			});
		}
	};

	// #endregion

	//#region delete cost
	const [costToDelete, setCostToDelete] = useState<number>();
	useEffect(() => {
		if (costToDelete !== undefined) {
			setOpenModal(ModalOptions.DeleteCost);
		}
	}, [costToDelete]);

	const handleDeleteCostInit = (id: number) => {
		setCostToDelete(id);
	};
	const handleDeleteCostConfirm = () => {
		// delete cost and hide the modal when done
		if (costToDelete !== undefined) {
			costEp.Delete(costToDelete).then(success => success && handleDeleteCostSuccess());
		}
	};
	const handleDeleteCostSuccess = () => {
		// close the delete modal and remove the deleted item from tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined && costToDelete !== undefined) {
			setProposal({
				...proposal,
				costs: [...proposal.costs.filter(c => c.id !== costToDelete)],
			});
			setCostToDelete(undefined);
		}
	};
	const handleDeleteCostCancel = () => {
		setCostToDelete(undefined);
		setOpenModal(ModalOptions.None);
	};

	//#endregion

	//#region involved party add/delete
	const handleInvolvedPartyDelete = (involvedParty: ProposalModels.InvolvedParty) => {
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				involvedParties: proposal.involvedParties.filter(
					i =>
						!(
							i.proposalId === involvedParty.proposalId &&
							i.organizationId === involvedParty.organizationId
						)
				),
			});
		}
	};
	const handleInvolvedPartyAdd = (involvedParty: ProposalModels.InvolvedParty) => {
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				involvedParties: [...proposal.involvedParties, involvedParty],
			});
		}
	};
	//#endregion

	//#region linked proposals add/delete
	const handleLinkedProposalDelete = (linkedProposals: ProposalModels.LinkedProposals) => {
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				linkedProposals: proposal.linkedProposals.filter(
					l =>
						!(
							l.proposalId === linkedProposals.proposalId &&
							l.linkedProposalId === linkedProposals.linkedProposalId
						)
				),
			});
		}
	};
	const handleLinkedProposalAdd = (linkedProposal: ProposalModels.LinkedProposals) => {
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				linkedProposals: [...proposal.linkedProposals, linkedProposal],
			});
		}
	};
	//#endregion

	//#region role assignment add/delete

	const handleRoleAssignmentAdd = (roleAssignment: RoleModels.RoleAssignment) => {
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				roleAssignments: [...proposal.roleAssignments, roleAssignment],
			});
		}
	};

	const [roleToDelete, setRoleToDelete] = useState<RoleModels.ModifyRoleAssignment>();
	useEffect(() => {
		if (roleToDelete !== undefined) {
			setOpenModal(ModalOptions.DeleteRole);
		}
	}, [roleToDelete]);

	const handleDeleteRoleInit = (personId: number, roleId: number) => {
		if (!proposal) {
			return;
		}
		const a = new ModifyRoleAssignment(proposal.id);
		a.personId = personId;
		a.roleId = roleId;
		setRoleToDelete(a);
	};
	const handleDeleteRoleConfirm = () => {
		// delete role assignment and hide the modal when done
		if (roleToDelete !== undefined) {
			roleEp.Delete(roleToDelete).then(r => r && handleDeleteRoleSuccess());
		}
	};
	const handleDeleteRoleSuccess = () => {
		// close the delete modal and remove the deleted item from tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined && roleToDelete !== undefined) {
			setProposal({
				...proposal,
				roleAssignments: [
					...proposal.roleAssignments.filter(
						a => !(a.personId === roleToDelete.personId && a.roleId === roleToDelete.roleId)
					),
				],
			});
			setRoleToDelete(undefined);
		}
	};
	const handleDeleteRoleCancel = () => {
		setRoleToDelete(undefined);
		setOpenModal(ModalOptions.None);
	};
	//#endregion

	//#region work item edit/delete
	const [workItemToEdit, setWorkItemToEdit] = useState<EditWorkItem>();
	useEffect(() => {
		if (workItemToEdit !== undefined) {
			setOpenModal(ModalOptions.EditWorkItem);
		}
	}, [workItemToEdit]);

	const handleEditWorkItemInit = (workItem: EditWorkItem) => {
		setWorkItemToEdit(workItem);
	};
	const handleEditWorkItemSuccess = (workItem: WorkItem) => {
		// close the delete modal and update the affected item in tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined && workItemToEdit !== undefined) {
			setProposal({
				...proposal,
				workItems: [...proposal.workItems.filter(t => t.id !== workItemToEdit.id), workItem],
			});
			setWorkItemToEdit(undefined);
		}
	};

	const [workItemToDelete, setWorkItemToDelete] = useState<number>();
	useEffect(() => {
		if (workItemToDelete !== undefined) {
			setOpenModal(ModalOptions.DeleteWorkItem);
		}
	}, [workItemToDelete]);

	const handleDeleteWorkItemInit = (id: number) => {
		setWorkItemToDelete(id);
	};
	const handleDeleteWorkItemConfirm = () => {
		// delete work item and hide the modal when done
		if (workItemToDelete !== undefined) {
			workItemEp.Delete(workItemToDelete).then(success => success && handleDeleteWorkItemSuccess());
		}
	};
	const handleDeleteWorkItemSuccess = () => {
		// close the delete modal and remove the deleted item from tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined && workItemToDelete !== undefined) {
			setProposal({
				...proposal,
				workItems: [...proposal.workItems.filter(t => t.id !== workItemToDelete)],
			});
			setWorkItemToDelete(undefined);
		}
	};
	const handleDeleteWorkItemCancel = () => {
		setWorkItemToDelete(undefined);
		setOpenModal(ModalOptions.None);
	};
	//#endregion

	//#region team assignment add/delete

	const handleTeamAssignmentAdd = (team: Team) => {
		if (proposal !== undefined) {
			setProposal({
				...proposal,
				teams: [...proposal.teams, team],
			});
		}
	};

	const [teamToDelete, setTeamToDelete] = useState<number>();
	useEffect(() => {
		if (teamToDelete !== undefined) {
			setOpenModal(ModalOptions.DeleteTeam);
		} else {
			setOpenModal(ModalOptions.None);
		}
	}, [teamToDelete]);

	const handleDeleteTeamInit = (teamId: number) => {
		if (!proposal) {
			return;
		}
		setTeamToDelete(teamId);
	};
	const handleDeleteTeamConfirm = () => {
		// delete team assignment and hide the modal when done
		if (teamToDelete !== undefined && proposal) {
			teamEp.RemoveFromProposal(teamToDelete, proposal.id).then(r => r && handleDeleteTeamSuccess());
		}
	};
	const handleDeleteTeamSuccess = () => {
		// close the delete modal and remove the deleted item from tne UI
		setOpenModal(ModalOptions.None);
		if (proposal !== undefined && teamToDelete !== undefined) {
			setProposal({
				...proposal,
				teams: [...proposal.teams.filter(a => a.id !== teamToDelete)],
			});
			setRoleToDelete(undefined);
		}
	};
	const handleDeleteTeamCancel = () => {
		setTeamToDelete(undefined);
	};
	//#endregion

	//#region delete proposal

	const handleDeleteProposalInit = () => {
		setOpenModal(ModalOptions.DeleteProposal);
	};
	const handleDeleteProposalConfirm = () => {
		if (!proposal) {
			return;
		}
		ep.Delete(proposal.id).then(success => success && handleDeleteProposalSuccess());
	};
	const handleDeleteProposalSuccess = () => {
		// close the delete modal and redirect back to the home page
		setOpenModal(ModalOptions.None);
		props.history.push('/');
	};
	const handleDeleteProposalCancel = () => {
		setOpenModal(ModalOptions.None);
	};
	//#endregion

	if (proposal === undefined || ep.IsLoading) {
		return <LinearProgress color="secondary" />;
	}

	return (
		<React.Fragment>
			<h2>
				{proposal.shortName} <FundedFields proposal={proposal} />
			</h2>
			<Paper>
				<Tabs
					value={selectedMainTab}
					onChange={(event: React.ChangeEvent<Record<string, unknown>>, newValue: number) =>
						setSelectedMainTab(newValue)
					}
					aria-label="scrollable auto tabs example"
					variant="scrollable"
				>
					<Tab icon={<InfoIcon />} label="Overview" />
					<Tab icon={<MoneyIcon />} label="Funding" />
					<Tab icon={<PlanningIcon />} label="Planning" />
				</Tabs>
			</Paper>

			<TabPanel selectedTab={selectedMainTab} index={MainTabsOptions.Overview}>
				<Grid container={true}>
					<Grid item={true} md={8}>
						<ProtectedComponent restrictedRoles={['admin']}>
							<ProposalDetailsCardDisplay proposal={proposal} />
						</ProtectedComponent>
						<ProtectedComponent allowedRoles={['admin']}>
							<ProposalDetailsCardEditable
								onDeleteClicked={handleDeleteProposalInit}
								proposal={proposal}
								onEditSuccess={handleEditProposalSuccess}
								onInvolvedPartyDeleted={handleInvolvedPartyDelete}
								onInvolvedPartyAdded={handleInvolvedPartyAdd}
								onRelatedProposalAdded={handleLinkedProposalAdd}
								onRelatedProposalDeleted={handleLinkedProposalDelete}
							/>
						</ProtectedComponent>
					</Grid>
					<Grid item={true} md={4}>
						<Tabs
							value={selectedTab}
							onChange={(event: React.ChangeEvent<Record<string, unknown>>, newValue: number) =>
								setSelectedTab(newValue)
							}
							aria-label="scrollable auto tabs example"
							variant="scrollable"
						>
							<Tab label="People" />
							<Tab label={`Notes (${proposal.notes.length})`} />
							<Tab label={`Files (${proposal.files.length})`} />
						</Tabs>
						<Paper>
							<TabPanel selectedTab={selectedTab} index={TabsOptions.People}>
								<ProtectedComponent restrictedRoles={['admin']}>
									<PeopleCard
										proposalId={proposal.id}
										roleAssignments={proposal.roleAssignments}
										editable={false}
									/>
									<TeamsCard proposalId={proposal.id} teams={proposal.teams} editable={false} />
								</ProtectedComponent>
								<ProtectedComponent allowedRoles={['admin']}>
									<PeopleCard
										proposalId={proposal.id}
										roleAssignments={proposal.roleAssignments}
										editable={true}
										onAddSuccess={newAssignment => handleRoleAssignmentAdd(newAssignment)}
										onDeleteClick={handleDeleteRoleInit}
									/>
								</ProtectedComponent>
								<ProtectedComponent restrictedRoles={['admin', 'superUser']}>
									<TeamsCard
										proposalId={proposal.id}
										teams={proposal.teams}
										editable={true}
										onAddSuccess={team => handleTeamAssignmentAdd(team)}
										onDeleteClick={handleDeleteTeamInit}
									/>
								</ProtectedComponent>
							</TabPanel>
							<TabPanel selectedTab={selectedTab} index={TabsOptions.Notes}>
								<NotesCard
									onSuccess={newNote => handleAddNoteSuccess(newNote)}
									notes={proposal.notes}
									proposalId={proposal.id}
								/>
							</TabPanel>
							<TabPanel selectedTab={selectedTab} index={TabsOptions.Files}>
								<FilesCard
									files={proposal.files}
									proposalId={proposal.id}
									onFileDeleteSuccess={handleFileDeleteSuccess}
									onFileEditSuccess={handleFileEditSuccess}
									onFileUploadSuccess={handleFileUploadSuccess}
									allowedUsers={proposal.roleAssignments.map(r => r.username)}
								/>
							</TabPanel>
						</Paper>
					</Grid>
				</Grid>
			</TabPanel>
			<TabPanel selectedTab={selectedMainTab} index={MainTabsOptions.Funding}>
				<Grid container={true} item={true} spacing={3}>
					<Grid item={true} md={4}>
						<CostsCard
							costs={proposal.costs}
							editable={true}
							onDeleteClicked={(id: number) => handleDeleteCostInit(id)}
							onEditClicked={(c: CostModels.EditCost) => handleEditCostInit(c)}
							onSuccess={cost => handleAddCostSuccess(cost)}
							proposalId={proposal.id}
						/>
					</Grid>
					<Grid item={true} md={8}>
						<Grid container={true} item={true} spacing={3}>
							<Grid item={true} xs={12}>
								<SupportAccountTable
									supportAccounts={proposal.supportAccounts}
									isLoading={ep.IsLoading || supportAccountEp.IsLoading}
									onAddClicked={() => setOpenModal(ModalOptions.AddSupport)}
									onDeleteClicked={(organizationId: number, accountNumber: string) =>
										handleDeleteSupportInit(organizationId, accountNumber)
									}
									onEditClicked={(s: SupportAccountModels.EditSupportAccount) =>
										handleEditSupportAccountInit(s)
									}
									editable={true}
								/>
							</Grid>
							<Grid item={true} xs={12}>
								<SubcontractLineTable
									subcontractLines={proposal.subcontractLines}
									isLoading={ep.IsLoading || subcontractEp.IsLoading}
									onAddClicked={() => setOpenModal(ModalOptions.AddSub)}
									onDeleteClicked={(id: number) => handleDeleteSubcontractInit(id)}
									onEditClicked={(s: SubcontractLineModels.EditSubcontractLine) =>
										handleEditSubcontractInit(s)
									}
									editable={true}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</TabPanel>
			<TabPanel selectedTab={selectedMainTab} index={MainTabsOptions.Planning}>
				<Grid container={true} item={true} spacing={3}>
					<Grid item={true} md={12}>
						<GanttCard workItems={proposal.workItems} proposalId={proposal.id} />
					</Grid>
					<Grid item={true} md={12}>
						<WorkItemTable
							workItems={proposal.workItems}
							onAddClicked={() => setOpenModal(ModalOptions.AddWorkItem)}
							onDeleteClicked={(id: number) => handleDeleteWorkItemInit(id)}
							onEditClicked={(w: EditWorkItem) => handleEditWorkItemInit(w)}
							editable={true}
						/>
					</Grid>
				</Grid>
			</TabPanel>

			<Dialog
				maxWidth="xs"
				open={openModal === ModalOptions.AddSupport}
				onClose={() => setOpenModal(ModalOptions.None)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Add Support Account</DialogTitle>
				<DialogContent>
					<AddSupportAccountForm
						onSuccess={newAcct => handleAddSupportAccountSuccess(newAcct)}
						proposalId={proposal.id}
					/>
				</DialogContent>
			</Dialog>

			<Dialog
				maxWidth="xs"
				open={openModal === ModalOptions.AddSub}
				onClose={() => setOpenModal(ModalOptions.None)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Add Subcontract Line</DialogTitle>
				<DialogContent>
					<AddSubcontractLineForm
						onSuccess={(sub: SubcontractLineModels.SubcontractLine) => handleAddSubcontractSuccess(sub)}
						proposalId={proposal.id}
					/>
				</DialogContent>
			</Dialog>

			<Dialog
				maxWidth="xs"
				open={openModal === ModalOptions.AddWorkItem}
				onClose={() => setOpenModal(ModalOptions.None)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Add Work Item</DialogTitle>
				<DialogContent>
					<AddWorkItemForm
						onSuccess={handleAddWorkItemSuccess}
						teams={proposal.teams}
						workItems={proposal.workItems}
						proposalId={proposal.id}
					/>
				</DialogContent>
			</Dialog>

			<Dialog
				maxWidth="xs"
				open={openModal === ModalOptions.EditCost}
				onClose={() => setOpenModal(ModalOptions.None)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Edit Cost</DialogTitle>
				<DialogContent>
					<EditCostForm
						onSuccess={(cost: CostModels.Cost) => handleEditCostSuccess(cost)}
						cost={costToEdit}
					/>
				</DialogContent>
			</Dialog>

			<Dialog
				maxWidth="xs"
				open={openModal === ModalOptions.EditSupport}
				onClose={() => setOpenModal(ModalOptions.None)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Edit Support Account</DialogTitle>
				<DialogContent>
					<EditSupportAccountForm
						onSuccess={(acct: SupportAccountModels.SupportAccount) => handleEditSupportAccountSuccess(acct)}
						supportAccount={supportAccountToEdit}
					/>
				</DialogContent>
			</Dialog>

			<Dialog
				maxWidth="xs"
				open={openModal === ModalOptions.EditSub}
				onClose={() => setOpenModal(ModalOptions.None)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Edit Subcontract Line</DialogTitle>
				<DialogContent>
					<EditSubcontractLineForm
						onSuccess={(sub: SubcontractLineModels.SubcontractLine) => handleEditSubcontractSuccess(sub)}
						subcontractLine={subcontractToEdit}
					/>
				</DialogContent>
			</Dialog>

			<Dialog
				maxWidth="xs"
				open={openModal === ModalOptions.EditWorkItem}
				onClose={() => setOpenModal(ModalOptions.None)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Edit Work Item</DialogTitle>
				<DialogContent>
					<EditWorkItemForm
						onSuccess={(workItem: WorkItem) => handleEditWorkItemSuccess(workItem)}
						workItem={workItemToEdit}
						teams={proposal.teams}
						workItems={proposal.workItems}
					/>
				</DialogContent>
			</Dialog>

			<Dialog
				maxWidth="md"
				open={openModal === ModalOptions.DeleteSupport}
				onClose={handleDeleteSupportCancel}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Delete Support Account</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to delete this support account?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteSupportCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteSupportConfirm} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				maxWidth="md"
				open={openModal === ModalOptions.DeleteSub}
				onClose={handleDeleteSubcontractCancel}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Delete Subcontract Line</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to delete this subcontract line?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteSubcontractCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteSubcontractConfirm} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				maxWidth="md"
				open={openModal === ModalOptions.DeleteCost}
				onClose={handleDeleteCostCancel}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Delete Cost</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to delete this cost/budget record?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteCostCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteCostConfirm} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				maxWidth="md"
				open={openModal === ModalOptions.DeleteRole}
				onClose={handleDeleteRoleCancel}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Delete Role Assignment</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to delete this role assignment?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteRoleCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteRoleConfirm} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				maxWidth="md"
				open={openModal === ModalOptions.DeleteWorkItem}
				onClose={handleDeleteWorkItemCancel}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Delete Work Item</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to delete this Work Item record?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteWorkItemCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteWorkItemConfirm} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				maxWidth="md"
				open={openModal === ModalOptions.DeleteTeam}
				onClose={handleDeleteTeamCancel}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Delete Team Assignment</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to delete this team assignment?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteTeamCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteTeamConfirm} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				maxWidth="md"
				open={openModal === ModalOptions.DeleteProposal}
				onClose={() => handleDeleteProposalCancel()}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Delete Proposal</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this proposal? This is a destructive action that cannot be
						undone. Please note, proposals should only be deleted if they were created in error. Rejected or
						withdrawn proposals should be changed to the appropriate award status.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleDeleteProposalCancel()} color="primary">
						Cancel
					</Button>
					<Button onClick={() => handleDeleteProposalConfirm()} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};
