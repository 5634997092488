import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { MenuItem } from '@material-ui/core';
import { RoleModels } from 'Models';

export interface IRoleSelectProps {
	/** The array of people that will be shown as dropdown items */
	roles: RoleModels.Role[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
}

export const RoleSelect = (props: IRoleSelectProps) => {
	return (
		<Field
			component={TextField}
			name={props.name}
			select={true}
			label={props.label || 'Role'}
			fullWidth={true}
			className={props.className}
		>
			<MenuItem key={'Role-select-0'} value="0">
				- Select -
			</MenuItem>
			{props.roles
				.sort((a, b) => ('' + a.name).localeCompare(b.name))
				.map(p => {
					return (
						<MenuItem key={`Role-select-${p.id}`} value={p.id}>
							{p.name}
						</MenuItem>
					);
				})}
		</Field>
	);
};
