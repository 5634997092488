import React, { useEffect, useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { Formik, Form } from 'formik';
import { FormControl, Button, LinearProgress } from '@material-ui/core';
import { RoleSelect, PersonSelect, FormDebugging } from '../Fields';
import { formStyles } from '../styles';
import { usePeopleEndpoint, useRoleAssignmentsEndpoint, useRolesEndpoint } from 'Endpoints';
import { Role, ModifyRoleAssignment, RoleAssignment } from 'Models/RoleModels';
import { Person } from 'Models/PersonModels';
import { ModifyRoleAssignmentSchema } from '../ValidationSchemas/RoleSchema';

export interface IAddRoleAssignmentFormProps {
	onSuccess: (roleAssignment: RoleAssignment) => void;
	proposalId: number;
}

export const AddRoleAssignmentForm = (props: IAddRoleAssignmentFormProps) => {
	const ep = useRoleAssignmentsEndpoint();
	const roleEp = useRolesEndpoint();
	const peopleEp = usePeopleEndpoint();
	const [roles, setRoles] = useState<Role[]>([]);
	const [people, setPeople] = useState<Person[]>([]);

	useEffect(() => {
		roleEp.Get().then(r => setRoles(r));
		peopleEp.Get().then(r => setPeople(r));
	}, []);

	const classes = formStyles();
	const nameof = useNameof<ModifyRoleAssignment>();

	const [roleAssignment, setRoleAssignment] = useState(new ModifyRoleAssignment(props.proposalId));

	const handleSuccess = (newAsst: RoleAssignment) => {
		setRoleAssignment(new ModifyRoleAssignment(props.proposalId));
		props.onSuccess(newAsst);
	};

	return (
		<Formik
			initialValues={roleAssignment}
			validationSchema={ModifyRoleAssignmentSchema}
			onSubmit={(values, { setSubmitting }) => {
				// add the subcontract line and clear the form if that succeeds
				ep.Add(values)
					.then(response => response && handleSuccess(response))
					.finally(() => setSubmitting(false));
			}}
		>
			{formprops => (
				<Form className={classes.flexForm}>
					<FormControl fullWidth={true}>
						<PersonSelect people={people} name={nameof('personId')} multiple={false} />
					</FormControl>

					<FormControl fullWidth={true}>
						<RoleSelect roles={roles} name={nameof('roleId')} />
					</FormControl>

					<FormControl fullWidth={true}>
						{formprops.isSubmitting && <LinearProgress />}
						<Button
							type="submit"
							color="primary"
							variant="contained"
							fullWidth={true}
							disabled={formprops.isSubmitting}
						>
							{formprops.isSubmitting ? 'Saving...' : 'Add'}
						</Button>
					</FormControl>
					<FormDebugging />
				</Form>
			)}
		</Formik>
	);
};
