import React, { useState } from 'react';
import { CostModels } from 'Models';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { FormControl, Button, LinearProgress, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { FormDebugging, GlobalDatePicker } from '../Fields';
import { useCostsEndpoint } from 'Endpoints';
import { EditCostSchema } from '../ValidationSchemas/CostSchema';

// props to be provided by parent component
// this is the components external interface
export interface IEditCostFormProps {
	onSuccess: (cost: CostModels.Cost) => void;
	cost?: CostModels.EditCost;
}

export const EditCostForm = (props: IEditCostFormProps) => {
	const ep = useCostsEndpoint();
	const classes = formStyles();
	const nameof = useNameof<CostModels.EditCost>();

	const [cost, setCost] = useState(props.cost ? props.cost : new CostModels.EditCost(0));

	const handleSuccess = (c: CostModels.Cost) => {
		setCost(new CostModels.EditCost(0));
		props.onSuccess(c);
	};

	return (
		<Formik
			initialValues={cost}
			validationSchema={EditCostSchema}
			onSubmit={(values, { setSubmitting }) => {
				ep.Edit(values).then(response => response && handleSuccess(response));
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form className={classes.flexForm} autoComplete="off">
					<Grid container={true}>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									name={nameof('directCosts')}
									label="Direct Costs"
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									name={nameof('indirectCostRate')}
									label="IDC Rate (e.g., 0-.99)"
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									name={nameof('indirectCostTotal')}
									label="Indirect Costs"
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									name={nameof('directCostMatch')}
									label="Direct Match"
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									name={nameof('indirectCostMatch')}
									label="IDC Match"
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									name={nameof('unrecoveredIndirectCost')}
									label="Unrecovered IDC"
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field component={GlobalDatePicker} name={nameof('startsOn')} label="Starts On" />
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field component={GlobalDatePicker} name={nameof('endsOn')} label="Ends on" />
							</FormControl>
						</Grid>
						<Grid item={true} md={12}>
							<FormControl>
								{formprops.isSubmitting && <LinearProgress />}
								<Button
									type="submit"
									color="primary"
									variant="contained"
									disabled={formprops.isSubmitting}
									onSubmit={formprops.submitForm}
								>
									{formprops.isSubmitting ? 'Saving...' : 'Save'}
								</Button>
							</FormControl>
							<FormDebugging />
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};
