import React, { useEffect, useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid, FormControl, Button, LinearProgress } from '@material-ui/core';
import { FormDebugging, OrganizationSelect } from '../Fields';
import { formStyles } from '../styles';
import { useSupportAccountsEndpoint, useOrganizationsEndpoint } from 'Endpoints';
import { Organization } from 'Models/OrganizationModels';
import { AddSupportAccount, SupportAccount } from 'Models/SupportAccountModels';
import { AddSupportAccountSchema } from '../ValidationSchemas/SupportAccountSchema';

export interface IAddSupportAccountFormProps {
	onSuccess: (supportAccount: SupportAccount) => void;
	proposalId: number;
}

export const AddSupportAccountForm = (props: IAddSupportAccountFormProps) => {
	const ep = useSupportAccountsEndpoint();
	const orgEp = useOrganizationsEndpoint();
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	useEffect(() => {
		orgEp.Get().then(r => setOrganizations(r));
	}, []);

	const classes = formStyles();
	const nameof = useNameof<AddSupportAccount>();

	const [supportAccount, setSupportAccount] = useState(new AddSupportAccount(props.proposalId));

	const handleSuccess = (newAcct: SupportAccount) => {
		setSupportAccount(new AddSupportAccount(props.proposalId));
		props.onSuccess(newAcct);
	};

	return (
		<Formik
			initialValues={supportAccount}
			validationSchema={AddSupportAccountSchema}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				// add the subcontract line and clear the form if that succeeds
				ep.Add(values).then(response => response && handleSuccess(response));
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form className={classes.flexForm}>
					<Grid container={true}>
						<Grid item={true} md={6}>
							<FormControl fullWidth={true}>
								<OrganizationSelect
									organizations={organizations}
									name={nameof('organizationId')}
									multiple={false}
									setField={true}
									label="Organization"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									label="Account Number"
									fullWidth={true}
									name={nameof('accountNumber')}
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									label="Dept. PI"
									fullWidth={true}
									name={nameof('deptPI')}
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									label="Bookkeeper"
									fullWidth={true}
									name={nameof('bookkeeper')}
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									label="Amount"
									fullWidth={true}
									name={nameof('amount')}
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={6}>
							<FormControl>
								<Field
									label="Match Amount"
									fullWidth={true}
									name={nameof('matchAmount')}
									type="text"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={12}>
							<FormControl fullWidth={true}>
								{formprops.isSubmitting && <LinearProgress />}
								<Button
									type="submit"
									color="primary"
									variant="contained"
									fullWidth={true}
									disabled={formprops.isSubmitting}
									onSubmit={formprops.submitForm}
								>
									{formprops.isSubmitting ? 'Saving...' : 'Add'}
								</Button>
							</FormControl>
						</Grid>
					</Grid>
					<FormDebugging />
				</Form>
			)}
		</Formik>
	);
};
