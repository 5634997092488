import { AddCost, EditCost } from 'Models/CostModels';
import { SchemaOf, object, number } from 'yup';
import { numberSchema, percentSchema, dateSchema } from './BaseSchemas';

export const EditCostSchema: SchemaOf<EditCost> = object({
	id: numberSchema('Id'),
	directCosts: numberSchema('Direct Costs'),
	indirectCostRate: percentSchema('IDC Rate'),
	indirectCostTotal: numberSchema('Indirect Costs'),
	directCostMatch: numberSchema('Direct Match'),
	indirectCostMatch: numberSchema('IDC Match'),
	unrecoveredIndirectCost: numberSchema('Unrecovered IDC'),
	startsOn: dateSchema('Starts On'),
	endsOn: dateSchema('Ends On'),
	proposalId: number().required('ProposalId is required'),
});

export const AddCostSchema: SchemaOf<AddCost> = object({
	directCosts: numberSchema('Direct Costs'),
	indirectCostRate: percentSchema('IDC Rate'),
	indirectCostTotal: numberSchema('Indirect Costs'),
	directCostMatch: numberSchema('Direct Match'),
	indirectCostMatch: numberSchema('IDC Match'),
	unrecoveredIndirectCost: numberSchema('Unrecovered IDC'),
	startsOn: dateSchema('Starts on'),
	endsOn: dateSchema('Ends On'),
	proposalId: number().required('ProposalId is required'),
});
