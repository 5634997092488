import { AddTeamToProposal, Team } from 'Models/TeamModels';
import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';

export const useTeamsEndpoint = () => {
	const { useError, useSuccess } = useNotifications();
	const ep = Endpoint('Teams');

	const Get = () =>
		ep.Get<Team[]>().catch(() => {
			useError('Error getting teams');
			return [] as Team[];
		});

	const AddToProposal = (dto: AddTeamToProposal) =>
		ep
			.Post<Team>(dto, 'addtoProposal')
			.then(r => {
				useSuccess('Team assignment added');
				return r;
			})
			.catch(() => {
				useError('Error adding team assignment');
				return undefined;
			});

	const RemoveFromProposal = (teamId: number, proposalId: number) =>
		ep
			.Delete<number>(`${teamId}/proposal/${proposalId}`)
			.then(r => {
				useSuccess('Team assignment deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting team assignment');
				return undefined;
			});

	return { Get, AddToProposal, RemoveFromProposal, IsLoading: ep.IsLoading };
};
