import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { Proposal, ProposalDetails, EditProposal, AddProposal } from 'Models/ProposalModels';

export const useProposalsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('proposals');

	const GetProposals = () =>
		ep.Get<Proposal[]>().catch(() => {
			useError('Error getting proposals');
			return [] as Proposal[];
		});

	const GetProposal = (id: number) =>
		ep
			.Get<ProposalDetails>(id)
			// make dates actual dates. They actually come from the API as strings
			.then(r => ({
				...r,
				workItems: r.workItems.map(i => ({
					...i,
					startsOn: typeof i.startsOn === 'string' ? new Date(i.startsOn) : i.startsOn,
					endsOn: typeof i.endsOn === 'string' ? new Date(i.endsOn) : i.endsOn,
				})),
			}))
			.catch(() => {
				useError('Error getting proposal');
				return undefined;
			});

	const Add = (dto: AddProposal) =>
		ep
			.Post<number>(dto)
			.then(r => {
				useSuccess('Proposal added');
				return r;
			})
			.catch(() => {
				useError('Error adding proposal');
				return undefined;
			});

	const Edit = (dto: EditProposal) =>
		ep
			.Put<number>(dto.id, dto)
			.then(r => {
				useSuccess('Proposal saved');
				return r;
			})
			.catch(() => {
				useError('Error saving proposal');
				return undefined;
			});

	const Delete = (id: number) =>
		ep
			.Delete<number>(id)
			.then(r => {
				useSuccess('Proposal deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting proposal');
				return undefined;
			});

	return { GetProposals, GetProposal, Add, Edit, Delete, IsLoading: ep.IsLoading };
};
