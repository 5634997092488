import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';

import { Fade, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { TableIcons } from 'Common/Tables';
import MaterialTable from '@material-table/core';
import AddIcon from '@material-ui/icons/Add';
// import TrashIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import { AddGrantProgramForm } from 'Common/Forms/Add';
import { TableStyles, Colors } from 'Common/Styles';
import { EditGrantProgramForm } from 'Common/Forms/Edit';
import { GrantProgram, EditGrantProgram } from 'Models/GrantProgramModels';
import { useGrantProgramsEndpoint } from 'Endpoints';

enum ModalOptions {
	None = 0,
	Add = 1,
	Edit = 2,
}

export const GrantPrograms = () => {
	const [openModal, setOpenModal] = useState<ModalOptions>(ModalOptions.None);
	const colors = Colors.useColors();
	const ep = useGrantProgramsEndpoint();
	const [grantPrograms, setGrantPrograms] = useState<GrantProgram[]>([]);
	const [editGrantProgram, setEditGrantProgram] = useState<EditGrantProgram>(new EditGrantProgram(0));
	useEffect(() => {
		DOMHelper.setPageTitle('Grant Programs Admin');
		ep.Get(true).then(r => setGrantPrograms(r));
	}, []);

	const handleLock = (id: number, currentStatus?: boolean) => {
		currentStatus = currentStatus === undefined ? false : currentStatus;
		ep.EditActive(id, !currentStatus).then(() => ep.Get(true).then(r => setGrantPrograms(r)));
	};

	const handleEditSucess = () => {
		setOpenModal(ModalOptions.None);
		ep.Get(true).then(r => setGrantPrograms(r));
	};

	useEffect(() => {
		if (editGrantProgram.id !== 0) {
			setOpenModal(ModalOptions.Edit);
		}
	}, [editGrantProgram]);

	return (
		<Fade in={true}>
			<React.Fragment>
				<MaterialTable
					columns={[
						{ title: 'Acronym', field: 'shortName' },
						{ title: 'Name', field: 'name' },
					]}
					data={grantPrograms}
					title="Grant Programs"
					icons={TableIcons}
					options={{
						pageSize: 10,
						headerStyle: TableStyles.headerStyle,
						exportAllData: true,
						actionsColumnIndex: -1,
						rowStyle: (rowData: GrantProgram) => ({
							opacity: !rowData.isActive ? 0.4 : 1,
						}),
					}}
					isLoading={ep.IsLoading}
					actions={[
						{
							icon: () => <AddIcon className={colors.primary} />,
							tooltip: 'Add Grant Program',
							position: 'toolbar',
							onClick: event => setOpenModal(ModalOptions.Add),
						},
						{
							icon: () => <EditIcon className={colors.warning} />,
							tooltip: 'Edit',
							onClick: (event, rowData: GrantProgram) =>
								setEditGrantProgram(new EditGrantProgram(rowData.id, rowData.name, rowData.shortName)),
						},
						{
							icon: () => <LockIcon />,
							tooltip: 'Lock/Set Inactive',
							onClick: (event, rowData: GrantProgram) => handleLock(rowData.id, rowData.isActive),
						},
						// {
						// 	icon: () => <TrashIcon className={colors.error} />,
						// 	tooltip: 'Delete',
						// 	onClick: event => alert('delete clicked'),
						// },
					]}
				/>
				<Dialog
					maxWidth="md"
					open={openModal === ModalOptions.Add}
					onClose={() => setOpenModal(ModalOptions.None)}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Add Grant Program</DialogTitle>
					<DialogContent>
						<AddGrantProgramForm onSuccess={() => setOpenModal(ModalOptions.None)} />
					</DialogContent>
				</Dialog>

				<Dialog
					maxWidth="md"
					open={openModal === ModalOptions.Edit}
					onClose={() => setOpenModal(ModalOptions.None)}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Edit Grant Program</DialogTitle>
					<DialogContent>
						<EditGrantProgramForm grantProgram={editGrantProgram} onSuccess={handleEditSucess} />
					</DialogContent>
				</Dialog>
			</React.Fragment>
		</Fade>
	);
};
