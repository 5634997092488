/** for showing files on the proposal details page, and what's returned from post and put actions */
export class File {
	id = 0;
	title = '';
	url = '';
	description = '';
	fileCategoryName = '';
	fileCategoryId = 0;
	submittedAt: Date = new Date();

	public constructor(init?: Partial<File>) {
		Object.assign(this, init);
	}
}

export class AddFile {
	proposalId = 0;
	fileCategoryId = 0;
	username = '';
	url = '';
	title = '';
	contents?: Blob;

	public constructor(init?: Partial<AddFile>) {
		Object.assign(this, init);
	}

	toFormData() {
		const formData = new FormData();

		formData.append('url', this.url);
		formData.append('title', this.title);
		formData.append('proposalId', this.proposalId.toString());
		formData.append('fileCategoryId', this.fileCategoryId.toString());
		formData.append('username', this.username);
		this.contents !== undefined && formData.append('contents', this.contents, this.url);

		return formData;
	}
}

export class EditFile {
	id = 0;
	title = '';
	description = '';
	fileCategoryId = 0;

	public constructor(init?: Partial<EditFile>) {
		Object.assign(this, init);
	}
}

export class FileCategory {
	id = 0;
	name = '';
}

export type FilterKey = 'url' | 'fileCategoryName';

export class FileFilter {
	key: FilterKey = 'url';
	value = '';

	public constructor(init?: Partial<FileFilter>) {
		Object.assign(this, init);
	}
}
