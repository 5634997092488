import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, Grid, Typography, ClickAwayListener, InputBase, Paper, IconButton, Tooltip } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useSearchEndpoint, useFilesEndpoint } from 'Endpoints';
import { AppModels } from 'Models';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		maxWidth: 450,
		position: 'relative',
	},
	search: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.black, 0.05),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.black, 0.1),
		},
		marginLeft: 0,
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(9),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
		width: '100%',
	},
	inputInput: {
		paddingTop: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(10),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200,
		},
	},
	results: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(3),
		position: 'absolute',
	},
	result: {
		padding: 4,
	},
	resultType: {
		textAlign: 'right',
		marginRight: 16,
	},
	resultBody: {
		borderLeft: 'solid 1px #ccc',
		padding: '8px 0 8px 12px',
		'&:hover': {
			backgroundColor: fade(theme.palette.common.black, 0.1),
		},
	},
	seeAll: {
		borderTop: 'solid 1px #ccc',
		padding: '8px',
		'&:hover': {
			backgroundColor: fade(theme.palette.common.black, 0.1),
		},
	},
	seeAllLink: {
		textDecoration: 'none',
		display: 'block',
	},
}));

export const AppBarSearch = () => {
	/** The minimum number of characters that must be typed in for the search to hit the API */
	const termMinimum = 2;
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState('');
	const [showResults, setShowResults] = useState(false);
	const [results, setResults] = useState<AppModels.SearchResult[]>([]);
	const ep = useSearchEndpoint();
	const fileEp = useFilesEndpoint();

	const SearchResult = (prop: {
		searchResult: AppModels.SearchResult;
		onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
	}) => {
		return (
			<Grid container={true} className={classes.result}>
				<Grid item={true} lg={3}>
					<Typography variant="subtitle1" color="textSecondary" component="p" className={classes.resultType}>
						{prop.searchResult.type === 'File' ? (
							<Tooltip title="Download" placement="top">
								<IconButton
									edge="end"
									aria-label="download-file"
									onClick={() => fileEp.Download(prop.searchResult.fileId)}
									style={{ marginRight: 4 }}
								>
									<GetAppIcon />
								</IconButton>
							</Tooltip>
						) : (
							''
						)}
						{prop.searchResult.type}
					</Typography>
				</Grid>
				<Grid item={true} lg={9} className={classes.resultBody}>
					<Link to={'/proposals/' + prop.searchResult.proposalId} onClick={handleClickAway}>
						<Typography variant="h6" component="h4">
							{prop.searchResult.name}
						</Typography>
						<Typography component="p" variant="body2" color="textSecondary">
							{prop.searchResult.description && prop.searchResult.description.substring(0, 100)}
						</Typography>
					</Link>
				</Grid>
			</Grid>
		);
	};

	useEffect(() => {
		// use the event value since using the newly set state const ended up being one step/event behind
		if (searchTerm.length >= termMinimum) {
			// only do the search once the user's typed in at least two characters
			ep.GetSearch(searchTerm).then(r => setResults(r));
			setShowResults(true);
		} else {
			setShowResults(false);
		}
	}, [searchTerm]);

	const handleClickAway = () => {
		setShowResults(false);
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Grid container={true} className={classes.root}>
				<Grid item={true} xs={12} className={classes.search}>
					<div className={classes.searchIcon}>
						<SearchIcon />
					</div>
					<InputBase
						placeholder="Search…"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
						value={searchTerm}
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
					/>
				</Grid>
				<Grid item={true} xs={12}>
					<Paper className={classes.results}>
						{showResults && (
							<React.Fragment>
								{results.slice(0, 5).map(r => {
									// tslint:disable-next-line:prefer-template
									let key = r.type + '-' + r.proposalId;
									switch (r.type) {
										case 'File':
											key += '-' + r.fileId;
											break;
										case 'Note':
											key += '-' + r.noteId;
											break;
										case 'SubcontractLine':
											key += '-' + r.subcontractLineId;
											break;
									}

									return (
										<SearchResult
											searchResult={r}
											key={key}
											onClick={() => {
												setShowResults(false);
												setSearchTerm('');
											}}
										/>
									);
								})}
								<Typography
									component="p"
									variant="body2"
									color="textSecondary"
									className={classes.seeAll}
								>
									<Link
										to={'/search/' + searchTerm}
										className={classes.seeAllLink}
										onClick={() => {
											setShowResults(false);
											setSearchTerm('');
										}}
									>
										See all results
									</Link>
								</Typography>
							</React.Fragment>
						)}
					</Paper>
				</Grid>
			</Grid>
		</ClickAwayListener>
	);
};
