import React, { useEffect, useState, useRef } from 'react';

import { ProposalModels, UserModels } from 'Models';
import { DOMHelper, DateHelper, TextHelper, ArrayHelper, RoleHelper } from 'Common/Helpers';
import classnames from 'classnames';

import { Fade, makeStyles, Grid, Typography, Theme } from '@material-ui/core';
import { TableIcons, TableFilters, FilterIcon } from 'Common/Tables';
import { Link } from 'react-router-dom';
import MaterialTable, { Column } from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import { StyledCard } from 'Common/Cards';
import { TableStyles } from 'Common/Styles';
import { useAppState } from 'Context/AppProvider';
import { useProposalsEndpoint, useUsersEndpoint } from 'Endpoints';

// put in a separate object so they can be consumed in useStyles and MT rowStyle
const bgColors = {
	red: {
		backgroundColor: '#FED9DB',
	},
	orange: {
		backgroundColor: '#FFEDD6',
	},
	blue: {
		backgroundColor: '#D3F5FC',
	},
	green: {
		backgroundColor: '#E7F5D0',
	},
	purple: {
		backgroundColor: '#F6E3FC',
	},
};

const darkBgColors = {
	red: {
		backgroundColor: 'rgb(145, 54, 59)',
	},
	orange: {
		backgroundColor: 'rgb(193, 106, 63)',
	},
	blue: {
		backgroundColor: 'rgb(43, 122, 138)',
	},
	green: {
		backgroundColor: 'rgb(96, 130, 41)',
	},
	purple: {
		backgroundColor: 'rgb(162, 70, 191)',
	},
};

const useStyles = makeStyles((theme: Theme) => ({
	link: {
		color: '#23CCEF',
	},
	legend: {
		margin: 6,
	},
	...(theme.palette.type === 'light' ? bgColors : darkBgColors),
}));

export const Home = () => {
	const state = useAppState();
	const classes = useStyles();

	const [isAdvanced, setIsAdvanced] = useState(false);
	const [proposals, setProposals] = useState<ProposalModels.Proposal[]>([]);
	const [filteredProposals, setFilteredProposals] = useState<ProposalModels.Proposal[]>([]);
	const [summaryData, setSummaryData] = useState<ProposalModels.Proposal[]>([]);
	const [userPreferences, setUserPreferences] = useState(new UserModels.Preferences());

	const tableRef = useRef(null);
	const storedFilters = localStorage.getItem('savedFilters') || {};
	const storedValue = Object.keys(storedFilters).length > 0 ? JSON.parse(storedFilters.toString()) : {};
	const tableColumnEnum = UserModels.ProposalTableColumns;
	const tableColumnPreferences = userPreferences.proposalsTableColumns;

	const teamList = (teams: string[]) => {
		return [...new Set(teams)].sort((a, b) => a.localeCompare(b)).join(' | ');
	};

	const defaultColumns: Column<ProposalModels.Proposal>[] = [
		{
			title: 'Name',
			field: tableColumnEnum.ShortName,
			render: rowData => (
				<Link className={classes.link} to={'/proposals/' + rowData.id}>
					{rowData.shortName}
				</Link>
			),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.ShortName),
			defaultFilter: storedValue.shortName,
		},
		{
			title: 'Institute/Dept',
			field: tableColumnEnum.InstituteName,
			lookup: ArrayHelper.toObject<ProposalModels.Proposal>(proposals, tableColumnEnum.InstituteName),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.InstituteName),
			defaultFilter: storedValue.instituteName,
		},
		{
			title: 'Primary Contact',
			field: tableColumnEnum.PrimaryContact,
			hidden: !tableColumnPreferences.includes(tableColumnEnum.PrimaryContact),
			defaultFilter: storedValue.primaryContact,
		},
		{
			title: 'Principal Investigator',
			field: tableColumnEnum.PrincipalInvestigator,
			hidden: !tableColumnPreferences.includes(tableColumnEnum.PrincipalInvestigator),
			defaultFilter: storedValue.principalInvestigator,
		},
		{
			title: 'Co-PIs',
			field: tableColumnEnum.CoPIs,
			hidden: !tableColumnPreferences.includes(tableColumnEnum.CoPIs),
			defaultFilter: storedValue.coPIs,
		},
		{
			title: 'Business Contact',
			field: tableColumnEnum.BusinessContact,
			hidden: !tableColumnPreferences.includes(tableColumnEnum.BusinessContact),
			defaultFilter: storedValue.businessContact,
		},
		{
			title: 'Teams',
			field: tableColumnEnum.Teams,
			render: rowData => teamList(rowData.teams),
			lookup: ArrayHelper.toObject<ProposalModels.Proposal>(proposals, tableColumnEnum.Teams),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.Teams),
			defaultFilter: storedValue.teams,
			customFilterAndSearch: (term, rowData) => TableFilters.FilterText(term, rowData.teams.join(' | ')),
			customSort: (a, b) => teamList(a.teams).localeCompare(teamList(b.teams)),
			export: false,
		},
		{
			title: 'Award Status',
			field: tableColumnEnum.AwardStatusName,
			lookup: ArrayHelper.toObject<ProposalModels.Proposal>(proposals, tableColumnEnum.AwardStatusName),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.AwardStatusName),
			defaultFilter: storedValue.awardStatusName,
		},
		{
			title: 'Account Number',
			field: tableColumnEnum.AccountNumber,
			hidden: !tableColumnPreferences.includes(tableColumnEnum.AccountNumber),
		},
		{
			title: 'Agency Number',
			field: tableColumnEnum.AgencyNumber,
			hidden: !tableColumnPreferences.includes(tableColumnEnum.AgencyNumber),
		},
		{
			title: 'Proposal Number',
			field: tableColumnEnum.ProposalNumber,
			hidden: !tableColumnPreferences.includes(tableColumnEnum.ProposalNumber),
		},
		{
			title: 'Lead Agency',
			field: tableColumnEnum.LeadAgencyName,
			lookup: ArrayHelper.toObject<ProposalModels.Proposal>(proposals, tableColumnEnum.LeadAgencyName),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.LeadAgencyName),
			defaultFilter: storedValue.leadAgencyName,
		},
		{
			title: 'M Number',
			field: tableColumnEnum.MNumber,
			hidden: !tableColumnPreferences.includes(tableColumnEnum.MNumber),
			defaultFilter: storedValue.mNumber,
		},
		{
			title: 'Submitted On',
			field: tableColumnEnum.SubmittedOn,
			render: rowData => DateHelper.getShortDateString(rowData.submittedOn),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterDate(term, rowData.submittedOn),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.SubmittedOn),
			defaultFilter: storedValue.submittedOn,
		},
		{
			title: 'Starts On',
			field: tableColumnEnum.StartsOn,
			render: rowData => DateHelper.getShortDateString(rowData.startsOn),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterDate(term, rowData.startsOn),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.StartsOn),
			defaultFilter: storedValue.startsOn,
		},
		{
			title: 'Ends On',
			field: tableColumnEnum.EndsOn,
			render: rowData => DateHelper.getShortDateString(rowData.endsOn),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterDate(term, rowData.endsOn),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.EndsOn),
			defaultFilter: storedValue.endsOn,
		},
		{
			title: 'Contract Signed On',
			field: tableColumnEnum.ContractSignedOn,
			render: rowData => DateHelper.getShortDateString(rowData.contractSignedOn),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterDate(term, rowData.contractSignedOn),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.ContractSignedOn),
			defaultFilter: storedValue.contractSignedOn,
		},
		{
			title: 'Funding Source',
			field: tableColumnEnum.FundingSourceName,
			hidden: !tableColumnPreferences.includes(tableColumnEnum.FundingSourceName),
			defaultFilter: storedValue.fundingSourceName,
		},
		{
			title: 'Grant Program',
			field: tableColumnEnum.GrantProgramName,
			hidden: !tableColumnPreferences.includes(tableColumnEnum.GrantProgramName),
			defaultFilter: storedValue.grantProgramName,
		},
		{
			title: 'Direct Costs',
			field: tableColumnEnum.DirectCosts,
			render: rowData => TextHelper.money(rowData.directCosts),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterNumber(term, rowData.directCosts),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.DirectCosts),
			defaultFilter: storedValue.directCosts,
		},
		{
			title: 'Indirect Costs',
			field: tableColumnEnum.IndirectCosts,
			render: rowData => TextHelper.money(rowData.indirectCosts),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterNumber(term, rowData.indirectCosts),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.IndirectCosts),
			defaultFilter: storedValue.indirectCosts,
		},
		{
			title: 'Total Award',
			field: tableColumnEnum.TotalAward,
			render: rowData => TextHelper.money(rowData.totalAward),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterNumber(term, rowData.totalAward),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.TotalAward),
			defaultFilter: storedValue.totalAward,
		},
		{
			title: 'Direct Match',
			field: tableColumnEnum.DirectMatch,
			render: rowData => TextHelper.money(rowData.directMatch),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterNumber(term, rowData.directMatch),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.DirectMatch),
			defaultFilter: storedValue.directMatch,
		},
		{
			title: 'Indirect Match',
			field: tableColumnEnum.IndirectMatch,
			render: rowData => TextHelper.money(rowData.indirectMatch),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterNumber(term, rowData.indirectMatch),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.IndirectMatch),
			defaultFilter: storedValue.indirectMatch,
		},
		{
			title: 'Unrecovered IDC',
			field: tableColumnEnum.UnrecoveredIDC,
			render: rowData => TextHelper.money(rowData.unrecoveredIDC),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterNumber(term, rowData.unrecoveredIDC),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.UnrecoveredIDC),
			defaultFilter: storedValue.unrecoveredIDC,
		},
		{
			title: 'Total Match',
			field: tableColumnEnum.TotalMatch,
			render: rowData => TextHelper.money(rowData.totalMatch),
			customFilterAndSearch: (term, rowData) => TableFilters.FilterNumber(term, rowData.totalMatch),
			hidden: !tableColumnPreferences.includes(tableColumnEnum.TotalMatch),
			defaultFilter: storedValue.totalMatch,
		},
	];

	const [columns, setColumns] = useState(defaultColumns);
	const ep = useProposalsEndpoint();
	const usersEp = useUsersEndpoint();

	useEffect(() => {
		DOMHelper.setPageTitle('Home');
		ep.GetProposals().then(r => setProposals(r.sort((a, b) => ('' + a.shortName).localeCompare(b.shortName))));
		const getLocalAdvanced = localStorage.getItem('isAdvanced') === 'true' ? true : false;
		setIsAdvanced(getLocalAdvanced);
		usersEp.Get(state.CurrentUser?.guid ?? '').then(r => r && setUserPreferences(r));
	}, []);

	useEffect(() => {
		let proposalsFiltered = proposals;

		// Filter proposals by chosen institute user preference
		if (userPreferences.proposalsInstitute !== 'all') {
			proposalsFiltered = proposalsFiltered.filter(
				p => p.instituteName.toLowerCase() === userPreferences.proposalsInstitute.toLowerCase()
			);
		}

		// Filter proposals by selected exclusion user preferences
		if (userPreferences.excludeProposals.length) {
			proposalsFiltered = proposalsFiltered.filter(
				p =>
					!userPreferences.excludeProposals.includes(
						p.awardStatusName.toLowerCase() as UserModels.ExcludeProposals
					)
			);
		}

		// set up people columns since those don't come directly from the API
		// having just the helper format columns in the render makes it so sort dones't work
		proposalsFiltered.forEach(p => {
			p.principalInvestigator = RoleHelper.principalInvestigator(p.roleAssignments);
			p.primaryContact = RoleHelper.primaryContact(p.roleAssignments);
			p.businessContact = RoleHelper.businessContact(p.roleAssignments);
			p.coPIs = RoleHelper.coPIs(p.roleAssignments);
		});

		setSummaryData(proposalsFiltered);
		// set columns back to default since the lookup props for some columns had no data when the page mounted
		setColumns(defaultColumns);
		setFilteredProposals(proposalsFiltered);
	}, [proposals, isAdvanced, userPreferences]);

	useEffect(() => {
		if (tableRef && tableRef.current) {
			// MT interface doesn't have state on it, so just do as any to make TS happy
			setSummaryData((tableRef.current as any).state.data);
		}
	}, [tableRef]);

	return (
		<Fade in={true}>
			<React.Fragment>
				<MaterialTable
					tableRef={tableRef}
					columns={columns}
					data={filteredProposals}
					title="Proposals"
					icons={TableIcons}
					options={{
						pageSize: 10,
						headerStyle: TableStyles.headerStyle,
						filtering: isAdvanced,
						search: !isAdvanced,
						columnsButton: true,
						exportMenu: [
							{
								label: 'Export',
								exportFunc: (columns, currentPageData, tableData) =>
									ExportCsv(columns, tableData.searchedData, 'Proposals'),
							},
						],
						rowStyle: (row: ProposalModels.Proposal) => {
							if (
								!userPreferences.highlightEnding ||
								row.endsOn === undefined ||
								// tslint:disable-next-line: strict-type-predicates
								row.endsOn === null ||
								row.awardStatusName !== 'Awarded'
							) {
								return {};
							}

							const colors = state.Theme === 'light' ? bgColors : darkBgColors;
							const daysRemaining = DateHelper.calculateDaysDifference(row.endsOn, new Date());

							if (row.isExpirationDismissed) {
								return colors.blue;
							} else if (row.isExtensionFiled) {
								return colors.green;
							} else if (daysRemaining <= 90) {
								return colors.red;
							} else if (daysRemaining <= 180) {
								return colors.orange;
							}

							if (row.nextCostEndsOn !== undefined) {
								const costDaysRemaining = DateHelper.calculateDaysDifference(
									row.nextCostEndsOn,
									new Date()
								);
								if (costDaysRemaining <= 90) {
									return colors.purple;
								}
							}

							return {};
						},
					}}
					// MT interface doesn't have state on it, so just do as any to make TS happy
					onSearchChange={() => setSummaryData((tableRef.current as any).state.data)}
					onFilterChange={filters => {
						setSummaryData((tableRef.current as any).state.data);

						const saveFilters: { [k: string]: any } = {};
						filters.forEach(f => {
							saveFilters[(f.column.field as string) || ''] = f.value;
						});
						localStorage.setItem('savedFilters', JSON.stringify(saveFilters));
					}}
					actions={[
						{
							icon: () => <FilterIcon isFiltering={isAdvanced} />,
							tooltip: 'Advanced Search',
							isFreeAction: true,
							onClick: () => {
								// tslint:disable-next-line:no-invalid-this
								setIsAdvanced(!isAdvanced);
								localStorage.setItem('isAdvanced', JSON.stringify(!isAdvanced));
								localStorage.setItem('savedFilters', JSON.stringify({}));
							},
						},
					]}
					isLoading={ep.IsLoading}
				/>
				<Grid container={true}>
					<Grid item={true} md={6}>
						{userPreferences.highlightEnding ? (
							<StyledCard title="Legend">
								<Grid container={true}>
									<Grid item={true} xs={1} className={classnames([classes.orange, classes.legend])} />
									<Grid item={true} xs={10}>
										<Typography component="p" variant="h6">
											Ending in 6 months
										</Typography>
									</Grid>
									<Grid item={true} xs={1} className={classnames([classes.red, classes.legend])} />
									<Grid item={true} xs={10}>
										<Typography component="p" variant="h6">
											Ending in 3 months
										</Typography>
									</Grid>
									<Grid item={true} xs={1} className={classnames([classes.blue, classes.legend])} />
									<Grid item={true} xs={10}>
										<Typography component="p" variant="h6">
											Ending; acknowledged
										</Typography>
									</Grid>
									<Grid item={true} xs={1} className={classnames([classes.green, classes.legend])} />
									<Grid item={true} xs={10}>
										<Typography component="p" variant="h6">
											Ending; extension requested
										</Typography>
									</Grid>
									<Grid item={true} xs={1} className={classnames([classes.purple, classes.legend])} />
									<Grid item={true} xs={10}>
										<Typography component="p" variant="h6">
											Budget ends in 3 months
										</Typography>
									</Grid>
								</Grid>
							</StyledCard>
						) : (
							''
						)}
					</Grid>
					<Grid item={true} md={6}>
						<StyledCard title={`Summary for ${summaryData.length} proposals`}>
							<Grid container={true}>
								<Grid item={true} md={6}>
									Total DC {TextHelper.money(summaryData.reduce((t, i) => t + i.directCosts, 0))}
									<br />
									Total IDC {TextHelper.money(summaryData.reduce((t, i) => t + i.indirectCosts, 0))}
									<br />
									Total Costs{' '}
									{TextHelper.money(
										summaryData.reduce((t, i) => t + i.indirectCosts + i.directCosts, 0)
									)}
								</Grid>
								<Grid item={true} md={6}>
									Total Match {TextHelper.money(summaryData.reduce((t, i) => t + i.totalMatch, 0))}
								</Grid>
							</Grid>
						</StyledCard>
					</Grid>
				</Grid>
			</React.Fragment>
		</Fade>
	);
};
