import React, { useEffect } from 'react';
import { DOMHelper } from 'Common/Helpers';

import { Fade, Grid, Card, CardContent, Typography, makeStyles } from '@material-ui/core';

import StandardCosts from 'Assets/BudgetResources/Standard-Costs-for-proposal-budget-preparation-v5.xlsx';

import SRSTemplate from 'Assets/BudgetResources/SRS-Budget-Template-FY18.xlsx';

import GLORevision from 'Assets/BudgetResources/Blank-Budget-Revision-Form-GLO.xls';
import TCEQRevision from 'Assets/BudgetResources/TCEQ-Blank-Budget-Rev-Form-Updated-7-22-15.xlsx';
import TSSWCBRevision from 'Assets/BudgetResources/TSSWCB-Blank-Budget-Revision-Form.xls';

import CommEstimates from 'Assets/BudgetResources/Communications-Tasks.docx';
import IDCRates from 'Assets/BudgetResources/FA-Rates-College-Station-Based-FY2015-18.pdf';
import FringeRates from 'Assets/BudgetResources/Fringe-Benefits-FY18.pdf';
import SRSSubform from 'Assets/BudgetResources/TAMU-SRS-SubForm-Final.pdf';
import SubcontractMatrix from 'Assets/BudgetResources/Subcontract-Matrix-Revised-Sept-2014.docx';
import SubVsVendor from 'Assets/BudgetResources/Subcontractor-vs-Vendor.docx';
import TCEQPEL from 'Assets/BudgetResources/TCEQ-PEL-Form.docx';
import TSSWCBFringe from 'Assets/BudgetResources/TSSWCB-Salary-Fringe-Statement2017.docx';

export interface IBudgetResourcesProps {}

const useStyles = makeStyles(() => ({
	link: {
		color: '#23CCEF',
	},
}));

export const BudgetResources = (props: IBudgetResourcesProps) => {
	const classes = useStyles();

	useEffect(() => {
		DOMHelper.setPageTitle('Budget Resources');
	}, []);

	return (
		<Fade in={true}>
			<Grid container={true}>
				<Grid item={true} md={6}>
					<Card>
						<CardContent>
							<Typography gutterBottom={true} variant="h5" component="h2">
								Budget Resources
							</Typography>

							<ul>
								<li>
									<a className={classes.link} href={StandardCosts}>
										Standard Costs for Proposal Budget Preparation
									</a>
								</li>
							</ul>

							<Typography gutterBottom={true} variant="h6" component="h3">
								SRS Budget Templates
							</Typography>
							<ul>
								<li>
									<a className={classes.link} href={SRSTemplate}>
										FY18 Template
									</a>
								</li>
							</ul>

							<Typography gutterBottom={true} variant="h6" component="h3">
								Budget Revisions
							</Typography>
							<ul>
								<li>
									<a className={classes.link} href={GLORevision}>
										GLO
									</a>
								</li>
								<li>
									<a className={classes.link} href={TCEQRevision}>
										TCEQ
									</a>
								</li>
								<li>
									<a className={classes.link} href={TSSWCBRevision}>
										TSSWCB
									</a>
								</li>
							</ul>

							<Typography gutterBottom={true} variant="h6" component="h3">
								Other Budget Resources
							</Typography>
							<ul>
								<li>
									<a className={classes.link} href={CommEstimates}>
										Communications Task Estimates
									</a>
								</li>
								<li>
									<a className={classes.link} href={IDCRates}>
										IDC Rate Agreement FY2015-18
									</a>
								</li>
								<li>
									<a className={classes.link} href={FringeRates}>
										Fringe Rates for FY18
									</a>
								</li>
								<li>
									<a className={classes.link} href={SRSSubform}>
										TAMU SRS SubForm
									</a>
								</li>
								<li>
									<a className={classes.link} href={SubcontractMatrix}>
										Subcontract Matrix
									</a>
								</li>
								<li>
									<a className={classes.link} href={SubVsVendor}>
										Subcontractor vs. Vendor Determination
									</a>
								</li>
								<li>
									<a className={classes.link} href={TCEQPEL}>
										TCEQ PEL Form
									</a>
								</li>
								<li>
									<a className={classes.link} href={TSSWCBFringe}>
										TSSWCB Salary Fringe Statement, 2017
									</a>
								</li>
							</ul>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Fade>
	);
};
