import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import AddPartyIcon from '@material-ui/icons/AddToPhotos';
import { Link } from 'react-router-dom';
import { useLinkedProposalsEndpoint, useProposalsEndpoint } from 'Endpoints';
import { Proposal, LinkedProposals } from 'Models/ProposalModels';
import { ProposalSelect } from 'Common/Forms/Fields';

// props to be provided by parent component
// this is the components external interface
export interface ILinkedProposalsCardProps {
	editable: boolean;
	linkedProposals: LinkedProposals[];
	onDelete?: (LinkedProposals: LinkedProposals) => void;
	onAdd?: (LinkedProposals: LinkedProposals) => void;
	proposalId: number;
}

export const LinkedProposalsCard = (props: ILinkedProposalsCardProps) => {
	const ep = useLinkedProposalsEndpoint();
	const proposalEp = useProposalsEndpoint();
	const [proposals, setProposals] = useState<Proposal[]>([]);

	useEffect(() => {
		proposalEp.GetProposals().then(r => setProposals(r));
	}, []);

	const handleDelete = (proposalId: number, linkedProposalID: number) => {
		ep.Delete(proposalId, linkedProposalID).then(r => r && props.onDelete && props.onDelete(r));
	};

	const [show, setShow] = useState(false);

	const handleItemClick = (linkedProposalID: number) => {
		const linked = new LinkedProposals();
		linked.proposalId = props.proposalId;
		linked.linkedProposalId = linkedProposalID;
		ep.Add(linked).then(r => r && props.onAdd && props.onAdd(r));
		setShow(false);
	};

	const showSelect = () => {
		setShow(true);
	};

	const existingLinkIds = props.linkedProposals.map(x => x.linkedProposalId);
	const filteredProposals = proposals
		.filter(x => x.id !== props.proposalId) // don't allow linking to itself
		.filter(x => !existingLinkIds.includes(x.id)) // filter out existing linked proposals
		.sort((a, b) => ('' + a.shortName).localeCompare(b.shortName));
	return (
		<React.Fragment>
			<List dense={true}>
				{props.linkedProposals
					.sort((a, b) => ('' + a.relatedProposalShortName).localeCompare(b.relatedProposalShortName))
					.map(l => {
						return (
							<ListItem key={l.linkedProposalId}>
								<Link to={`/proposals/${l.linkedProposalId}`}>
									<ListItemText primary={l.relatedProposalShortName} />
								</Link>
								{props.editable && (
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="delete"
											onClick={() => handleDelete(l.proposalId, l.linkedProposalId)}
										>
											<TrashIcon color="error" />
										</IconButton>
									</ListItemSecondaryAction>
								)}
							</ListItem>
						);
					})}
			</List>
			{props.editable && (
				<React.Fragment>
					<IconButton onClick={showSelect}>
						<AddPartyIcon color="primary" />
					</IconButton>
					{show ? (
						<ProposalSelect
							proposals={filteredProposals}
							name="proposals"
							setField={false}
							multiple={false}
							onChange={v => handleItemClick(v)}
						/>
					) : (
						''
					)}
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
