import { CSSProperties } from '@material-ui/styles';

export const headerStyle = {
	whiteSpace: 'nowrap',
} as CSSProperties;

export const rowStyle = {
	paddingTop: 4,
	paddingBottom: 4,
} as CSSProperties;
