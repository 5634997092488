import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { AddWorkItem, EditWorkItem, WorkItem } from 'Models/WorkItemModels';

export const useWorkItemsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('workItems');

	const Get = () =>
		ep.Get<WorkItem[]>().catch(() => {
			useError('Error getting proposal tasks');
			return [] as WorkItem[];
		});

	const Add = (dto: AddWorkItem) =>
		ep
			.Post<WorkItem>(dto)
			.then(r => {
				useSuccess('Task added');
				return {
					...r,
					startsOn: typeof r.startsOn === 'string' ? new Date(r.startsOn) : r.startsOn,
					endsOn: typeof r.endsOn === 'string' ? new Date(r.endsOn) : r.endsOn,
				};
			})
			.catch(() => {
				useError('Error adding task');
				return undefined;
			});

	const Edit = (dto: EditWorkItem) =>
		ep
			.Put<WorkItem>(dto.id, dto)
			.then(r => {
				useSuccess('Task saved');
				return {
					...r,
					startsOn: typeof r.startsOn === 'string' ? new Date(r.startsOn) : r.startsOn,
					endsOn: typeof r.endsOn === 'string' ? new Date(r.endsOn) : r.endsOn,
				};
			})
			.catch(() => {
				useError('Error saving task');
				return undefined;
			});

	const Delete = (id: number) =>
		ep
			.Delete<number>(id)
			.then(r => {
				useSuccess('Task deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting task');
				return undefined;
			});

	return { Get, Add, Edit, Delete, IsLoading: ep.IsLoading };
};
