import React from 'react';
import MaterialTable from '@material-table/core';
import { TableIcons } from '.';
import AddIcon from '@material-ui/icons/Add';
import TrashIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { TableStyles, Colors } from 'Common/Styles';
import { EditWorkItem, WorkItem } from 'Models/WorkItemModels';
import { DateHelper } from 'Common/Helpers';
import { useNameof } from 'Common/Helpers/ReactHelper';

export interface IWorkItemTableProps {
	isLoading?: boolean;
	workItems: WorkItem[];
	onAddClicked: () => void;
	onEditClicked: (WorkItem: EditWorkItem) => void;
	onDeleteClicked: (id: number) => void;
	editable: boolean;
}

export const WorkItemTable = (props: IWorkItemTableProps) => {
	const colors = Colors.useColors();
	const nameof = useNameof<WorkItem>();
	return (
		<MaterialTable
			columns={[
				{ title: 'Name', field: 'title' },
				{
					title: 'Starts On',
					field: 'startsOn',
					render: rowData => DateHelper.getShortDateString(rowData.startsOn),
				},
				{
					title: 'Ends On',
					field: nameof('endsOn'),
					render: rowData => DateHelper.getShortDateString(rowData.endsOn),
				},
				{ title: 'Person', field: nameof('personName') },
				{ title: 'Team', field: nameof('teamName') },
				{ title: 'Parent', field: nameof('parentTitle') },
			]}
			data={props.workItems}
			title="Work Items"
			icons={TableIcons}
			options={{
				pageSize: 5,
				headerStyle: TableStyles.headerStyle,
				actionsColumnIndex: -1,
			}}
			actions={[
				{
					icon: () => <AddIcon className={colors.primary} />,
					tooltip: 'Add Work Items',
					position: 'toolbar',
					onClick: event => props.onAddClicked(),
					hidden: !props.editable,
				},
				{
					icon: () => <EditIcon className={colors.warning} />,
					tooltip: 'Edit',
					onClick: (event, rowData: WorkItem) => props.onEditClicked(new EditWorkItem(rowData)),
					hidden: !props.editable,
				},
				{
					icon: () => <TrashIcon className={colors.error} />,
					tooltip: 'Delete',
					onClick: (event, rowData: WorkItem) => props.onDeleteClicked(rowData.id),
					hidden: !props.editable,
				},
			]}
			isLoading={props.isLoading}
		/>
	);
};
