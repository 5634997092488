/** used when getting grant programs, and the model sent from the API when editing a grant program */
export class GrantProgram {
	id: number = 0;
	name: string = '';
	shortName: string = '';
	isActive?: boolean;
}

export class AddGrantProgram {
	name: string = '';
	shortName: string = '';
}

export class EditGrantProgram {
	id: number;
	name: string;
	shortName: string;

	constructor(id: number, name: string = '', shortName: string = '') {
		this.id = id;
		this.name = name;
		this.shortName = shortName;
	}
}
