import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';

import { Fade, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { TableIcons } from 'Common/Tables';
import AddIcon from '@material-ui/icons/Add';
// import TrashIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import { AddPersonForm } from 'Common/Forms/Add';
import { TableStyles, Colors } from 'Common/Styles';
import { EditPersonForm } from 'Common/Forms/Edit';
import { Person, EditPerson } from 'Models/PersonModels';
import { usePeopleEndpoint } from 'Endpoints';

enum ModalOptions {
	None = 0,
	Add = 1,
	Edit = 2,
}

export const People = () => {
	const ep = usePeopleEndpoint();
	const [people, setPeople] = useState<Person[]>([]);
	const [openModal, setOpenModal] = useState<ModalOptions>(ModalOptions.None);
	const colors = Colors.useColors();
	const [editPerson, setEditPerson] = useState<EditPerson>(new EditPerson(0));

	useEffect(() => {
		DOMHelper.setPageTitle('People Admin');
		ep.Get(true).then(r => setPeople(r));
	}, []);

	const handleLock = (id: number, currentStatus: boolean) => {
		ep.EditActive(id, !currentStatus).then(() => ep.Get(true).then(r => setPeople(r)));
	};

	const handleEditSucess = () => {
		setOpenModal(ModalOptions.None);
		ep.Get(true).then(r => setPeople(r));
	};

	useEffect(() => {
		if (editPerson.id !== 0) {
			setOpenModal(ModalOptions.Edit);
		}
	}, [editPerson]);

	return (
		<Fade in={true}>
			<React.Fragment>
				<MaterialTable
					columns={[
						{ title: 'Name', field: 'fullName' },
						{ title: 'Username', field: 'username' },
						{ title: 'Description', field: 'description' },
					]}
					data={people}
					title="People"
					icons={TableIcons}
					options={{
						pageSize: 10,
						headerStyle: TableStyles.headerStyle,
						exportAllData: true,
						actionsColumnIndex: -1,
						rowStyle: (rowData: Person) => ({
							opacity: !rowData.isActive ? 0.4 : 1,
						}),
					}}
					isLoading={ep.IsLoading}
					actions={[
						{
							icon: () => <AddIcon className={colors.primary} />,
							tooltip: 'Add Person',
							position: 'toolbar',
							onClick: event => setOpenModal(ModalOptions.Add),
						},
						{
							icon: () => <EditIcon className={colors.warning} />,
							tooltip: 'Edit',
							onClick: (event, rowData: Person) =>
								setEditPerson(
									new EditPerson(
										rowData.id,
										rowData.firstName,
										rowData.lastName,
										rowData.username,
										rowData.description
									)
								),
						},
						{
							icon: () => <LockIcon />,
							tooltip: 'Lock/Set Inactive',
							onClick: (event, rowData: Person) => handleLock(rowData.id, rowData.isActive),
						},
						// {
						// 	icon: () => <TrashIcon className={colors.error} />,
						// 	tooltip: 'Delete',
						// 	onClick: event => alert('delete clicked'),
						// },
					]}
				/>

				<Dialog
					maxWidth="md"
					open={openModal === ModalOptions.Add}
					onClose={() => setOpenModal(ModalOptions.None)}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Add Person</DialogTitle>
					<DialogContent>
						<AddPersonForm onSuccess={() => setOpenModal(ModalOptions.None)} />
					</DialogContent>
				</Dialog>

				<Dialog
					maxWidth="md"
					open={openModal === ModalOptions.Edit}
					onClose={() => setOpenModal(ModalOptions.None)}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Edit Person</DialogTitle>
					<DialogContent>
						<EditPersonForm person={editPerson} onSuccess={handleEditSucess} />
					</DialogContent>
				</Dialog>
			</React.Fragment>
		</Fade>
	);
};
