import { DateHelper } from 'Common/Helpers';

/** Boolean indicating the supplied term matches the supplied value.
 * Capable of greater than and less than.
 * Can be used for money, decimal, and percentage
 */
export const FilterNumber = (term: string, value?: number) => {
	// just return false if the value is undefined
	if (value === undefined) {
		return false;
	}

	// replace all commas and empty decimal places in search term since we compare to the underlying number for a cell
	term = term.replace(/,/g, '').replace('.00', '');

	// first, try to just to a straight match
	if (value.toString().includes(term)) {
		return true;
	}

	// check gt and lt if string contains comparison and rest of string is a number
	// start with more restrictive conditions first
	if (term.startsWith('>=')) {
		const numberTerm = Number(term.replace('>=', '').trim());
		if (!isNaN(numberTerm)) {
			return value >= numberTerm;
		}
	}

	if (term.startsWith('>')) {
		const numberTerm = Number(term.replace('>', '').trim());
		if (!isNaN(numberTerm)) {
			return value > numberTerm;
		}
	}

	if (term.startsWith('<=')) {
		const numberTerm = Number(term.replace('<=', '').trim());
		if (!isNaN(numberTerm)) {
			return value <= numberTerm;
		}
	}

	if (term.startsWith('<')) {
		const numberTerm = Number(term.replace('<', '').trim());
		if (!isNaN(numberTerm)) {
			return value < numberTerm;
		}
	}

	return false;
};

/** Boolean indicating the supplied term matches the supplied value.
 * Capable of greater than and less than.
 */
export const FilterDate = (term: string, value?: Date | null) => {
	// just return false if the value is undefined or null
	if (value === undefined || value === null) {
		return false;
	}

	// make sure any returns in the loop only return true
	// returning false will prevent any other search terms to be ignored
	for (const d of term.split(',')) {
		let splitTerm = d;
		// separate comparison operator from the search term so the term can be parsed into a date
		let compOp = '';
		const matchArray = splitTerm.match(/^([<>=])*/g);
		if (matchArray) {
			compOp = matchArray[0];
		}
		splitTerm = splitTerm.replace(compOp, '');

		// force the term into the M/d/yyyy format that dates will be in
		// allow users to put in something like 12/1/19 and have it turn into 12/1/2019
		if (splitTerm.includes('/') && !splitTerm.includes('-')) {
			splitTerm = DateHelper.getShortDateString(DateHelper.parseShortDate(splitTerm));
		}

		// turn the date into an actual date object instead of a half-ass ISO date thing
		// without this, it'll console log out something like 2017-07-01T00:00:00
		value = DateHelper.parseShortDate(DateHelper.getShortDateString(value));
		if (value === undefined) {
			// return false since no amount of iterated terms will make this a valid date
			return false;
		}

		// first, try to just to a straight match
		if (compOp === '') {
			const dateString = DateHelper.getShortDateString(value);
			if (dateString.includes(splitTerm)) {
				return true;
			}

			// allow for searching date range with a dash
			if (splitTerm.includes('-')) {
				const startDate = DateHelper.parseShortDate(splitTerm.split('-')[0].trim());
				const endDate = DateHelper.parseShortDate(splitTerm.split('-')[1].trim());

				if (startDate !== undefined && endDate !== undefined && value >= startDate && value <= endDate) {
					return true;
				}
			}
		}

		// check gt and lt if string contains comparison and rest of string is a date
		// start with more restrictive conditions first
		if (compOp === '>=') {
			// if the term is only a year, default it to 12/31 of that year
			if (!splitTerm.includes('/')) {
				splitTerm = '12/31/' + splitTerm;
			}
			const dateTerm = DateHelper.parseShortDate(splitTerm.trim());
			if (dateTerm !== undefined && value >= dateTerm) {
				return true;
			}
		}

		if (compOp === '>') {
			// if the term is only a year, default it to 12/31 of that year
			if (!splitTerm.includes('/')) {
				splitTerm = '12/31/' + splitTerm;
			}
			const dateTerm = DateHelper.parseShortDate(splitTerm.trim());
			if (dateTerm !== undefined && value > dateTerm) {
				return true;
			}
		}

		if (compOp === '<=') {
			// if the term is only a year, default it to 1/1 of that year
			if (!splitTerm.includes('/')) {
				splitTerm = '1/1/' + splitTerm;
			}
			const dateTerm = DateHelper.parseShortDate(splitTerm.trim());
			if (dateTerm !== undefined && value <= dateTerm) {
				return true;
			}
		}

		if (compOp === '<') {
			// if the term is only a year, default it to 1/1 of that year
			if (!splitTerm.includes('/')) {
				splitTerm = '1/1/' + splitTerm;
			}
			const dateTerm = DateHelper.parseShortDate(splitTerm.trim());
			if (dateTerm !== undefined && value < dateTerm) {
				return true;
			}
		}
	}

	return false;
};

/** Boolean indicating the supplied term matches the supplied value.
 * Capable of greater than and less than.
 * Can be used for money, decimal, and percentage
 */
export const FilterText = (term: string | string[] | undefined, value?: string) => {
	// just return false if the value is undefined or null
	// TS warns the value will never be null, but that doesn't seem to be the case
	// tslint:disable-next-line: strict-type-predicates
	if (value === undefined || value === null) {
		return false;
	}

	// dropdown filters can send an array instead of text
	if (Array.isArray(term)) {
		term = term[0];
	}

	// makes sure it's not undefined. can happen when dropdown is deselected and array is zero length
	term = term ?? '';

	// normalize to lower case so searches don't have to be case sensitive
	term = term.toLowerCase();
	value = value.toLowerCase();

	// first, try to just to a straight match
	if (value.includes(term)) {
		return true;
	}

	// allow for searching multiple exact string matches
	if (term.includes(';') || term.includes(' or ')) {
		for (const d of term.split(/;| or /)) {
			if (d !== '' && value.trim().includes(d.trim())) {
				return true;
			}
		}
	}

	return false;
};
