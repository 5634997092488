import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { GrantProgramModels } from 'Models';
import { GlobalAutocomplete } from '.';
import { GrantProgram } from 'Models/GrantProgramModels';

export interface IGrantProgramSelectProps {
	/** The array of grant programs that will be shown as dropdown items */
	grantPrograms: GrantProgramModels.GrantProgram[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
	/** IMPORTANT!!! Mulitple must be set to false for single select autocompletes or it bombs the application */
	multiple?: boolean;
	/** For controlled autocomplete */
	value?: GrantProgram;
}

export const GrantProgramSelect = (props: IGrantProgramSelectProps) => {
	// because it is stupid and won't load properly for a controlled select correctly!!
	const [val, setVal] = useState(props.value);

	useEffect(() => {
		setVal(props.value);
	}, [props.value]);

	return (
		<Field
			name={props.name}
			key="grant-program-auto"
			component={(p: any) => (
				<GlobalAutocomplete
					{...p}
					label={props.label || 'Grant Program'}
					options={props.grantPrograms.sort((a, b) => ('' + a.name).localeCompare(b.name))}
					value={val}
					namefield="grantProgramName"
					setField={true}
					inputChanged={(v: GrantProgram) => setVal(v)}
				/>
			)}
			multiple={props.multiple === undefined ? false : props.multiple}
			getOptionLabel={(option: GrantProgram) => option.name}
			fullWidth={true}
		/>
	);
};
