import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';

import { Fade, Grid, Card, CardContent, Typography, Button, CircularProgress, ListItemText, createStyles, LinearProgress, Theme, withStyles, Box, LinearProgressProps } from '@material-ui/core';
import { useSearchEndpoint } from 'Endpoints';
import { Stats } from 'Models/AppModels';

export const Search = () => {
	const ep = useSearchEndpoint();
	const [stats, setStats] = useState<Stats[]>([]);

	useEffect(() => {
		DOMHelper.setPageTitle('Search Admin');
	}, []);

	const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
		return (
			<Box display="flex" alignItems="center">
				<Box width="100%" mr={1}>
					<LinearProgress variant="determinate" {...props} />
				</Box>
				<Box minWidth={125}>
					<Typography variant="body2" color="textSecondary">{`${props.value} records`}</Typography>
				</Box>
			</Box>
		);
	}

	const BorderLinearProgress = withStyles((theme: Theme) =>
		createStyles({
			root: {
				height: 10,
				borderRadius: 5,
			},
			colorPrimary: {
				backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
			},
			bar: {
				borderRadius: 5,
				// backgroundColor: '#1a90ff',
			},
		}),
	)(LinearProgressWithLabel);

	return (
		<Fade in={true}>
			<Grid container={true}>
				<Grid item={true} md={6} lg={5}>
					<Card>
						<CardContent>
							<Typography gutterBottom={true} variant="h5" component="h2">
								Build Search Index
							</Typography>
							<Typography variant="body2" color="textSecondary" component="div">
								<p>
									Click the button below to rebuild the entire search index for projects, agreements,
									and parcels. This action may take a few minutes.
								</p>
							</Typography>
							<Button
								variant="contained"
								color="primary"
								disabled={ep.IsLoading}
								onClick={() => ep.BuildIndex().then(r => r && setStats(r))}
							>
								{ep.IsLoading && (
									<CircularProgress
										size={24}
										style={{
											position: 'absolute',
											top: '50%',
											left: '50%',
											marginTop: -12,
											marginLeft: -12,
										}}
									/>
								)}
								Rebuild Index
							</Button>
							{!ep.IsLoading &&
								(<Grid container={true} style={{ paddingTop: 15 }}>
									{stats && stats.map(s => {
										console.log(s);

										return (<Grid item={true} sm={12} style={{ paddingTop: 15 }}>
											<BorderLinearProgress variant="determinate" value={s.docCount} />
											<ListItemText primary={`${s.docType}s index rebuilt on ${s.updatedDate}`} secondary={`Created on ${s.createdDate}`} />
										</Grid>
										)
									})}
								</Grid>
								)}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Fade>
	);
};
