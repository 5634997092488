import { useAuthorization } from 'Common/Helpers/AuthHelper';
import { useAppState } from 'Context/AppProvider';
import React from 'react';

type RoleName = 'superUser' | 'admin';

export interface IProtectedComponentProps {
	children?: any; // https://stackoverflow.com/a/55858755
	allowedRoles?: RoleName[];
	restrictedRoles?: RoleName[];
	allowedUsers?: string[];
}

type Props = IProtectedComponentProps;

export const ProtectedComponent = (props: Props) => {
	const { isAdmin, isSuperUser } = useAuthorization();
	const state = useAppState();

	const userIsAuthorized = (): boolean => {
		if (!state.CurrentUser) {
			return false;
		}

		if (props.allowedRoles && props.allowedRoles.includes('admin') && isAdmin()) {
			return true;
		}

		if (props.allowedRoles && props.allowedRoles.includes('superUser') && isSuperUser()) {
			return true;
		}

		if (props.restrictedRoles && props.restrictedRoles.includes('admin') && !isAdmin()) {
			return true;
		}

		if (props.restrictedRoles && props.restrictedRoles.includes('superUser') && !isSuperUser()) {
			return true;
		}

		if (props.allowedUsers && props.allowedUsers.includes(state.CurrentUser.preferredUsername)) {
			return true;
		}

		return false;
	};

	return userIsAuthorized() ? props.children : <React.Fragment />;
};
