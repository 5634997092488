import React, { useState } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { FormControl, Button, LinearProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { usePeopleEndpoint } from 'Endpoints';
import { AddPerson } from 'Models/PersonModels';
import { AddPersonSchema } from '../ValidationSchemas/PersonSchema';
import { FormDebugging } from '../Fields';

// props to be provided by parent component
// this is the components external interface
export interface IAddPersonFormProps {
	onSuccess: () => void;
}

export const AddPersonForm = (props: IAddPersonFormProps) => {
	const ep = usePeopleEndpoint();
	const classes = formStyles();
	const nameof = useNameof<AddPerson>();

	const [person, setPerson] = useState(new AddPerson());

	const handleSuccess = () => {
		setPerson(new AddPerson());
		props.onSuccess();
	};

	return (
		<Formik
			initialValues={person}
			validationSchema={AddPersonSchema}
			onSubmit={(values, { setSubmitting }) => {
				ep.Add(values).then(response => response && handleSuccess());
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form className={classes.flexForm}>
					<FormControl fullWidth={true}>
						<Field name={nameof('firstName')} label="First Name" type="text" component={TextField} />
					</FormControl>
					<FormControl fullWidth={true}>
						<Field name={nameof('lastName')} label="Last Name" type="text" component={TextField} />
					</FormControl>
					<FormControl fullWidth={true}>
						<Field
							name={nameof('username')}
							label="Username (Optional)"
							type="text"
							component={TextField}
						/>
					</FormControl>
					<FormControl fullWidth={true}>
						<Field name={nameof('description')} label="Description" type="text" component={TextField} />
					</FormControl>
					<FormControl>
						{formprops.isSubmitting && <LinearProgress />}
						<Button
							type="submit"
							color="primary"
							variant="contained"
							disabled={formprops.isSubmitting}
							onSubmit={formprops.submitForm}
						>
							{formprops.isSubmitting ? 'Saving...' : 'Add'}
						</Button>
					</FormControl>
					<FormDebugging />
				</Form>
			)}
		</Formik>
	);
};
