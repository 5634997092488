import { FundingType } from 'Models/FundingTypeModels';
import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';

export const useFundingTypesEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('fundingtypes');

	const Get = () =>
		ep.Get<FundingType[]>().catch(() => {
			useError('Error getting funding types');
			return [] as FundingType[];
		});
	return { Get, IsLoading: ep.IsLoading };
};
