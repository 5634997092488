import React, { useState } from 'react';
import { GrantProgramModels } from 'Models';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { FormControl, Button, LinearProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { formStyles } from '../styles';
import { useGrantProgramsEndpoint } from 'Endpoints';
import { EditGrantProgramSchema } from '../ValidationSchemas/GrantProgramSchema';
import { FormDebugging } from '../Fields';

// props to be provided by parent component
// this is the components external interface
export interface IEditGrantProgramFormProps {
	onSuccess: () => void;
	grantProgram: GrantProgramModels.EditGrantProgram;
}

export const EditGrantProgramForm = (props: IEditGrantProgramFormProps) => {
	const ep = useGrantProgramsEndpoint();
	const classes = formStyles();
	const nameof = useNameof<GrantProgramModels.EditGrantProgram>();

	const [grantProgram, setGrantProgram] = useState(props.grantProgram);

	const handleSuccess = () => {
		setGrantProgram(new GrantProgramModels.EditGrantProgram(0));
		props.onSuccess();
	};

	return (
		<React.Fragment>
			<Formik
				initialValues={grantProgram}
				validationSchema={EditGrantProgramSchema}
				onSubmit={(values, { setSubmitting }) => {
					ep.Edit(values).then(response => response && handleSuccess());
					setSubmitting(false);
				}}
			>
				{formprops => (
					<Form className={classes.flexForm}>
						<FormControl>
							<Field
								label="Acronym"
								fullWidth={true}
								type="text"
								name={nameof('shortName')}
								component={TextField}
							/>
						</FormControl>
						<FormControl>
							<Field label="Name" fullWidth={true} name={nameof('name')} component={TextField} />
						</FormControl>
						<FormControl>
							{formprops.isSubmitting && <LinearProgress />}
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={formprops.isSubmitting}
								onSubmit={formprops.submitForm}
							>
								{formprops.isSubmitting ? 'Saving...' : 'Save'}
							</Button>
						</FormControl>
						<FormDebugging />
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};
