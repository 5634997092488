import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { ProposalModels } from 'Models';
import { Proposal } from 'Models/ProposalModels';
import { GlobalAutocomplete } from '.';

export interface IProposalSelectProps {
	/** The array of proposals that will be shown as dropdown items */
	proposals: ProposalModels.Proposal[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
	/** IMPORTANT!!! Mulitple must be set to false for single select autocompletes or it bombs the application */
	multiple?: boolean;
	/** For controlled autocomplete */
	value?: Proposal;
	/** For autosave, set to false for all others set to true */
	setField?: boolean;
	/** For save on change in the involved parties card */
	onChange?: (v: number) => void;
}

export const ProposalSelect = (props: IProposalSelectProps) => {
	const [val, setVal] = useState(props.value);

	useEffect(() => {
		setVal(props.value);
	}, [props.value]);

	return (
		<Field
			name={props.name}
			key="proposal-auto"
			component={(p: any) => (
				<GlobalAutocomplete
					{...p}
					label={props.label || 'Proposal'}
					options={props.proposals.sort((a, b) => ('' + a.shortName).localeCompare(b.shortName))}
					setField={false}
					inputChanged={(v: Proposal) => val && setVal({ ...val, shortName: v.shortName })}
					valueChanged={(v: Proposal) => props.onChange && props.onChange(v.id)}
				/>
			)}
			multiple={props.multiple === undefined ? false : props.multiple}
			getOptionLabel={(option: Proposal) => option.shortName}
			fullWidth={true}
		/>
	);
};
