import React from 'react';
import { AppBar as MuiAppBar, Toolbar, makeStyles, Theme, Button, Hidden, IconButton } from '@material-ui/core';
import { ProtectedComponent } from 'Common/Utilities';
import MenuIcon from '@material-ui/icons/Menu';
import { AppBarMenu } from 'Layout/Navigation';
import { AppBarSearch } from './';
import LightIcon from '@material-ui/icons/Brightness7';
import DarkIcon from '@material-ui/icons/Brightness4';
import { useAppState, useAppDispatch } from 'Context/AppProvider';

export interface IAppBarProps {
	onAddClick: () => void;
	onMenuClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		boxShadow: 'none',
		borderBottom:
			'solid 1px ' + (theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[900]),
	},
	grow: {
		flexGrow: 1,
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
}));

export const AppBar = (props: IAppBarProps) => {
	const classes = useStyles();
	const state = useAppState();
	const dispatch = useAppDispatch();

	return (
		<div>
			<MuiAppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<Hidden lgUp={true} implementation="css">
						<IconButton color="inherit" aria-label="Open drawer" onClick={props.onMenuClick}>
							<MenuIcon />
						</IconButton>
					</Hidden>
					<div className={classes.grow} />
					<AppBarSearch />
					<div className={classes.sectionDesktop}>
						<ProtectedComponent allowedRoles={['admin']}>
							<Button onClick={() => props.onAddClick()}>Add Proposal</Button>
						</ProtectedComponent>
					</div>
					{state.Theme === 'light' ? (
						<IconButton
							onClick={() => dispatch({ type: 'APP/SET_THEME', payload: 'dark' })}
							color="inherit"
							size="small"
							className={classes.menuButton}
						>
							<DarkIcon />
						</IconButton>
					) : (
						<IconButton
							onClick={() => dispatch({ type: 'APP/SET_THEME', payload: 'light' })}
							color="inherit"
							size="small"
							className={classes.menuButton}
						>
							<LightIcon />
						</IconButton>
					)}
					<AppBarMenu />
				</Toolbar>
			</MuiAppBar>
		</div>
	);
};
