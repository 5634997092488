import React, { useState } from 'react';
import { CostModels } from 'Models';
import { AddCostForm } from 'Common/Forms/Add';
import { DateHelper, TextHelper } from 'Common/Helpers';
import classnames from 'classnames';

import { Tab, Tabs, FormControl, FormLabel, InputBase, makeStyles, Button, Grid, Paper } from '@material-ui/core';
import { TabPanel } from 'Common/Elements';
import AddIcon from '@material-ui/icons/Add';
import { StyledCard } from 'Common/Cards';
import EditIcon from '@material-ui/icons/Edit';

export interface ICostsCardProps {
	costs: CostModels.Cost[];
	onDeleteClicked: (id: number) => void;
	onEditClicked: (cost: CostModels.EditCost) => void;
	editable: boolean;
	onSuccess: (cost: CostModels.Cost) => void;
	proposalId: number;
}

const useStyles = makeStyles(theme => ({
	divider: {
		borderTop: 'solid 1px #ccc',
	},
	control: {
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'space-around',
	},
	label: {
		width: '50%',
		margin: 'auto',
	},
	input: {
		width: '50%',
		margin: 'auto',
	},
	bold: {
		fontWeight: 'bold',
	},
	alignRight: {
		textAlign: 'right',
	},
}));

const CostControl = (props: { label: string; value: string; bold?: boolean }) => {
	const classes = useStyles();
	return (
		<FormControl className={classes.control}>
			<FormLabel className={classnames(classes.label, { [classes.bold]: props.bold })}>{props.label}</FormLabel>
			<InputBase
				className={classnames(classes.input, { [classes.bold]: props.bold })}
				value={props.value} // PSA: defaultValue prevents this from updating when state or props changes
				inputProps={{ 'aria-label': 'naked' }}
			/>
		</FormControl>
	);
};

export const CostsCard = (props: ICostsCardProps) => {
	const classes = useStyles();

	const [selectedTab, setSelectedTab] = useState(0);
	// for summary display
	const totalAward = props.costs.reduce((c, v) => {
		const direct = v.directCosts !== undefined ? v.directCosts : 0;
		const indirect = v.indirectCostTotal !== undefined ? v.indirectCostTotal : 0;
		return c + (direct + indirect);
	}, 0);
	const totalMatch = props.costs.reduce((c, v) => {
		const direct = v.directCostMatch !== undefined ? v.directCostMatch : 0;
		const indirect = v.indirectCostMatch !== undefined ? v.indirectCostMatch : 0;
		const unrecovered = v.unrecoveredIndirectCost !== undefined ? v.unrecoveredIndirectCost : 0;
		return c + (direct + indirect + unrecovered);
	}, 0);

	return (
		<React.Fragment>
			<Tabs
				value={selectedTab}
				onChange={(event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => setSelectedTab(newValue)}
				aria-label="scrollable auto tabs example"
				variant="scrollable"
			>
				{props.costs
					.sort((a, b) => (a.startsOn && b.startsOn ? (a.startsOn < b.startsOn ? -1 : 1) : -1))
					.map(c => {
						return (
							<Tab
								key={'cost-tab-' + c.id}
								label={`${DateHelper.getShortDateString(
									c.startsOn,
									'MM/yy'
								)} - ${DateHelper.getShortDateString(c.endsOn, 'MM/yy')}`}
							/>
						);
					})}
				{props.editable && <Tab label={<AddIcon />} />}
			</Tabs>
			<Paper>
				{props.costs
					.sort((a, b) => (a.startsOn && b.startsOn ? (a.startsOn < b.startsOn ? -1 : 1) : -1))
					.map((c, i) => {
						return (
							<TabPanel key={'cost-values-' + c.id} selectedTab={selectedTab} index={i}>
								<CostControl label="Direct Costs" value={TextHelper.money(c.directCosts)} />
								<CostControl label="IDC Rate" value={TextHelper.percentage(c.indirectCostRate)} />
								<CostControl label="Indirect Costs" value={TextHelper.money(c.indirectCostTotal)} />
								<CostControl
									label="Total Award"
									bold={true}
									value={TextHelper.money(
										(c.directCosts ? c.directCosts : 0) +
										(c.indirectCostTotal ? c.indirectCostTotal : 0)
									)}
								/>

								<hr className={classes.divider} />

								<CostControl label="Direct Match" value={TextHelper.money(c.directCostMatch)} />
								<CostControl label="IDC Match" value={TextHelper.money(c.indirectCostMatch)} />
								<CostControl
									label="Unrecovered IDC"
									value={TextHelper.money(c.unrecoveredIndirectCost)}
								/>
								<CostControl
									label="Total Match"
									bold={true}
									value={TextHelper.money(
										(c.directCostMatch ? c.directCostMatch : 0) +
										(c.indirectCostMatch ? c.indirectCostMatch : 0) +
										(c.unrecoveredIndirectCost ? c.unrecoveredIndirectCost : 0)
									)}
								/>
								<CostControl label="Funds are Spent?" value={c.areFundsSpent ? 'Yes' : 'No'} />
								<CostControl label="Starts On" value={DateHelper.getShortDateString(c.startsOn)} />
								<CostControl label="Ends On" value={DateHelper.getShortDateString(c.endsOn)} />
								<Grid container={true}>
									<Grid item={true} md={6}>
										{props.editable && (
											<Button onClick={() => props.onDeleteClicked(c.id)}>Delete</Button>
										)}
									</Grid>
									<Grid item={true} md={6} className={classes.alignRight}>
										{props.editable && (
											<Button
												color="primary"
												variant="contained"
												onClick={() =>
													props.onEditClicked(
														new CostModels.EditCost(
															c.id,
															c.startsOn,
															c.endsOn,
															c.indirectCostRate,
															c.indirectCostTotal,
															c.directCosts,
															c.directCostMatch,
															c.indirectCostMatch,
															c.unrecoveredIndirectCost,
															props.proposalId
														)
													)
												}
											>
												<EditIcon /> Edit
											</Button>
										)}
									</Grid>
								</Grid>
							</TabPanel>
						);
					})}
				<TabPanel selectedTab={selectedTab} index={props.costs.length}>
					<AddCostForm onSuccess={props.onSuccess} proposalId={props.proposalId} />
				</TabPanel>
			</Paper>
			<StyledCard title="Total Costs Summary">
				<Grid container={true} direction="row" spacing={3}>
					<Grid item={true} md={4}>
						Total Award
					</Grid>
					<Grid item={true} md={2}>
						{TextHelper.money(totalAward)}
					</Grid>
				</Grid>
				<Grid container={true} direction="row">
					<Grid item={true} md={4}>
						Total Match
					</Grid>
					<Grid item={true} md={2}>
						{TextHelper.money(totalMatch)}
					</Grid>
				</Grid>
			</StyledCard>
		</React.Fragment>
	);
};

export default CostsCard;
