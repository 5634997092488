import React, { useEffect, useState } from 'react';
import {
	makeStyles,
	Theme,
	Paper,
	Grid,
	Typography,
	Button,
	FormControl,
	FormGroup,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Checkbox,
} from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { DOMHelper } from 'Common/Helpers';
import {
	ExcludeProposals,
	ProposalTableColumns,
	Preferences,
	ExcludesValues,
	ProposalTableColumnsValues,
} from 'Models/UserModels';
import { useAppState } from 'Context/AppProvider';
import { useInstitutesEndpoint, useUsersEndpoint } from 'Endpoints';
import { Institute } from 'Models/InstituteModels';

const darkBlueGrey = blueGrey[900];
const lightBlueGrey = blueGrey[200];
const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		padding: theme.spacing(2),
	},
	headerContainer: {
		marginBottom: theme.spacing(2),
	},
	formControl: {
		display: 'block',
		marginBottom: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	label: {
		borderBottom: `1px solid ${theme.palette.type === 'light' ? darkBlueGrey : lightBlueGrey}`,
		color: theme.palette.type === 'light' ? darkBlueGrey : lightBlueGrey,
		fontSize: '18px',
		marginBottom: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		width: '100%',
	},
	radioCheckbox: {
		paddingBottom: '5px',
		paddingTop: '5px',
	},
	columnsContainer: {
		maxHeight: '400px',
	},
}));

export const Profile = () => {
	const state = useAppState();
	const classes = useStyles();
	const [preferences, setPreferences] = useState(new Preferences());
	const [institutes, setInstitutes] = useState<Institute[]>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const ep = useUsersEndpoint();
	const instituteEp = useInstitutesEndpoint();

	useEffect(() => {
		DOMHelper.setPageTitle('Proposals Page Preferences');
		ep.Get(state.CurrentUser?.guid ?? '').then(r => r && setPreferences(r));
		instituteEp.Get().then(r => setInstitutes(r));
	}, []);

	const handleExcludeChange = (name: ExcludeProposals) => (event: React.ChangeEvent<HTMLInputElement>) => {
		let newExcludeProposals = [...preferences.excludeProposals];
		const containsValue = newExcludeProposals.includes(name);

		if (event.target.checked && !containsValue) {
			newExcludeProposals = [...newExcludeProposals, name] as ExcludeProposals[];
		} else if (!event.target.checked && containsValue) {
			newExcludeProposals = newExcludeProposals.filter(e => e !== name);
		}

		setPreferences({ ...preferences, excludeProposals: newExcludeProposals });
	};

	const handleColumnChange = (name: ProposalTableColumns) => (event: React.ChangeEvent<HTMLInputElement>) => {
		let newColumns = [...preferences.proposalsTableColumns];
		const containsValue = newColumns.includes(name);

		if (event.target.checked && !containsValue) {
			newColumns = [...newColumns, name] as ProposalTableColumns[];
		} else if (!event.target.checked && containsValue) {
			newColumns = newColumns.filter(c => c !== name);
		}

		setPreferences({ ...preferences, proposalsTableColumns: newColumns });
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		if (state.CurrentUser?.guid) {
			ep.Edit(state.CurrentUser.guid, preferences).then(r => r && setIsSubmitting(false));
		}
	};

	const excludeProposals = [...Object.values(ExcludeProposals)].reduce((acc, e) => {
		acc[e] = preferences.excludeProposals.includes(e);
		return acc;
	}, new ExcludesValues());
	const tableColumns = [...Object.values(ProposalTableColumns)].reduce((acc, c) => {
		acc[c] = preferences.proposalsTableColumns.includes(c);
		return acc;
	}, new ProposalTableColumnsValues());

	return (
		<Paper className={classes.paper}>
			<form onSubmit={handleSubmit}>
				<Grid container={true} direction="row" justify="space-between" className={classes.headerContainer}>
					<Typography component="h1" variant="h6">
						Proposals Page Preferences
					</Typography>
				</Grid>
				<Grid container={true}>
					<Grid item={true} md={5}>
						<Grid>
							{institutes.length > 1 && (
								<FormControl component="fieldset" className={classes.formControl}>
									<FormLabel component="legend" className={classes.label}>
										Institute/Department Preferences
									</FormLabel>
									<RadioGroup
										aria-label="institute preferences"
										name="institutePreference"
										value={preferences.proposalsInstitute}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											setPreferences({
												...preferences,
												proposalsInstitute: event.target.value,
											})
										}
									>
										{institutes.map(i => {
											return (
												<FormControlLabel
													key={i.shortName}
													value={i.shortName.toLocaleLowerCase()}
													control={<Radio className={classes.radioCheckbox} />}
													label={i.shortName}
												/>
											);
										})}

										<FormControlLabel
											value="all"
											control={<Radio className={classes.radioCheckbox} />}
											label="All"
										/>
									</RadioGroup>
								</FormControl>
							)}
						</Grid>
						<Grid>
							<FormControl component="fieldset" className={classes.formControl}>
								<FormLabel component="legend" className={classes.label}>
									Proposal Exclusion Preferences
								</FormLabel>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												checked={excludeProposals.completed}
												onChange={handleExcludeChange(ExcludeProposals.Completed)}
												value={ExcludeProposals.Completed}
												className={classes.radioCheckbox}
											/>
										}
										label="Exclude Completed?"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={excludeProposals.rejected}
												onChange={handleExcludeChange(ExcludeProposals.Rejected)}
												value={ExcludeProposals.Rejected}
												className={classes.radioCheckbox}
											/>
										}
										label="Exclude Rejected?"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={excludeProposals.withdrawn}
												onChange={handleExcludeChange(ExcludeProposals.Withdrawn)}
												value={ExcludeProposals.Withdrawn}
												className={classes.radioCheckbox}
											/>
										}
										label="Exclude Withdrawn?"
									/>
								</FormGroup>
							</FormControl>
						</Grid>
						<Grid>
							<FormControl component="fieldset" className={classes.formControl}>
								<FormLabel component="legend" className={classes.label}>
									Other
								</FormLabel>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												checked={preferences.highlightEnding}
												onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
													setPreferences({
														...preferences,
														highlightEnding: event.target.checked,
													})
												}
												value={'highlightEnding'}
												className={classes.radioCheckbox}
											/>
										}
										label="Highlight Ending Proposals?"
									/>
								</FormGroup>
							</FormControl>
						</Grid>
					</Grid>
					<Grid item={true} md={7}>
						<FormControl component="fieldset" className={classes.formControl}>
							<FormLabel component="legend" className={classes.label}>
								Proposals Table Columns
							</FormLabel>
							<FormGroup className={classes.columnsContainer}>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.shortName}
											onChange={handleColumnChange(ProposalTableColumns.ShortName)}
											value={ProposalTableColumns.ShortName}
											className={classes.radioCheckbox}
										/>
									}
									label="Name"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.instituteName}
											onChange={handleColumnChange(ProposalTableColumns.InstituteName)}
											value={ProposalTableColumns.InstituteName}
											className={classes.radioCheckbox}
										/>
									}
									label="Institute/Dept"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.leadAgencyName}
											onChange={handleColumnChange(ProposalTableColumns.LeadAgencyName)}
											value={ProposalTableColumns.LeadAgencyName}
											className={classes.radioCheckbox}
										/>
									}
									label="Agency"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.primaryContact}
											onChange={handleColumnChange(ProposalTableColumns.PrimaryContact)}
											value={ProposalTableColumns.PrimaryContact}
											className={classes.radioCheckbox}
										/>
									}
									label="Primary Contact"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.principalInvestigator}
											onChange={handleColumnChange(ProposalTableColumns.PrincipalInvestigator)}
											value={ProposalTableColumns.PrincipalInvestigator}
											className={classes.radioCheckbox}
										/>
									}
									label="Principal Investigator"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.coPIs}
											onChange={handleColumnChange(ProposalTableColumns.CoPIs)}
											value={ProposalTableColumns.CoPIs}
											className={classes.radioCheckbox}
										/>
									}
									label="Co-PIs"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.teams}
											onChange={handleColumnChange(ProposalTableColumns.Teams)}
											value={ProposalTableColumns.Teams}
											className={classes.radioCheckbox}
										/>
									}
									label="Teams"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.businessContact}
											onChange={handleColumnChange(ProposalTableColumns.BusinessContact)}
											value={ProposalTableColumns.BusinessContact}
											className={classes.radioCheckbox}
										/>
									}
									label="Business Contact"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.awardStatusName}
											onChange={handleColumnChange(ProposalTableColumns.AwardStatusName)}
											value={ProposalTableColumns.AwardStatusName}
											className={classes.radioCheckbox}
										/>
									}
									label="Award Status"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.accountNumber}
											onChange={handleColumnChange(ProposalTableColumns.AccountNumber)}
											value={ProposalTableColumns.AccountNumber}
											className={classes.radioCheckbox}
										/>
									}
									label="Account Number"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.agencyNumber}
											onChange={handleColumnChange(ProposalTableColumns.AgencyNumber)}
											value={ProposalTableColumns.AgencyNumber}
											className={classes.radioCheckbox}
										/>
									}
									label="Agency Number"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.proposalNumber}
											onChange={handleColumnChange(ProposalTableColumns.ProposalNumber)}
											value={ProposalTableColumns.ProposalNumber}
											className={classes.radioCheckbox}
										/>
									}
									label="Proposal Number"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.mNumber}
											onChange={handleColumnChange(ProposalTableColumns.MNumber)}
											value={ProposalTableColumns.MNumber}
											className={classes.radioCheckbox}
										/>
									}
									label="M Number"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.submittedOn}
											onChange={handleColumnChange(ProposalTableColumns.SubmittedOn)}
											value={ProposalTableColumns.SubmittedOn}
											className={classes.radioCheckbox}
										/>
									}
									label="Submitted On"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.startsOn}
											onChange={handleColumnChange(ProposalTableColumns.StartsOn)}
											value={ProposalTableColumns.StartsOn}
											className={classes.radioCheckbox}
										/>
									}
									label="Starts On"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.endsOn}
											onChange={handleColumnChange(ProposalTableColumns.EndsOn)}
											value={ProposalTableColumns.EndsOn}
											className={classes.radioCheckbox}
										/>
									}
									label="Ends On"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.contractSignedOn}
											onChange={handleColumnChange(ProposalTableColumns.ContractSignedOn)}
											value={ProposalTableColumns.ContractSignedOn}
											className={classes.radioCheckbox}
										/>
									}
									label="Contract Signed On"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.fundingSourceName}
											onChange={handleColumnChange(ProposalTableColumns.FundingSourceName)}
											value={ProposalTableColumns.FundingSourceName}
											className={classes.radioCheckbox}
										/>
									}
									label="Funding Source"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.grantProgramName}
											onChange={handleColumnChange(ProposalTableColumns.GrantProgramName)}
											value={ProposalTableColumns.GrantProgramName}
											className={classes.radioCheckbox}
										/>
									}
									label="Grant Program"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.directCosts}
											onChange={handleColumnChange(ProposalTableColumns.DirectCosts)}
											value={ProposalTableColumns.DirectCosts}
											className={classes.radioCheckbox}
										/>
									}
									label="Direct Costs"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.indirectCosts}
											onChange={handleColumnChange(ProposalTableColumns.IndirectCosts)}
											value={ProposalTableColumns.IndirectCosts}
											className={classes.radioCheckbox}
										/>
									}
									label="Indirect Costs"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.totalAward}
											onChange={handleColumnChange(ProposalTableColumns.TotalAward)}
											value={ProposalTableColumns.TotalAward}
											className={classes.radioCheckbox}
										/>
									}
									label="Total Award"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.directMatch}
											onChange={handleColumnChange(ProposalTableColumns.DirectMatch)}
											value={ProposalTableColumns.DirectMatch}
											className={classes.radioCheckbox}
										/>
									}
									label="Direct Match"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.indirectMatch}
											onChange={handleColumnChange(ProposalTableColumns.IndirectMatch)}
											value={ProposalTableColumns.IndirectMatch}
											className={classes.radioCheckbox}
										/>
									}
									label="Indirect Match"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.unrecoveredIDC}
											onChange={handleColumnChange(ProposalTableColumns.UnrecoveredIDC)}
											value={ProposalTableColumns.UnrecoveredIDC}
											className={classes.radioCheckbox}
										/>
									}
									label="Unrecovered IDC"
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tableColumns.totalMatch}
											onChange={handleColumnChange(ProposalTableColumns.TotalMatch)}
											value={ProposalTableColumns.TotalMatch}
											className={classes.radioCheckbox}
										/>
									}
									label="Total Match"
								/>
							</FormGroup>
						</FormControl>
					</Grid>
					<Grid item={true} md={12}>
						<Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
							Save Changes
						</Button>
					</Grid>
				</Grid>
			</form>
		</Paper>
	);
};
