import React, { useEffect, useState, useReducer } from 'react';
import classnames from 'classnames';
import { ProposalModels, AwardStatusModels } from 'Models';
import { DateHelper, ReactHelper } from 'Common/Helpers';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
	Theme,
	Paper,
	Grid,
	FormControl,
	Typography,
	Button,
	Tab,
	Tabs,
	AppBar,
	Divider,
	LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
	InstituteSelect,
	AgencySelect,
	FundingTypeSelect,
	OrganizationSelect,
	GrantProgramSelect,
	AwardStatusSelect,
	GlobalDatePicker,
	FormDebugging,
} from 'Common/Forms/Fields';
import { TabPanel } from 'Common/Elements';
import { Colors } from 'Common/Styles';
import { InvolvedPartiesCard } from '.';
import InfoIcon from '@material-ui/icons/Info';
import OrganizationIcon from '@material-ui/icons/AccountTree';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import RelatedIcon from '@material-ui/icons/Autorenew';
import {
	AwardedCompletedProposalCard,
	RejectedProposalCard,
	WithdrawnProposalCard,
	FacilitatedProposalCard,
	LinkedProposalsCard,
} from './';
import { AwardStatuses } from 'Models/AwardStatusModels';
import {
	useGrantProgramsEndpoint,
	useInstitutesEndpoint,
	useAgenciesEndpoint,
	useProposalsEndpoint,
	useOrganizationsEndpoint,
	useAwardStatusesEndpoint,
	useFundingTypesEndpoint,
} from 'Endpoints';
import { ProposalDetailsReducer, ProposalDetailsState } from './ProposalDetailsReducer';
import { EditProposalSchema } from 'Common/Forms/ValidationSchemas/ProposalSchema';

export interface IProposalDetailsCardProps {
	proposal: ProposalModels.ProposalDetails;
	onEditSuccess: (proposal: ProposalModels.ProposalDetails) => void;
	onDeleteClicked: () => void;
	onInvolvedPartyDeleted: (involvedParty: ProposalModels.InvolvedParty) => void;
	onInvolvedPartyAdded: (involvedParty: ProposalModels.InvolvedParty) => void;
	onRelatedProposalDeleted: (involvedParty: ProposalModels.LinkedProposals) => void;
	onRelatedProposalAdded: (involvedParty: ProposalModels.LinkedProposals) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	gridContainer: {
		marginBottom: theme.spacing(4),
		paddingRight: `${theme.spacing(2)}px`,
	},
	nameFormControl: {
		marginLeft: '0',
		marginRight: '0',
		maxWidth: '100%',
		width: '100%',
	},
	nameInput: {
		marginLeft: '0',
		marginTop: theme.spacing(1),
	},
	detailsContainer: {
		margin: 0,
		padding: 0,
		borderTopWidth: 4,
		borderTopStyle: 'solid',
	},
	formControl: {
		marginLeft: '0',
		marginRight: '0',
		maxWidth: '100%',
		padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
		width: '100%',
	},
	formField: {
		marginLeft: '0',
		marginRight: '0',
	},
	lineBreak: {
		margin: `${theme.spacing(3)}px 0 ${theme.spacing(1)}px`,
	},
	propertyHeader: {
		color: theme.palette.text.secondary,
		display: 'block',
		fontSize: '14px',
		marginBottom: theme.spacing(1),
	},
	propertyValue: {
		fontSize: '16px',
		display: 'block',
		marginBottom: theme.spacing(3),
	},
	buttonContainer: {
		margin: `${theme.spacing(2)}px 0`,
	},
	alignRight: {
		textAlign: 'right',
	},
}));

enum TabsOptions {
	General = 0,
	Organizations = 1,
	Status = 2,
	Related = 3,
}

export const ProposalDetailsCardEditable = (props: IProposalDetailsCardProps) => {
	const classes = useStyles();
	const colors = Colors.useColors();
	const nameof = ReactHelper.useNameof<ProposalModels.ProposalDetails>();

	const [proposal, setProposal] = useState(new ProposalModels.ProposalDetails());
	const [state, dispatch] = useReducer(ProposalDetailsReducer, new ProposalDetailsState());

	const ep = useProposalsEndpoint();
	const grantEp = useGrantProgramsEndpoint();
	const instituteEp = useInstitutesEndpoint();
	const agencyEp = useAgenciesEndpoint();
	const organizationEp = useOrganizationsEndpoint();
	const awardStatusEp = useAwardStatusesEndpoint();
	const fundingTypeEp = useFundingTypesEndpoint();

	useEffect(() => {
		Promise.all([
			instituteEp.Get(),
			agencyEp.Get(),
			fundingTypeEp.Get(),
			organizationEp.Get(),
			grantEp.Get(),
			awardStatusEp.Get(),
		]).then(r => {
			dispatch({
				type: 'DROPDOWNS_LOADED',
				payload: {
					institutes: r[0],
					agencies: r[1],
					fundingTypes: r[2],
					organizations: r[3],
					grantPrograms: r[4],
					awardStatuses: r[5],
				},
			});
		});
	}, []);

	useEffect(() => {
		setProposal(props.proposal);
	}, [props.proposal]);

	const [selectedTab, setSelectedTab] = useState(TabsOptions.General);

	if (ep.IsLoading) {
		return <LinearProgress />;
	}

	return (
		<Formik
			initialValues={proposal}
			enableReinitialize={true}
			validationSchema={EditProposalSchema}
			onSubmit={(values, { setSubmitting }) => {
				ep.Edit(values as ProposalModels.EditProposal).then(
					response => response && props.onEditSuccess(values)
				);
				setSubmitting(false);
			}}
		>
			{formprops => {
				const currentAwardStatusId = formprops.values.awardStatusId;
				return (
					<Form>
						<Grid container={true} className={classes.gridContainer}>
							<Grid item={true} md={12}>
								<Paper
									className={classnames(
										classes.detailsContainer,
										{
											[colors.defaultTopBorder]:
												currentAwardStatusId === AwardStatusModels.AwardStatuses.Submitted,
										},
										{
											[colors.warningTopBorder]:
												currentAwardStatusId === AwardStatusModels.AwardStatuses.Pending ||
												currentAwardStatusId ===
													AwardStatusModels.AwardStatuses.ContractPending,
										},
										{
											[colors.primaryTopBorder]:
												currentAwardStatusId === AwardStatusModels.AwardStatuses.Awarded ||
												currentAwardStatusId === AwardStatusModels.AwardStatuses.Completed,
										},
										{
											[colors.secondaryTopBorder]:
												currentAwardStatusId === AwardStatusModels.AwardStatuses.Facilitated,
										},
										{
											[colors.errorTopBorder]:
												currentAwardStatusId === AwardStatusModels.AwardStatuses.Rejected ||
												currentAwardStatusId === AwardStatusModels.AwardStatuses.Withdrawn,
										}
									)}
								>
									<Grid container={true}>
										<Grid item={true} xs={12}>
											<FormControl className={classes.formControl}>
												<Field
													label="Short Name"
													fullWidth={true}
													name={nameof('shortName')}
													className={classes.formField}
													component={TextField}
												/>
											</FormControl>
										</Grid>

										<Grid item={true} xs={12}>
											<FormControl className={classes.formControl}>
												<Field
													label="Long Name"
													fullWidth={true}
													name={nameof('longName')}
													className={classes.formField}
													component={TextField}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<Field
													label="Proposal Number"
													fullWidth={true}
													name={nameof('proposalNumber')}
													className={classes.formField}
													component={TextField}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<Field
													label="M Number"
													fullWidth={true}
													name={nameof('mNumber')}
													className={classes.formField}
													component={TextField}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<Field
													label="Client Contract Number"
													fullWidth={true}
													className={classes.formField}
													name={nameof('clientContractNumber')}
													component={TextField}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<Field
													component={GlobalDatePicker}
													label="Starts On"
													name={nameof('startsOn')}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<Field
													component={GlobalDatePicker}
													label="Ends On"
													name={nameof('endsOn')}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4} />
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<InstituteSelect
													institutes={state.institutes}
													name={nameof('instituteId')}
													className={classes.formField}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<AgencySelect
													agencies={state.agencies}
													name={nameof('leadAgencyId')}
													className={classes.formField}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<OrganizationSelect
													organizations={state.organizations}
													label="Funding Source"
													name={nameof('fundingSourceId')}
													className={classes.formField}
													multiple={false}
													setField={true}
													value={state.organizations.find(
														o => o.id === proposal.fundingSourceId
													)}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<GrantProgramSelect
													grantPrograms={state.grantPrograms}
													name={nameof('grantProgramId')}
													className={classes.formField}
													multiple={false}
													value={state.grantPrograms.find(
														g => g.id === proposal.grantProgramId
													)}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<FundingTypeSelect
													fundingTypes={state.fundingTypes}
													name={nameof('fundingTypeId')}
													className={classes.formField}
												/>
											</FormControl>
										</Grid>
										<Grid item={true} xs={6} md={4}>
											<FormControl className={classes.formControl}>
												<AwardStatusSelect
													awardStatuses={state.awardStatuses}
													name={nameof('awardStatusId')}
													label="Award Status"
													className={classes.formField}
													onChange={() => setSelectedTab(TabsOptions.Status)}
												/>
											</FormControl>
										</Grid>
									</Grid>
									<Divider style={{ marginTop: 10 }} />
									<Grid container={true}>
										<Grid item={true} md={3} lg={3}>
											<AppBar
												position="static"
												color="default"
												elevation={0}
												style={{ height: '100%' }}
											>
												<Tabs
													value={selectedTab}
													orientation="vertical"
													onChange={(
														event: React.ChangeEvent<Record<string, unknown>>,
														newValue: number
													) => setSelectedTab(newValue)}
													aria-label="scrollable auto tabs example"
												>
													<Tab icon={<InfoIcon />} label="General Info" wrapped={false} />
													<Tab icon={<OrganizationIcon />} label="Organizations" />
													<Tab icon={<TrophyIcon />} label="Status" />
													<Tab icon={<RelatedIcon />} label="Related Proposals" />
												</Tabs>
											</AppBar>
										</Grid>
										<Grid item={true} xs={9} lg={9}>
											<TabPanel selectedTab={selectedTab} index={TabsOptions.General}>
												<Grid container={true}>
													<Grid item={true} xs={12}>
														<FormControl className={classes.formControl}>
															<Field
																label="Abstract"
																fullWidth={true}
																multiline={true}
																name={nameof('abstract')}
																component={TextField}
															/>
														</FormControl>
													</Grid>
													<Grid item={true} xs={6} md={4}>
														<FormControl className={classes.formControl}>
															<Field
																component={GlobalDatePicker}
																label="Submitted On"
																name={nameof('submittedOn')}
															/>
														</FormControl>
													</Grid>
													<Grid item={true} xs={6} md={4}>
														<FormControl className={classes.formControl}>
															<Field
																label="Confluence Key"
																fullWidth={true}
																name={nameof('confluenceKey')}
																className={classes.formField}
																component={TextField}
															/>
														</FormControl>
													</Grid>
													<Grid item={true} xs={6} md={4}>
														<FormControl className={classes.formControl}>
															<Field
																label="Jira Key"
																fullWidth={true}
																name={nameof('jiraKey')}
																className={classes.formField}
																component={TextField}
															/>
														</FormControl>
													</Grid>
													<Grid item={true} xs={6} md={2}>
														<Typography component="span" className={classes.propertyHeader}>
															Created On
														</Typography>
														<Typography component="span" className={classes.propertyValue}>
															{DateHelper.getShortDateString(formprops.values.createdOn)}
														</Typography>
													</Grid>
													<Grid item={true} xs={6} md={2}>
														<Typography component="span" className={classes.propertyHeader}>
															Submitted By
														</Typography>
														<Typography component="span" className={classes.propertyValue}>
															{formprops.values.createdByName}
														</Typography>
													</Grid>
												</Grid>
											</TabPanel>
											<TabPanel selectedTab={selectedTab} index={TabsOptions.Organizations}>
												<InvolvedPartiesCard
													editable={true}
													involvedParties={proposal.involvedParties}
													onDelete={props.onInvolvedPartyDeleted}
													onAdd={props.onInvolvedPartyAdded}
													proposalId={formprops.values.id}
												/>
											</TabPanel>
											<TabPanel selectedTab={selectedTab} index={TabsOptions.Status}>
												{(currentAwardStatusId === AwardStatuses.Awarded ||
													currentAwardStatusId === AwardStatuses.Completed) && (
													<AwardedCompletedProposalCard
														awardedProposal={formprops.values.awardedProposal}
														onChange={ap => formprops.setFieldValue('awardedProposal', ap)}
														errors={formprops.errors.awardedProposal}
														editable={true}
													/>
												)}
												{currentAwardStatusId === AwardStatuses.Facilitated && (
													<FacilitatedProposalCard
														facilitatedProposal={formprops.values.facilitatedProposal}
														onChange={fp =>
															formprops.setFieldValue('facilitatedProposal', fp)
														}
														errors={formprops.errors.facilitatedProposal}
														editable={true}
													/>
												)}
												{currentAwardStatusId === AwardStatuses.Rejected && (
													<RejectedProposalCard
														rejectedProposal={formprops.values.rejectedProposal}
														onChange={rp => formprops.setFieldValue('rejectedProposal', rp)}
														editable={true}
														errors={formprops.errors.rejectedProposal}
													/>
												)}
												{currentAwardStatusId === AwardStatuses.Withdrawn && (
													<WithdrawnProposalCard
														withdrawnProposal={formprops.values.withdrawnProposal}
														onChange={wp =>
															formprops.setFieldValue('withdrawnProposal', wp)
														}
														errors={formprops.errors.withdrawnProposal}
														editable={true}
													/>
												)}
											</TabPanel>
											<TabPanel selectedTab={selectedTab} index={TabsOptions.Related}>
												<LinkedProposalsCard
													editable={true}
													linkedProposals={proposal.linkedProposals}
													onDelete={props.onRelatedProposalDeleted}
													onAdd={props.onRelatedProposalAdded}
													proposalId={proposal.id}
												/>
											</TabPanel>
											<Grid container={true} className={classes.buttonContainer}>
												{formprops.isSubmitting && <LinearProgress />}
												<Grid item={true} xs={6}>
													<FormControl>
														<Button onClick={props.onDeleteClicked} disabled={ep.IsLoading}>
															Delete Proposal
														</Button>
													</FormControl>
												</Grid>
												<Grid item={true} xs={6} className={classes.alignRight}>
													<FormControl>
														<Button
															type="submit"
															color="primary"
															variant="contained"
															disabled={ep.IsLoading}
														>
															{formprops.isSubmitting ? 'Saving...' : 'Save Changes'}
														</Button>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
						<FormDebugging />
					</Form>
				);
			}}
		</Formik>
	);
};
