import React from 'react';
import { Field } from 'formik';
import { MenuItem, FormControl, makeStyles, Theme, createStyles } from '@material-ui/core';
import { AwardStatusModels } from 'Models';
import { GlobalSelect } from './GlobalSelect';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(0),
			minWidth: 200,
		},
	})
);

export interface IAwardStatusSelectProps {
	/** The array of award statuses that will be shown as dropdown items */
	awardStatuses: AwardStatusModels.AwardStatus[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
	onChange?: (value: boolean) => void;
}

export const AwardStatusSelect = (props: IAwardStatusSelectProps) => {
	const classes = useStyles();
	return (
		<FormControl className={classes.formControl}>
			<Field
				component={(p: any) => (
					<GlobalSelect {...p} valueChanged={(v: boolean) => props.onChange && props.onChange(v)} />
				)}
				name={props.name}
				label={props.label || 'Award Status'}
				fullWidth={true}
				className={props.className}
			>
				<MenuItem key={'award-status-select-0'} value="">
					- Select -
				</MenuItem>
				{props.awardStatuses
					.sort((a, b) => ('' + a.name).localeCompare(b.name))
					.map(s => {
						return (
							<MenuItem key={`award-status-select-${s.id}`} value={s.id}>
								{s.name}
							</MenuItem>
						);
					})}
			</Field>
		</FormControl>
	);
};
