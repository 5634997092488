import { format, parse, parseISO, toDate, differenceInDays, formatDistanceToNow } from 'date-fns';

export const getDateString = (date: Date): string => {
	const defaultDateValue = '0001-01-01T00:00:00';
	const defaultDate = new Date(Date.parse(defaultDateValue));

	if (date.valueOf() === defaultDate.valueOf()) {
		return defaultDate.toDateString();
	}

	return date.toDateString();
};

/** Returns the given date in M/d/yyyy format if a format is not supplied
 * @param {Date?} date An actual data object to be formatted
 * @param {string?} dateFormat Optional date format string. Note: day and year use lowercase letters
 */
export const getShortDateString = (date: Date | undefined | null, dateFormat?: string): string => {
	if (date === undefined || date === null) {
		return '';
	}

	if (dateFormat === undefined || dateFormat === '') {
		// default to M/d/yyyy format if none is supplied
		dateFormat = 'M/d/yyyy';
	}

	// seems that values from a datepicker want toDate, but ISO dates returned from API want parseISO
	try {
		// try to parse the date if it's in ISO format
		return format(parseISO(date.toString()), dateFormat);
	} catch {
		// just swallow so things will continue
	}

	// just make into a string and parse again since some dates were not actual date objects. I have no answers
	return format(toDate(date), dateFormat);
};

/** Attempts to parse a date string in M/d/yyyy format */
export const parseShortDate = (dateString: string | undefined | null): Date | undefined => {
	if (dateString === undefined || dateString === null) {
		return undefined;
	}

	// force the string into a format that date-fns will accept
	const dateParts = dateString.split('/');
	let y = dateParts[2];
	if (y) {
		y = y.length === 2 ? '20' + y : y;
		const date = new Date(parseInt(y), parseInt(dateParts[0]) - 1, parseInt(dateParts[1]));
		dateString = getShortDateString(date);
	}

	return parse(dateString, 'M/d/yyyy', new Date());
};

export const getDateTimeString = (date: Date): string => {
	const defaultDateValue = '0001-01-01T00:00:00';
	const defaultDate = new Date(Date.parse(defaultDateValue));

	if (date.valueOf() === defaultDate.valueOf()) {
		return `${defaultDate.toDateString()} ${defaultDate.toLocaleTimeString()}`;
	}

	return `${date.toDateString()} ${date.toLocaleTimeString()}`;
};

/** calculate the difference (in days) between two dates
 * @param {Date} date1 The later date
 * @param {Date} date2 The earlier date
 */
export const calculateDaysDifference = (date1: Date, date2: Date): number => {
	date1 = parseShortDate(getShortDateString(date1)) as Date;
	return differenceInDays(toDate(date1), toDate(date2));
};

/** calculate the difference (in days) between two dates and return the percentage of time in days remaining
 * @param {Date} date1 The later date
 * @param {Date} date2 The earlier date
 */
export const calculateDateDifferencePercentage = (date1: Date, date2: Date): number => {
	const endDate = getShortDate(date1);
	const startDate = getShortDate(date2);
	const today = getShortDate(new Date());
	const fullDifference = calculateDaysDifference(endDate, startDate);
	const difference = today <= startDate ? 0 : today > endDate ? fullDifference : calculateDaysDifference(today, startDate);
	const percentage = Math.round((difference / fullDifference) * 100);

	return percentage;
}

// to be used for converting dates to ISO strings in local time.
export const toLocalISOString = (date: Date): string => {
	return date.toISOString().slice(0, -1); // cut the Z off the end so it's not in UTC time.
};

export const timeSince = (date: Date): string => {
	if (calculateDaysDifference(new Date(), date) < 7) {
		return formatDistanceToNow(date, {
			addSuffix: true,
		});
	} else {
		return getShortDateString(date);
	}
};

/** get short date
 * @param {Date} date1 The later date
 * @param {Date} date2 The earlier date
 */
export const getShortDate = (date1: Date): Date => {
	return date1 = toDate(date1);
};