import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FileModels } from 'Models';
import { TextHelper, DateHelper } from 'Common/Helpers';
import { useDropzone } from 'react-dropzone';

import {
	makeStyles,
	TextField,
	Grid,
	Button,
	Popper,
	Grow,
	Paper,
	ClickAwayListener,
	List,
	ListItem,
	Checkbox,
	ListItemIcon,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Select,
	MenuItem,
	IconButton,
	DialogContentText,
	ListItemText,
	ListItemSecondaryAction,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ProtectedComponent } from 'Common/Utilities';
import { Colors } from 'Common/Styles';
import { EditFileForm } from 'Common/Forms/Edit';
import { useFilesEndpoint } from 'Endpoints';
import { useAppState } from 'Context/AppProvider';

const useStyles = makeStyles(theme => ({
	root: {
		padding: -16,
		maxHeight: 400,
		overflowY: 'scroll',
		fontSize: '.9rem',
	},
	dropzoneRoot: {
		minHeight: 200,
		minWidth: 400,
	},
	dropzoneDrop: {
		border: '#ccc 4px dashed',
		borderRadius: 5,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	centerText: {
		textAlign: 'center',
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	uploadIcon: {
		fontSize: '3rem',
	},
	noPadding: {
		padding: 0,
	},
}));

enum FileModalOptions {
	None = 0,
	Upload = 1,
	Edit = 2,
	Delete = 3,
}

export interface IFilesCardProps {
	files: FileModels.File[];
	onFileEditSuccess: (editedFile: FileModels.File) => void;
	onFileDeleteSuccess: (fileId: number) => void;
	onFileUploadSuccess: (files: FileModels.File[]) => void;
	proposalId: number;
	allowedUsers: string[];
}

export const FilesCard = (props: IFilesCardProps) => {
	const classes = useStyles();
	const colors = Colors.useColors();

	const dropdownRef = useRef(null);
	const [isFileTypeDropdownOpen, setIsFileTypeDropdownOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [files, setFiles] = useState(props.files);
	const [filters, setFilters] = useState<FileModels.FileFilter[]>([]);
	const [openModal, setOpenModal] = useState<FileModalOptions>(FileModalOptions.None);
	const [filesForUpload, setFilesForUpload] = useState<FileModels.AddFile[]>([]);
	const [fileToDelete, setFileToDelete] = useState<FileModels.File>(new FileModels.File());
	const [fileToEdit, setFileToEdit] = useState<FileModels.EditFile>(new FileModels.EditFile());
	const [fileCategories, setFileCategories] = useState<FileModels.FileCategory[]>([]);
	const ep = useFilesEndpoint();
	const state = useAppState();

	useEffect(() => {
		ep.Categories().then(r => setFileCategories(r));
	}, []);

	useEffect(() => {
		setFiles(props.files);
	}, [props.files]);

	useEffect(() => {
		if (filters.length > 0) {
			let filteredFiles = props.files;
			const filteredFileCategories = filters.map(f => {
				if (f.key === 'fileCategoryName') return f.value;
				return false;
			});
			filteredFiles = props.files.filter(file => filteredFileCategories.includes(file.fileCategoryName));
			filters.forEach(filter => {
				if (filter.key !== 'fileCategoryName') {
					filteredFiles = filter.value
						? props.files.filter(file => file.title.toLowerCase().includes(filter.value))
						: props.files;
				}
			});
			setFiles(filteredFiles);
			return;
		}

		setFiles(props.files);
	}, [filters]);

	const closeModal = () => setOpenModal(FileModalOptions.None);

	// #region File Upload & Download

	const dropzoneAccept = [
		'image/jpeg',
		'image/png',
		'image/gif',
		'text/plain',
		'text/csv',
		'application/pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/zip',
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	];

	const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
		const reader = new FileReader();
		return new Promise<ArrayBuffer>((resolve, reject) => {
			reader.onerror = () => {
				reader.abort();
				reject(new DOMException('Problem parsing input file.'));
			};
			reader.onload = () => {
				const result = reader.result;
				if (result !== null) {
					resolve(result as ArrayBuffer);
				}
				reject(new DOMException('File contents are empty'));
			};
			reader.readAsArrayBuffer(file);
		});
	};

	const onFileDrop = useCallback(
		async (acceptedFiles: File[]) => {
			const forUpload: FileModels.AddFile[] = [];
			const uncategorized = fileCategories.find(x => x.name === 'Other');
			let defaultCategoryId = 0;
			if (uncategorized !== undefined) defaultCategoryId = uncategorized.id;

			for (const file of acceptedFiles) {
				const buffer = await readFileAsArrayBuffer(file);
				const content: Blob = new Blob([buffer]);

				const suffixIndex = file.name.lastIndexOf('.');
				const fileTitle = file.name.substring(0, suffixIndex);

				forUpload.push(
					new FileModels.AddFile({
						proposalId: props.proposalId,
						fileCategoryId: defaultCategoryId,
						username: state.CurrentUser?.guid,
						url: file.name,
						title: fileTitle,
						contents: content,
					})
				);
			}

			setFilesForUpload(filesForUpload.concat(forUpload));
		},
		[filesForUpload, fileCategories]
	);

	const cancelUpload = () => {
		closeModal();
		setFilesForUpload([]);
	};

	const uploadFiles = () => {
		ep.Add(filesForUpload).then(response => response.length && handleUploadSuccess(response));
	};

	const handleUploadSuccess = (newFiles: FileModels.File[]) => {
		closeModal();
		props.onFileUploadSuccess(newFiles);
		setFilesForUpload([]);
	};

	const { getRootProps: getDropzoneRootProps, getInputProps: getDropzoneInputProps, isDragActive } = useDropzone({
		onDrop: onFileDrop,
		accept: dropzoneAccept,
		noClick: filesForUpload.length > 0,
	});

	// #endregion

	// #region Filtering

	const closeFileTypeDropdown = () => setIsFileTypeDropdownOpen(false);
	const toggleFileTypeDropdown = () => setIsFileTypeDropdownOpen(!isFileTypeDropdownOpen);

	const applyFilter = (filter: FileModels.FileFilter) => {
		const tempFilters =
			filter.key === 'url' ? [...filters.filter(f => f.key !== 'url'), filter] : [...filters, filter];
		setFilters(tempFilters);
	};

	const removeFiltersWithKey = (key: FileModels.FilterKey) => {
		const tempFilters = filters.filter(f => f.key !== key);
		setFilters(tempFilters);
	};

	const removeFilterWithKeyValuePair = (key: FileModels.FilterKey, value: string) => {
		const tempFilters = filters.filter(f => !(f.key === key && f.value === value));
		setFilters(tempFilters);
	};

	const applySearchFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);

		event.target.value && !TextHelper.isEmptyOrWhitespace(event.target.value)
			? applyFilter(new FileModels.FileFilter({ key: 'url', value: event.target.value }))
			: removeFiltersWithKey('url');
	};

	const handleFileCategoryCheckboxClick = (categoryName: string) => (
		event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>
	) => {
		let exists = false;
		for (const f of filters) {
			if (f.key === 'fileCategoryName' && f.value === categoryName) {
				exists = true;
				break;
			}
		}

		if (exists) {
			removeFilterWithKeyValuePair('fileCategoryName', categoryName);
			return;
		}

		const filter = new FileModels.FileFilter({
			key: 'fileCategoryName',
			value: categoryName,
		});

		applyFilter(filter);
	};

	// #endregion

	// #region File Delete & Edit

	const handleDeleteClick = (fileId: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
		const file = props.files.find(x => x.id === fileId);

		if (file === undefined) {
			return;
		}

		setFileToDelete(file);
		setOpenModal(FileModalOptions.Delete);
	};

	const onFileDeleteConfirm = async () => {
		const deletedId = await ep.Delete(fileToDelete.id);
		if (deletedId === fileToDelete.id) props.onFileDeleteSuccess(deletedId);
		setFileToDelete(new FileModels.File());
		closeModal();
	};

	const onFileDeleteCancel = () => {
		setFileToDelete(new FileModels.File());
		closeModal();
	};

	const handleEditClick = (id: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
		const file = props.files.find(x => x.id === id);

		if (file === undefined) {
			return;
		}

		setFileToEdit(new FileModels.EditFile({ ...file }));
		setOpenModal(FileModalOptions.Edit);
	};

	const onFileEditSuccess = async (file: FileModels.File) => {
		if (file.id !== 0) props.onFileEditSuccess(file);
		setFileToEdit(new FileModels.EditFile());
		closeModal();
	};

	const onFileEditCancel = () => {
		setFileToEdit(new FileModels.EditFile());
		closeModal();
	};

	// #endregion

	return (
		<Grid container={true} spacing={1}>
			<Grid container={true} direction="row" justify="space-between">
				<Grid item={true}>
					<ProtectedComponent allowedRoles={['superUser', 'admin']} allowedUsers={props.allowedUsers}>
						<Button
							variant="contained"
							color="primary"
							size="small"
							aria-label="upload file"
							onClick={() => setOpenModal(FileModalOptions.Upload)}
						>
							<CloudUploadIcon className={classes.buttonIcon} /> Upload
						</Button>
					</ProtectedComponent>
				</Grid>
				<Grid item={true}>
					<TextField value={searchValue} placeholder="search file name" onChange={applySearchFilter} />
					<Button
						variant="contained"
						size="small"
						aria-label="filter files"
						ref={dropdownRef}
						onClick={toggleFileTypeDropdown}
					>
						File Types <ArrowDropDownIcon />
					</Button>
					<Popper
						open={isFileTypeDropdownOpen}
						anchorEl={dropdownRef.current}
						placement="bottom-start"
						transition={true}
						disablePortal={true}
						style={{
							maxHeight: '50%',
							overflowY: 'scroll',
							zIndex: 99999,
						}}
					>
						{({ TransitionProps }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: 'top left',
								}}
							>
								<Paper>
									<ClickAwayListener onClickAway={closeFileTypeDropdown}>
										<List dense={true}>
											{fileCategories
												.sort((a, b) => a.name.localeCompare(b.name))
												.map(category => {
													let isIncluded = false;
													for (const filter of filters) {
														if (
															filter.key === 'fileCategoryName' &&
															filter.value === category.name
														) {
															isIncluded = true;
															break;
														}
													}
													return (
														<ListItem
															key={`filecat-${category.id}`}
															button={true}
															onClick={handleFileCategoryCheckboxClick(category.name)}
														>
															<ListItemIcon>
																<Checkbox
																	edge="start"
																	checked={isIncluded}
																	disableRipple={true}
																	className={classes.noPadding}
																/>
															</ListItemIcon>
															{category.name}
														</ListItem>
													);
												})}
										</List>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Grid>
			</Grid>
			<Grid container={true} item={true} direction="column" xs={12} className={classes.root}>
				<List dense={true}>
					{files
						.sort((a, b) => b.id - a.id)
						.map(file => (
							<ListItem key={`file-${file.id}`}>
								<ListItemText
									primary={file.title}
									secondary={
										<>
											<Typography
												component="span"
												variant="body2"
												color="textPrimary"
												style={{ display: 'block' }}
											>
												{file.description}
											</Typography>
											{`${file.fileCategoryName} ${DateHelper.timeSince(file.submittedAt)}`}
										</>
									}
								/>

								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="download-file"
										onClick={() => ep.Download(file.id)}
									>
										<GetAppIcon />
									</IconButton>
									<ProtectedComponent
										allowedRoles={['superUser', 'admin']}
										allowedUsers={props.allowedUsers}
									>
										<IconButton
											edge="end"
											aria-label="edit-file"
											onClick={handleEditClick(file.id)}
										>
											<EditIcon className={colors.warning} />
										</IconButton>
										<IconButton
											edge="end"
											aria-label="delete-file"
											onClick={handleDeleteClick(file.id)}
										>
											<DeleteIcon className={colors.error} />
										</IconButton>
									</ProtectedComponent>
								</ListItemSecondaryAction>
							</ListItem>
						))}
				</List>
			</Grid>

			<Dialog
				maxWidth="md"
				open={openModal === FileModalOptions.Upload}
				onClose={closeModal}
				aria-labelledby="upload-form-dialog-title"
			>
				<DialogTitle id="upload-form-dialog-title">Upload Files</DialogTitle>
				<DialogContent>
					<Grid container={true}>
						<Grid container={true} item={true} xs={12}>
							<div {...getDropzoneRootProps({ className: classes.dropzoneRoot })}>
								<input aria-label="dropzone-input" {...getDropzoneInputProps()} />
								<div className={classes.centerText}>
									{isDragActive ? (
										<div className={classes.dropzoneDrop}>
											<Typography variant="h4">Bombs Away!</Typography>
											<CloudUploadIcon
												fontSize="large"
												color="secondary"
												className={classes.uploadIcon}
											/>
										</div>
									) : filesForUpload.length > 0 ? (
										''
									) : (
										<div className={classes.dropzoneDrop}>
											<Typography variant="h4">Drag and drop files here</Typography>
											<br />
											<Typography variant="h6" color="textSecondary">
												(or click to select files)
											</Typography>
											<CloudUploadIcon
												fontSize="large"
												color="secondary"
												className={classes.uploadIcon}
											/>
										</div>
									)}
								</div>
								{filesForUpload.length > 0 ? (
									<Grid container={true} item={true} xs={12}>
										{filesForUpload.map(file => (
											<Grid
												key={`${file.url}-uploadform`}
												container={true}
												justify="space-between"
												item={true}
												xs={12}
											>
												<Grid item={true}>
													<Typography variant="body1">{file.url}</Typography>
												</Grid>
												<Grid item={true}>
													<Select
														value={file.fileCategoryId}
														onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
															const newValues = new FileModels.AddFile({
																...file,
																fileCategoryId: parseInt(event.target.value, 10),
															});
															const index = filesForUpload
																.map(x => x.url)
																.indexOf(file.url);
															const newArray = [...filesForUpload];
															newArray.splice(index, 1, newValues);
															setFilesForUpload(newArray);
														}}
														placeholder="File Category"
													>
														{fileCategories.map(cat => (
															<MenuItem key={`filecat-${cat.id}`} value={cat.id}>
																{cat.name}
															</MenuItem>
														))}
													</Select>
													<IconButton
														onClick={() => {
															setFilesForUpload([
																...filesForUpload.filter(x => x.url !== file.url),
															]);
														}}
													>
														<ClearIcon className={colors.error} />
													</IconButton>
												</Grid>
											</Grid>
										))}
									</Grid>
								) : (
									''
								)}
							</div>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={cancelUpload}>
						Cancel
					</Button>
					<Button color="primary" onClick={uploadFiles}>
						Upload
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				maxWidth="xs"
				open={openModal === FileModalOptions.Delete}
				onClose={onFileDeleteCancel}
				aria-labelledby="delete-form-dialog-title"
			>
				<DialogTitle id="delete-form-dialog-title">Delete File</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete <strong>{fileToDelete.title}</strong>?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onFileDeleteCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={onFileDeleteConfirm} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				maxWidth="xs"
				open={openModal === FileModalOptions.Edit}
				onClose={onFileEditCancel}
				aria-labelledby="edit-form-dialog-title"
			>
				<DialogTitle id="edit-form-dialog-title">Edit {fileToEdit.title}</DialogTitle>
				<DialogContent>
					<EditFileForm file={fileToEdit} onSuccess={onFileEditSuccess} />
				</DialogContent>
			</Dialog>
		</Grid>
	);
};
