import React from 'react';
import { MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import { FileModels } from 'Models';
import { TextField } from 'formik-material-ui';

export interface IFileCategorySelectProps {
	/** The array of file categories that will be shown as dropdown items */
	fileCategories: FileModels.FileCategory[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
}

export const FileCategorySelect = (props: IFileCategorySelectProps) => {
	return (
		<Field
			component={TextField}
			name={props.name}
			select={true}
			label={props.label || 'File Category'}
			fullWidth={true}
			className={props.className}
		>
			{props.fileCategories
				.sort((a, b) => ('' + a.name).localeCompare(b.name))
				.map(f => {
					return (
						<MenuItem key={`file-category-select-${f.id}`} value={f.id}>
							{f.name}
						</MenuItem>
					);
				})}
		</Field>
	);
};
