import React from 'react';
import * as Sentry from '@sentry/browser';
import { Grid, Card, CardContent, Typography, CardActions, Button } from '@material-ui/core';

export interface IGlobalErrorBoundaryProps {}

interface State {
	hasError: boolean;
	eventId?: string;
}

export class GlobalErrorBoundary extends React.Component<IGlobalErrorBoundaryProps, State> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false, eventId: undefined };
	}

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, eventId: undefined };
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		Sentry.withScope(scope => {
			scope.setExtras(info);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<Grid container={true}>
					<Grid item={true} xs={12} sm={6} md={4}>
						&nbsp;
					</Grid>
					<Grid item={true} xs={12} sm={6} md={4}>
						<Card>
							<CardContent>
								<Typography gutterBottom={true} variant="h5" component="h2">
									Error
								</Typography>
								<Typography variant="body2" color="textSecondary" component="p">
									An error occurred that caused the application to crash. This has been recorded and
									the team will be notified. If you'd like, you can report feedback about what you
									were doing when the application crashed. This will help us diagnose problems.
								</Typography>
							</CardContent>

							<CardActions>
								<a href="/">
									<Button size="small" color="primary">
										Reload Application
									</Button>
								</a>
								<Button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
									Report feedback
								</Button>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			);
		}

		return this.props.children;
	}
}


