import React, { useState, useEffect } from 'react';
import {
	Grid,
	FormControl,
	makeStyles,
	Theme,
	TextField,
	FormControlLabel,
	Checkbox,
	FormHelperText,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ProposalModels } from 'Models';
import { DisplayField } from 'Common/Elements';
import { DateHelper } from 'Common/Helpers';
import deepEqual from 'deep-equal';
import { FormikErrors } from 'formik';

// props to be provided by parent component
// this is the components external interface
export interface IAwardedCompletedProposalCardProps {
	awardedProposal?: ProposalModels.AwardedProposal;
	editable: boolean;
	onChange?: (awardedProposal: ProposalModels.AwardedProposal) => void;
	errors?: FormikErrors<ProposalModels.AwardedProposal>;
}

const useStyles = makeStyles((theme: Theme) => ({
	formControl: {
		marginLeft: '0',
		marginRight: '0',
		maxWidth: '100%',
		padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
		width: '100%',
	},
	formField: {
		marginLeft: '0',
		marginRight: '0',
	},
}));

export const AwardedCompletedProposalCard = (props: IAwardedCompletedProposalCardProps) => {
	const classes = useStyles();
	const origAwardedProposal = props.awardedProposal || new ProposalModels.AwardedProposal();
	const [awardedProposal, setAwardedProposal] = useState(origAwardedProposal);

	const somethingChanged = () => {
		const areEqual = deepEqual(origAwardedProposal, awardedProposal);
		return !areEqual;
	};

	useEffect(() => {
		if (somethingChanged()) {
			props.onChange && props.onChange(awardedProposal);
		}
	}, [awardedProposal]);

	useEffect(() => {
		if (somethingChanged()) {
			setAwardedProposal(origAwardedProposal);
		}
	}, [props.awardedProposal]);

	return (
		<Grid container={true}>
			{props.editable ? (
				<React.Fragment>
					<Grid container={true}>
						<Grid item={true} xs={6}>
							<FormControl className={classes.formControl}>
								<KeyboardDatePicker
									showTodayButton={true}
									id="date-picker-awarded-on"
									label="Awarded On"
									format="MM/dd/yyyy"
									value={awardedProposal.awardedOn}
									onChange={(date: Date) =>
										setAwardedProposal({ ...awardedProposal, awardedOn: date })
									}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									error={props.errors && !!props.errors.awardedOn}
									helperText={props.errors && props.errors.awardedOn}
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<TextField
									label="Account Number"
									fullWidth={true}
									value={awardedProposal.accountNumber || ''}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
										setAwardedProposal({ ...awardedProposal, accountNumber: event.target.value })
									}
									margin="normal"
									className={classes.formField}
									error={props.errors && !!props.errors.accountNumber}
									helperText={props.errors && props.errors.accountNumber}
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<TextField
									label="Agency Number"
									fullWidth={true}
									value={awardedProposal.agencyNumber || ''}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
										setAwardedProposal({ ...awardedProposal, agencyNumber: event.target.value })
									}
									margin="normal"
									className={classes.formField}
									error={props.errors && !!props.errors.agencyNumber}
									helperText={props.errors && props.errors.agencyNumber}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} xs={6}>
							<FormControl
								className={classes.formControl}
								error={props.errors && !!props.errors.isExpirationDismissed}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={awardedProposal.isExpirationDismissed || false}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
												setAwardedProposal({
													...awardedProposal,
													isExpirationDismissed: event.currentTarget.checked,
												});
											}}
											value={awardedProposal.isExpirationDismissed}
											color="primary"
										/>
									}
									label="Project End Acknowledged"
								/>
								<FormHelperText>{props.errors && props.errors.isExpirationDismissed}</FormHelperText>
							</FormControl>
							<FormControl
								className={classes.formControl}
								error={props.errors && !!props.errors.isExtensionFiled}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={awardedProposal.isExtensionFiled || false}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
												setAwardedProposal({
													...awardedProposal,
													isExtensionFiled: event.currentTarget.checked,
												});
											}}
											value={awardedProposal.isExtensionFiled}
											color="primary"
										/>
									}
									label="Is Extension Filed?"
								/>
								<FormHelperText>{props.errors && props.errors.isExtensionFiled}</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Grid container={true}>
						<Grid item={true} xs={6}>
							<DisplayField
								label="Awardedon On"
								value={DateHelper.getShortDateString(awardedProposal.awardedOn)}
							/>
							<DisplayField label="Account Number" value={awardedProposal.accountNumber || ''} />
							<DisplayField label="Agency Number" value={awardedProposal.agencyNumber || ''} />
						</Grid>
						<Grid item={true} xs={6}>
							<DisplayField
								label="Project End Acknowledged?"
								value={awardedProposal.isExpirationDismissed ? 'Yes' : 'No'}
							/>
							<DisplayField
								label="Is Extension Filed?"
								value={awardedProposal.isExtensionFiled ? 'Yes' : 'No'}
							/>
						</Grid>
					</Grid>
				</React.Fragment>
			)}
		</Grid>
	);
};
