import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { formStyles } from '../styles';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormControl, Button, LinearProgress } from '@material-ui/core';
import { InstituteSelect, AgencySelect, FundingTypeSelect, OrganizationSelect, FormDebugging } from '../Fields';
import {
	useProposalsEndpoint,
	useInstitutesEndpoint,
	useAgenciesEndpoint,
	useFundingTypesEndpoint,
	useOrganizationsEndpoint,
} from 'Endpoints';
import { Institute } from 'Models/InstituteModels';
import { FundingType } from 'Models/FundingTypeModels';
import { Organization } from 'Models/OrganizationModels';
import { AddProposal } from 'Models/ProposalModels';
import { Agency } from 'Models/AgencyModels';
import { AddProposalSchema } from '../ValidationSchemas/ProposalSchema';

// props to be provided by parent component
// this is the components external interface
export interface IAddProposalFormProps {
	onSuccess: () => void;
}

export const AddProposalForm = (props: IAddProposalFormProps & RouteComponentProps<any>) => {
	const ep = useProposalsEndpoint();
	const instituteEp = useInstitutesEndpoint();
	const agencyEp = useAgenciesEndpoint();
	const fundingEp = useFundingTypesEndpoint();
	const orgEp = useOrganizationsEndpoint();

	const [institutes, setInstitutes] = useState<Institute[]>([]);
	const [agencies, setAgencies] = useState<Agency[]>([]);
	const [fundingTypes, setFundingTypes] = useState<FundingType[]>([]);
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	useEffect(() => {
		instituteEp.Get().then(r => setInstitutes(r));
		agencyEp.Get().then(r => setAgencies(r));
		fundingEp.Get().then(r => setFundingTypes(r));
		orgEp.Get().then(r => setOrganizations(r));
	}, []);

	const classes = formStyles();
	const nameof = useNameof<AddProposal>();

	return (
		<Formik
			initialValues={new AddProposal()}
			validationSchema={AddProposalSchema}
			onSubmit={(values, { setSubmitting }) => {
				// add the Proposal and clear the form if that succeeds
				ep.Add(values)
					.then(result => {
						if (result) {
							props.onSuccess();
							props.history.push(`/proposals/${result}`);
						}
					})
					.finally(() => setSubmitting(false));
			}}
		>
			{formprops => (
				<Form className={classes.flexForm}>
					<FormControl className={classes.wideControl}>
						<Field
							label="Proposal Number"
							fullWidth={true}
							type="text"
							name={nameof('proposalNumber')}
							component={TextField}
						/>
					</FormControl>
					<FormControl className={classes.wideControl}>
						<Field
							required={true}
							label="Short Name"
							fullWidth={true}
							type="text"
							name={nameof('shortName')}
							component={TextField}
						/>
					</FormControl>
					<FormControl className={classes.wideControl}>
						<InstituteSelect institutes={institutes} name={nameof('instituteId')} />
					</FormControl>
					<FormControl className={classes.wideControl}>
						<AgencySelect agencies={agencies} name={nameof('leadAgencyId')} />
					</FormControl>
					<FormControl className={classes.wideControl}>
						<FundingTypeSelect fundingTypes={fundingTypes} name={nameof('fundingTypeId')} />
					</FormControl>
					<FormControl className={classes.wideControl}>
						<OrganizationSelect
							label="Funding Source"
							organizations={organizations}
							name={nameof('fundingSourceId')}
							setField={true}
							multiple={false}
						/>
					</FormControl>
					<FormControl className={classes.wideControl}>
						{formprops.isSubmitting && <LinearProgress />}
						<Button
							type="submit"
							color="primary"
							variant="contained"
							disabled={formprops.isSubmitting}
							onSubmit={formprops.submitForm}
						>
							{formprops.isSubmitting ? 'Saving...' : 'Add'}
						</Button>
					</FormControl>
					<FormDebugging />
				</Form>
			)}
		</Formik>
	);
};

export default withRouter(AddProposalForm);
