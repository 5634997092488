/** used when getting people, and the model sent from the API when editing a person */
export class Person {
	id: number = 0;
	fullName: string = '';
	firstName: string = '';
	lastName: string = '';
	username: string = '';
	description: string = '';
	isActive: boolean = false;
}

export class AddPerson {
	firstName: string = '';
	lastName: string = '';
	username: string = '';
	description: string = '';
}

export class EditPerson {
	id: number;
	firstName: string;
	lastName: string;
	username: string;
	description: string;

	constructor(
		id: number,
		firstName: string = '',
		lastName: string = '',
		username: string = '',
		description: string = ''
	) {
		this.id = id;
		this.firstName = firstName;
		this.lastName = lastName;
		this.username = username;
		this.description = description;
	}
}
