import React, { useState, useEffect } from 'react';
import { Grid, FormControl, makeStyles, Theme, TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ProposalModels } from 'Models';
import { DisplayField } from 'Common/Elements/DisplayField';
import { DateHelper } from 'Common/Helpers';
import deepEqual from 'deep-equal';
import { FormikErrors } from 'formik';

// props to be provided by parent component
// this is the components external interface
export interface IWithdrawnProposalCardProps {
	withdrawnProposal?: ProposalModels.WithdrawnProposal;
	editable: boolean;
	onChange?: (withdrawnProposal: ProposalModels.WithdrawnProposal) => void;
	errors?: FormikErrors<ProposalModels.WithdrawnProposal>;
}

const useStyles = makeStyles((theme: Theme) => ({
	formControl: {
		marginLeft: '0',
		marginRight: '0',
		maxWidth: '100%',
		padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
		width: '100%',
	},
	formField: {
		marginLeft: '0',
		marginRight: '0',
	},
}));

export const WithdrawnProposalCard = (props: IWithdrawnProposalCardProps) => {
	const classes = useStyles();
	const origWithdrawnProposal = props.withdrawnProposal || new ProposalModels.WithdrawnProposal();
	const [withdrawnProposal, setWithdrawnProposal] = useState(origWithdrawnProposal);

	const somethingChanged = () => {
		const areEqual = deepEqual(origWithdrawnProposal, withdrawnProposal);
		return !areEqual;
	};

	useEffect(() => {
		if (somethingChanged()) {
			props.onChange && props.onChange(withdrawnProposal);
		}
	}, [withdrawnProposal]);

	useEffect(() => {
		if (somethingChanged()) {
			setWithdrawnProposal(origWithdrawnProposal);
		}
	}, [props.withdrawnProposal]);

	return (
		<Grid container={true}>
			<Grid item={true} xs={6}>
				{props.editable ? (
					<React.Fragment>
						<FormControl className={classes.formControl}>
							<KeyboardDatePicker
								showTodayButton={true}
								id="date-picker-awarded-on"
								label="Withdrawn On"
								format="MM/dd/yyyy"
								value={withdrawnProposal.withdrawnOn}
								onChange={(date: Date) =>
									setWithdrawnProposal({ ...withdrawnProposal, withdrawnOn: date })
								}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								error={props.errors && !!props.errors.withdrawnOn}
								helperText={props.errors && props.errors.withdrawnOn}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								label="Note"
								fullWidth={true}
								value={withdrawnProposal.note || ''}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									setWithdrawnProposal({ ...withdrawnProposal, note: event.target.value })
								}
								margin="normal"
								className={classes.formField}
								error={props.errors && !!props.errors.note}
								helperText={props.errors && props.errors.note}
							/>
						</FormControl>
					</React.Fragment>
				) : (
					<React.Fragment>
						<DisplayField
							label="Withdrawn On"
							value={DateHelper.getShortDateString(withdrawnProposal.withdrawnOn)}
						/>
						<DisplayField label="Note" value={withdrawnProposal.note || ''} />
					</React.Fragment>
				)}
			</Grid>
		</Grid>
	);
};
