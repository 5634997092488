/** for showing support accounts in the support account table, and model returned from add/edit commands */
export class SupportAccount {
	proposalId: number = 0;
	organizationName: string = '';
	organizationId: number = 0;
	accountNumber: string = '';
	deptPI: string = '';
	bookkeeper: string = '';
	amount: number = 0;
	matchAmount: number = 0;
}

/** for adding a new support account */
export class AddSupportAccount {
	organizationId: number = 0;
	accountNumber: string = '';
	deptPI: string = '';
	bookkeeper: string = '';
	amount: number = 0;
	matchAmount: number = 0;
	proposalId: number;

	constructor(proposalId: number) {
		this.proposalId = proposalId;
	}
}

/** for editing an existing support account */
export class EditSupportAccount {
	proposalId: number;
	organizationId: number;
	accountNumber: string;
	deptPI: string;
	bookkeeper: string;
	amount: number;
	matchAmount: number;

	constructor(
		proposalId: number,
		organizationId: number = 0,
		accountNumber: string = '',
		deptPI: string = '',
		bookkeeper: string = '',
		amount: number = 0,
		matchAmount: number = 0
	) {
		this.proposalId = proposalId;
		this.organizationId = organizationId;
		this.accountNumber = accountNumber;
		this.deptPI = deptPI;
		this.bookkeeper = bookkeeper;
		this.amount = amount;
		this.matchAmount = matchAmount;
	}
}

export class DeleteSupportAccount {
	proposalId: number;
	organizationId: number;
	accountNumber: string;

	constructor(proposalId: number, organizationId: number, accountNumber: string) {
		this.proposalId = proposalId;
		this.organizationId = organizationId;
		this.accountNumber = accountNumber;
	}
}
