/** for showing costs in the subcontract table */
export class Cost {
	id: number = 0;
	startsOn?: Date;
	endsOn?: Date;
	indirectCostRate?: number;
	indirectCostTotal?: number;
	directCosts?: number;
	directCostMatch?: number;
	indirectCostMatch?: number;
	unrecoveredIndirectCost?: number;
	areFundsSpent?: boolean;
}

/** for adding a new cost */
export class AddCost {
	startsOn?: Date = new Date();
	endsOn?: Date = new Date();
	indirectCostRate?: number;
	indirectCostTotal?: number;
	directCosts?: number;
	directCostMatch?: number;
	indirectCostMatch?: number;
	unrecoveredIndirectCost?: number;
	proposalId: number = 0;

	constructor(proposalId: number) {
		this.proposalId = proposalId;
	}
}

/** for editing an existing cost */
export class EditCost {
	id: number;
	startsOn?: Date;
	endsOn?: Date;
	indirectCostRate?: number;
	indirectCostTotal?: number;
	directCosts?: number;
	directCostMatch?: number;
	indirectCostMatch?: number;
	unrecoveredIndirectCost?: number;
	proposalId: number = 0;

	constructor(
		id: number,
		startsOn: Date = new Date(),
		endsOn: Date = new Date(),
		indirectCostRate: number = 0,
		indirectCostTotal: number = 0,
		directCosts: number = 0,
		directCostMatch: number = 0,
		indirectCostMatch: number = 0,
		unrecoveredIndirectCost: number = 0,
		proposalId: number = 0
	) {
		this.id = id;
		this.startsOn = startsOn;
		this.endsOn = endsOn;
		this.indirectCostRate = indirectCostRate;
		this.indirectCostTotal = indirectCostTotal;
		this.directCosts = directCosts;
		this.directCostMatch = directCostMatch;
		this.indirectCostMatch = indirectCostMatch;
		this.unrecoveredIndirectCost = unrecoveredIndirectCost;
		this.proposalId = proposalId;
	}
}
