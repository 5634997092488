import {
	Avatar,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Theme,
	Typography,
} from '@material-ui/core';
import { Colors } from 'Common/Styles';
import { Team } from 'Models/TeamModels';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { teal } from '@material-ui/core/colors/';
import DeleteIcon from '@material-ui/icons/Delete';
import { AddTeamAssignmentForm } from 'Common/Forms/Add';
import AddIcon from '@material-ui/icons/Add';

export interface ITeamsCardProps {
	proposalId: number;
	teams: Team[];
	editable: boolean;
	onAddSuccess?: (team: Team) => void;
	onDeleteClick?: (teamId: number) => void;
}

enum ModalOptions {
	None = 0,
	Add = 1,
}

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		marginBottom: theme.spacing(2),
	},
	heading: {
		marginBottom: theme.spacing(0.5),
		color: theme.palette.text.secondary,
		textTransform: 'uppercase',
		fontSize: '14px',
	},
	header: {
		marginBottom: theme.spacing(0.5),
		color: theme.palette.text.secondary,
		textTransform: 'uppercase',
		fontSize: '16px',
	},
	details: {
		display: 'flex',
		alignItems: 'center',
	},
	detailsButtons: {
		marginLeft: 'auto',
	},
	avatar: {
		marginRight: theme.spacing(1.5),
	},
	avatarPrimary: {
		backgroundColor: teal[500],
	},
	name: {
		color: theme.palette.text.primary,
		fontSize: '16px',
		lineHeight: '1.25',
	},
	textRight: {
		textAlign: 'right',
	},
}));

const TeamBlock = (props: { team: Team; editable: boolean; onDeleteClick: (teamId: number) => void }) => {
	const colors = Colors.useColors();
	const classes = useStyles();

	const initials = props.team.name
		? props.team.name
			.split(/[\s/]+/)
			.map(name => name.charAt(0))
			.join('')
		: '';

	return (
		<Grid item={true} xs={12}>
			<Grid item={true} className={classes.container}>
				<Grid className={classes.details} item={true}>
					<Grid>
						<Avatar className={`${classes.avatar} ${classes.avatarPrimary}`}>{initials}</Avatar>
					</Grid>
					<Grid item={true}>
						<Typography component="p" className={classes.name}>
							{props.team.name}
						</Typography>
					</Grid>
					<Grid className={classes.detailsButtons} item={true}>
						{props.editable && (
							<IconButton
								edge="end"
								aria-label="delete"
								onClick={() => props.onDeleteClick(props.team.id)}
							>
								<DeleteIcon className={colors.error} />
							</IconButton>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const TeamsCard = (props: ITeamsCardProps) => {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState<ModalOptions>(ModalOptions.None);

	const closeModal = () => setOpenModal(ModalOptions.None);

	const handleAddSuccess = (team: Team) => {
		closeModal();
		props.onAddSuccess && props.onAddSuccess(team);
	};

	return (
		<React.Fragment>
			<Grid container={true}>
				{(props.editable || props.teams.length) ? (
					<Typography component="h2" className={classes.header}>
						Teams
					</Typography>
				) : ''}
				{props.editable && (
					<Grid item={true} sm={12} className={classes.textRight}>
						<Button
							startIcon={<AddIcon />}
							color="primary"
							variant="contained"
							onClick={() => setOpenModal(ModalOptions.Add)}
						>
							Add Team
						</Button>
					</Grid>
				)}
				{props.teams
					.sort((a, b) => a.name.localeCompare(b.name))
					.map(t => (
						<TeamBlock
							key={t.id}
							team={t}
							editable={props.editable}
							onDeleteClick={teamId => props.onDeleteClick && props.onDeleteClick(teamId)}
						/>
					))}
			</Grid>

			<Dialog
				maxWidth="sm"
				open={openModal === ModalOptions.Add}
				onClose={closeModal}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Add Team Assignment</DialogTitle>
				<DialogContent>
					<AddTeamAssignmentForm proposalId={props.proposalId} onSuccess={handleAddSuccess} />
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};

export default TeamsCard;
