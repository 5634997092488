import React from 'react';
import { MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import { OrganizationModels } from 'Models';
import { TextField } from 'formik-material-ui';

export interface IOrganizationTypeSelectProps {
	/** The array of organization types that will be shown as dropdown items */
	organizationTypes: OrganizationModels.OrganizationType[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
}

export const OrganizationTypeSelect = (props: IOrganizationTypeSelectProps) => {
	return (
		<Field
			component={TextField}
			name={props.name}
			select={true}
			label={props.label || 'Organization Type'}
			fullWidth={true}
			className={props.className}
		>
			<MenuItem key={'organization-type-select-0'} value="0">
				- Select -
			</MenuItem>
			{props.organizationTypes
				.sort((a, b) => ('' + a.name).localeCompare(b.name))
				.map(f => {
					return (
						<MenuItem key={`organization-type-select-${f.id}`} value={f.id}>
							{f.name}
						</MenuItem>
					);
				})}
		</Field>
	);
};
