import React, { useEffect, useState } from 'react';
import { CalendarEvent } from 'Models/CalendarModels';
import { DOMHelper } from 'Common/Helpers';
import classnames from 'classnames';

import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Fade, Grid, Typography, makeStyles } from '@material-ui/core';
import { StyledCard } from 'Common/Cards';
import { useCalendarEndpoint } from 'Endpoints';

const useStyles = makeStyles(() => ({
	legend: {
		margin: 6,
	},
	green: {
		backgroundColor: 'rgba(135, 203, 22, 0.2)',
	},
	blue: {
		backgroundColor: 'rgba(68, 125, 247, 0.2);',
	},
}));

export const Calendar = () => {
	const localizer = momentLocalizer(moment);
	const classes = useStyles();
	const ep = useCalendarEndpoint();
	const [events, setEvents] = useState<CalendarEvent[]>([]);

	useEffect(() => {
		DOMHelper.setPageTitle('Calendar');
		ep.Get().then(r => setEvents(r));
	}, []);

	return (
		<Fade in={!events.length}>
			<React.Fragment>
				<BigCalendar
					onNavigate={(date?: Date) => ep.Get(date).then(r => setEvents(r))}
					localizer={localizer}
					selectable={true}
					popup={true}
					events={events}
					defaultView="month"
					scrollToTime={new Date(1970, 1, 1, 6)}
					defaultDate={new Date()}
					startAccessor="occursOn"
					endAccessor="occursOn"
					views={['month', 'agenda']}
					eventPropGetter={event => ({
						className: 'category-' + event.type.toLowerCase(),
					})}
				/>
				<Grid container={true}>
					<Grid item={true} md={3}>
						<StyledCard title="Legend">
							<Grid container={true}>
								<Grid item={true} xs={1} className={classnames([classes.green, classes.legend])} />
								<Grid item={true} xs={10}>
									<Typography component="p" variant="h6">
										Proposal
									</Typography>
								</Grid>
								<Grid item={true} xs={1} className={classnames([classes.blue, classes.legend])} />
								<Grid item={true} xs={10}>
									<Typography component="p" variant="h6">
										Subcontract
									</Typography>
								</Grid>
							</Grid>
						</StyledCard>
					</Grid>
				</Grid>
			</React.Fragment>
		</Fade>
	);
};
