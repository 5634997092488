import React, { useState } from 'react';
import { Note, AddNote } from 'Models/NoteModels';
import classnames from 'classnames';
import { DateHelper } from 'Common/Helpers';
import { useNameof } from 'Common/Helpers/ReactHelper';

import { makeStyles, TextField, Button, Grid } from '@material-ui/core';
import {ProtectedComponent} from 'Common/Utilities';
import { useNotesEndpoint } from 'Endpoints';
import { useAppState } from 'Context/AppProvider';

export interface INotesCardProps {
	notes: Note[];
	// explicitly pass in proposal id since a proposal with no notes can't be relied upon to get its id
	proposalId: number;
	onSuccess: (note: Note) => void;
}

const useStyles = makeStyles(theme => ({
	root: {
		maxHeight: 400,
		overflowY: 'scroll',
		fontSize: '.9rem',
	},
	note: {
		marginBottom: theme.spacing(1),
	},
	noteBody: {
		padding: theme.spacing(1),
		borderRadius: theme.spacing(1),
		margin: 4,
	},
	myNoteBody: {
		backgroundColor: '#55A1C1',
		color: 'white',
	},
	otherNoteBody: {
		backgroundColor: 'rgba(56, 54, 54, 0.062)',
		color: 'black',
	},
	timestamp: {
		fontSize: '.8rem',
		color: '#6c757d',
	},
	alignRight: {
		textAlign: 'right',
	},
}));

export const NotesCard = (props: INotesCardProps) => {
	const classes = useStyles();
	const [note, setNote] = useState(new AddNote(props.proposalId));
	const nameof = useNameof<AddNote>();
const ep = useNotesEndpoint();
const state = useAppState();

	const handleChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setNote({ ...note, [key]: event.target.value });
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		// add the note and clear the form if that succeeds
		ep.Add(note).then(response => response && handleSuccess(response));
	};

	const handleSuccess = (n: Note) => {
		setNote(new AddNote(props.proposalId));
		props.onSuccess(n);
	};

	return (
		<React.Fragment>
			<div className={classes.root}>
				{props.notes.map(n => {
					const isMyNote = n.username.toLowerCase() === state.CurrentUser?.preferredUsername.toLowerCase();
					return (
						<div key={n.id} className={classes.note}>
							<div className={classnames({ [classes.alignRight]: !isMyNote })}>{n.displayName}</div>
							<div
								className={classnames(classes.noteBody, {
									[classes.myNoteBody]: isMyNote,
									[classes.otherNoteBody]: !isMyNote,
								})}
							>
								{n.text}
							</div>
							<div className={classnames(classes.timestamp, classes.alignRight)}>
								{DateHelper.getShortDateString(n.submittedAt, 'MMM d, yyyy h:mm aaaa')}
							</div>
						</div>
					);
				})}
			</div>
			<ProtectedComponent allowedRoles={['superUser', 'admin']}>
				<hr />
				<form onSubmit={handleSubmit}>
					<Grid container={true}>
						<Grid item={true} md={9}>
							<TextField
								required={true}
								label="Add a note..."
								fullWidth={true}
								value={note.text}
								onChange={handleChange(nameof('text'))}
							/>
						</Grid>
						<Grid item={true} md={3}>
							<Button
								style={{ marginTop: 4, marginLeft: 4 }}
								type="submit"
								color="primary"
								variant="contained"								
							>
								Add
							</Button>
						</Grid>
					</Grid>
				</form>
			</ProtectedComponent>
		</React.Fragment>
	);
};

