import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { File, FileCategory, AddFile, EditFile } from 'Models/FileModels';

export const useFilesEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('files');

	const Download = (id: number) =>
		ep.Download(id).catch(() => {
			useError('Error getting file contents');
			return undefined;
		});

	const Add = async (files: AddFile[]) => {
		const uploaded: File[] = [];

		for (const f of files) {
			const response = await ep
				.Post<File>(f.toFormData())

				.catch(() => {
					useError('Error adding file');
					return undefined;
				});
			response && uploaded.push(response);
		}
		return uploaded;
	};

	const Edit = (dto: EditFile) =>
		ep
			.Put<File>(dto.id, dto)
			.then(r => {
				useSuccess('File saved');
				return r;
			})
			.catch(() => {
				useError('Error saving file');
				return undefined;
			});

	const Delete = (id: number) =>
		ep
			.Delete<number>(id)
			.then(r => {
				useSuccess('File deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting file');
				return undefined;
			});

	const Categories = () =>
		ep.Get<FileCategory[]>('categories').catch(() => {
			useError('Error getting categories');
			return [];
		});

	return { Download, Add, Edit, Delete, Categories, IsLoading: ep.IsLoading };
};
