import React from 'react';
import { TextHelper, DateHelper } from 'Common/Helpers';
import { SubcontractLineModels } from 'Models';

import MaterialTable from '@material-table/core';
import { TableIcons, TableFilters } from '.';
import AddIcon from '@material-ui/icons/Add';
import TrashIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Colors, TableStyles } from 'Common/Styles';

export interface ISubcontractLineTableProps {
	isLoading?: boolean;
	subcontractLines: SubcontractLineModels.SubcontractLine[];
	onAddClicked: () => void;
	onDeleteClicked: (id: number) => void;
	onEditClicked: (subcontractLine: SubcontractLineModels.EditSubcontractLine) => void;
	editable: boolean;
}

export const SubcontractLineTable = (props: ISubcontractLineTableProps) => {
	const colors = Colors.useColors();
	return (
		<MaterialTable
			columns={[
				{ title: 'Name', field: 'name' },
				{ title: 'Account Number', field: 'accountNumber' },
				{ title: 'M Number', field: 'mNumber' },
				{ title: 'Dept PI', field: 'deptPI' },
				{ title: 'Bookkeeper', field: 'bookkeeper' },
				{ title: 'Amount', field: 'amount', render: rowData => TextHelper.money(rowData.amount) },
				{ title: 'Match', field: 'matchAmount', render: rowData => TextHelper.money(rowData.matchAmount) },
				{
					title: 'Starts On',
					field: 'startsOn',
					render: rowData => DateHelper.getShortDateString(rowData.startsOn),
					customFilterAndSearch: (term, rowData) => TableFilters.FilterDate(term, rowData.startsOn),
				},
				{
					title: 'Ends On',
					field: 'endsOn',
					render: rowData => DateHelper.getShortDateString(rowData.endsOn),
					customFilterAndSearch: (term, rowData) => TableFilters.FilterDate(term, rowData.endsOn),
				},
			]}
			data={props.subcontractLines}
			title="Subcontract Lines"
			icons={TableIcons}
			options={{
				pageSize: 5,
				headerStyle: TableStyles.headerStyle,
				actionsColumnIndex: -1,
			}}
			actions={[
				{
					icon: () => <AddIcon className={colors.primary} />,
					tooltip: 'Add Subcontract Line',
					position: 'toolbar',
					onClick: event => props.onAddClicked(),
					hidden: !props.editable,
				},
				{
					icon: () => <EditIcon className={colors.warning} />,
					tooltip: 'Edit',
					onClick: (event, rowData: SubcontractLineModels.SubcontractLine) =>
						props.onEditClicked(
							new SubcontractLineModels.EditSubcontractLine(
								rowData.id,
								rowData.mNumber,
								rowData.deptPI,
								rowData.bookkeeper,
								rowData.amount,
								rowData.matchAmount,
								rowData.startsOn,
								rowData.endsOn
							)
						),
					hidden: !props.editable,
				},
				{
					icon: () => <TrashIcon className={colors.error} />,
					tooltip: 'Delete',
					onClick: (event, rowData: SubcontractLineModels.SubcontractLine) =>
						props.onDeleteClicked(rowData.id),
					hidden: !props.editable,
				},
			]}
			isLoading={props.isLoading}
		/>
	);
};
